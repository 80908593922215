import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { AltSezonModel } from "../../../models/businessModels/AltSezonModel";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import StringHelper from "../../../library/helpers/StringHelper";

interface IAltSezonPopupEditorProps {
    altSezonModel: AltSezonModel,
    tumAltSezonModeller: AltSezonModel[],
    guncellenebilir: boolean,
    closer: (model: AltSezonModel | null) => void,
    genelDurumHelper: GenelDurumHelper
}

function AltSezonPopupEditor(props: IAltSezonPopupEditorProps) {
    const { tumAltSezonModeller, genelDurumHelper, closer, guncellenebilir } = props;

    const [altSezonModel, setAltSezonModel] = useState<AltSezonModel>(JsonHelper.deepCopy(props.altSezonModel));

    const updateAltSezonModel = (action: (model: AltSezonModel) => void) => {
        const result = JsonHelper.deepCopy(altSezonModel);
        action(result);
        setAltSezonModel(result);
    }

    let isimHataVar = false;
    let isimHata = "";

    if (StringHelper.isEmpty(altSezonModel.isim))
        isimHataVar = true;
    else if (tumAltSezonModeller.any(e => e.id !== altSezonModel.id && e.isim === altSezonModel.isim)) {
        isimHataVar = true;
        isimHata = "Bu isme sahip başka bir alt sezon bulunmaktadır";
    }

    const kaydet = async () => {
        closer(altSezonModel);
    }

    return (
        <Rows width={250} height={150} pt={20}>
            <CsmTextField
                disabled={!guncellenebilir}
                helperText={isimHata}
                label="Alt Sezon İsmi"
                error={isimHataVar}
                value={altSezonModel.isim}
                onChange={(e) => updateAltSezonModel(t => t.isim = e)}
                eventOnKeyPress
            />
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={isimHataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );
}

export default AltSezonPopupEditor;
