import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { HesapBilgiModel } from "../../models/businessModels/HesapBilgiModel";
import OzelGrupluKisiAvatarListControl from "./OzelGrupluKisiAvatarListControl";
import { EnmSesGrupTip } from "../../models/enums/EnmSesGrupTip";
import { SarkiModel } from "../../models/businessModels/SarkiModel";
import { BusinessHelper } from "../../helpers/BusinessHelper";

interface ISesGrupluKisiAvatarListControlProps {
    onClick?: (hesap: HesapBilgiVeKullaniciModel) => void,
    gosterilecekHesaplar: HesapBilgiVeKullaniciModel[],
    tumHesaplar: HesapBilgiVeKullaniciModel[],
    thumbnailSize?: "small" | "normal" | "large" | "xlarge",
    sesGrupYontemOrSarki?: "AnaSesGrubu" | "AltSesGrubu" | SarkiModel,
    badgeCountGetter?: (hesap: HesapBilgiVeKullaniciModel) => { error: number, info: number },
    darkSeperator?: boolean,
    hesapColorizeMethod?: "Özgeçmiş" | "Durum" | "Custom",
    customHesapColorizeMethod?: (hesap: HesapBilgiVeKullaniciModel) => string | undefined,
    hesapIsimTextGetir?: (hesap: HesapBilgiVeKullaniciModel) => string,
    hesapSesGrupGetir?: (hesap: HesapBilgiModel) => string[],
    genelDurumHelper: GenelDurumHelper
}


function SesGrupluKisiAvatarListControl(props: ISesGrupluKisiAvatarListControlProps) {
    const { sesGrupYontemOrSarki, hesapSesGrupGetir, tumHesaplar, gosterilecekHesaplar, ...rest } = props;
    const hesapSesGrupListGetir = hesapSesGrupGetir ?? ((e: HesapBilgiModel) => e.sesGrupIdList);

    const tumKoristHesaplar = tumHesaplar.where(e => e.sesGrupIdList.length > 0);
    const gosterilecekKoristHesaplar = gosterilecekHesaplar.where(e => e.sesGrupIdList.length > 0);


    function eserinDiviziUstuSesGruplariniGetir(sarki: SarkiModel) {
        const sarkininSesGruplari = props.genelDurumHelper.SesGruplariniGetir(sarki.sesGrupIdList).where(e => e.sesGrupTip !== EnmSesGrupTip.Diğer);
        const sarkininDiviziUstuSesGruplari = sarkininSesGruplari.where(e => e.sesGrupTip !== EnmSesGrupTip["Alt Divizi Ses Grubu"]);
        const sarkininDiviziSesGruplari = sarkininSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Alt Divizi Ses Grubu"])
            .select(e => BusinessHelper.SesGrubu.DiviziSesGrubununAltSesGrubunuGetir(e.id, props.genelDurumHelper.SeciliKoroModel.tumSesGruplari))
            .distinct();
        return [...sarkininDiviziUstuSesGruplari, ...sarkininDiviziSesGruplari].orderBy(e => e.isim).distinct();
    }

    function gosterilecekSesGruplariHesapla() {
        if (sesGrupYontemOrSarki === undefined || sesGrupYontemOrSarki === "AnaSesGrubu")
            return props.genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"]);
        else if (sesGrupYontemOrSarki === "AltSesGrubu")
            return props.genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"]);
        else
            return eserinDiviziUstuSesGruplariniGetir(sesGrupYontemOrSarki);
    }

    const gercek_gosterilecekSesGruplari = gosterilecekSesGruplariHesapla();

    return <OzelGrupluKisiAvatarListControl
        {...rest}
        gosterilecekHesaplar={gosterilecekKoristHesaplar}
        tumHesaplar={tumKoristHesaplar}
        hesapGrupGetir={e => gercek_gosterilecekSesGruplari.first(t => hesapSesGrupListGetir(e).contains(t.id))}
        grupIsimGetir={(e: SesGrupModel) => e.isim}
        hesapGrupSortFunc={(e: SesGrupModel) => e.sira}
    />
}

export default SesGrupluKisiAvatarListControl;