/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import { EnmSesGrupTip } from "../../models/enums/EnmSesGrupTip";
import Rows from "../../library/components/layout/Rows";
import Columns from "../../library/components/layout/Columns";
import { CsmText } from "../../library/components/mui/CsmText";
import { BusinessHelper } from "../../helpers/BusinessHelper";

export interface IVatanSesGrupMultiSelectFieldProps {
    id?: string,
    value: string[] | null,
    label: string,
    tumSesGruplari: SesGrupModel[],
    onChange: (e: any) => void,
    error?: boolean,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
    forKorist?: boolean,
}

export default function VatanSesGrupMultiSelectField(props: IVatanSesGrupMultiSelectFieldProps) {
    const { id, value, label, tumSesGruplari, onChange, error, fontSize, sx, disabled, forKorist, style } = props;
    const fontSizeToUse = fontSize ?? 14;

    const list = (tumSesGruplari ?? []).orderBy(e => e.sira);
    const tipList = list
        .select(e => e.sesGrupTip)
        .distinct()
        .orderBy(BusinessHelper.SesGrubu.SesGrupTipSortFunc);

    const chipClickedForSingleSelection = (newId: string | null) => {
        if (newId === null)
            onChange([]);
        else {
            const anaId = BusinessHelper.SesGrubu.AltSesGrubununAnaSesGrupIdGetir(newId, tumSesGruplari);
            const ustId = BusinessHelper.SesGrubu.AltSesGrubununUstSesGrupIdGetir(newId, tumSesGruplari);
            onChange([newId, anaId, ustId]);
        }
    }

    const chipClicked = (newIdList: string[]) => {
        onChange(newIdList.orderBy(e => list.single(t => t.id === e).sira));
    }

    const getSelectedChipItem = (sesGrupId: string) => {
        return <Chip key={sesGrupId} label={list.singleOrDefault(e => e.id === sesGrupId)?.isim} />
    }
    const getSelectableChipMenuItem = (sesGrup: SesGrupModel, isSelected: boolean): React.ReactNode => {
        return <Chip
            key={sesGrup.id}
            label={sesGrup.isim}
            disabled={forKorist !== true ? undefined : (sesGrup.sesGrupTip !== EnmSesGrupTip["Alt Ses Grubu"])}
            onClick={() => {
                if (forKorist === true)
                    chipClickedForSingleSelection(!isSelected ? sesGrup.id : null);
                else
                    chipClicked(isSelected ? (value ?? [])?.where(e => e !== sesGrup.id) : [sesGrup.id, ...(value ?? [])]);
            }}
            color={isSelected ? "primary" : undefined}
            style={{ margin: "5px", cursor: "pointer", display: "flex" }}
        />;
    }

    return (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
            <Select
                multiple
                disabled={disabled}
                sx={{ overflow: "hidden", ...sx }}
                inputProps={{ style: { paddingTop: "5px !important", paddingBottom: "5px !important", fontSize: fontSizeToUse } }}
                error={error}
                MenuProps={{ MenuListProps: { style: { display: "flex", flexWrap: "wrap" } } }}
                labelId={id}
                id={id}
                value={value?.orderBy(e => list.single(t => t.id === e).sira)}
                label={label}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((_value) => getSelectedChipItem(_value))}
                    </Box>
                )}
            // onChange={(e) => onChange(e.target.value)}
            >
                <Rows flex>
                    {tipList.map(tip => (
                        <Rows key={tip.toString()} mb={10}>
                            <CsmText style={{ marginLeft: "10px" }} note>{EnmSesGrupTip[tip]}</CsmText>
                            <MenuItem style={{ padding: "0px" }} divider></MenuItem>
                            <Columns wrap>
                                {list.where(e => e.sesGrupTip === tip).map(e => getSelectableChipMenuItem(e, value?.contains(e.id) ?? false))}
                            </Columns>
                        </Rows>
                    ))}
                </Rows>
            </Select>
        </FormControl>
    );
}
