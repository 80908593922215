// AutoGenerated
export class KoroModel {
    isim!: string;
    resimIcerikId!: string | null;
    aktif!: boolean;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}