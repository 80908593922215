import CssType from "csstype";
import { BaseCSSProperties } from "@mui/material/styles/createMixins";
import { CSSProperties, useState } from "react";
import { useTheme } from "@mui/material";
import StyleHelper, { IExtraStyleSpacingProps, IExtraStyleBorderProps } from "../../helpers/StyleHelper";

export type CellCSSProperties = Omit<BaseCSSProperties, "height" | "width" | "flex" | "marginTop" | "marginRight" | "marginBottom" | "marginLeft" | "paddingTop" | "paddingRight" | "paddingBottom" | "paddingLeft" | "borderLeftColor" | "borderTopColor" | "borderRightColor" | "borderBottomColor">;
export type CellProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "style"> & IExtraStyleSpacingProps & IExtraStyleBorderProps &
{
    style?: CellCSSProperties,
    hoverStyle?: CellCSSProperties,
    width?: CssType.Property.Width<string | number>,
    height?: CssType.Property.Width<string | number>,
    flex?: boolean | CssType.Property.Width<string | number>,
    wrap?: boolean,
    dontGrowForChild?: boolean,
    dbg_red?: boolean,
    dbg_blue?: boolean,
    dbg_yellow?: boolean,
};

function Cell(props: CellProps) {
    if (props.hoverStyle)
        return HoverableCell(props);
    else
        return NonHoverableCell(props);
}


function HoverableCell(props: CellProps) {
    const { dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, hoverStyle, style, ...innerRest } = props;

    const rest = StyleHelper.OmitProps(innerRest);

    const theme = useTheme();

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const [isHovering, setIsHovering] = useState<boolean>(false);

    const addHoverStyle: CSSProperties = isHovering ? hoverStyle! : {};

    const customStyle = StyleHelper.CreateStyle(props);

    const givenStyle: CSSProperties = {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        minHeight: dontGrowForChild ? 0 : "auto",
        minWidth: dontGrowForChild ? 0 : "auto",
    };
    return (
        <div
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
                ...givenStyle,
                ...customStyle,
                ...style,
                ...addHoverStyle,
            }} {...rest}>
            {children}
        </div>
    );
}

function NonHoverableCell(props: CellProps) {
    const { dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, ...rest } = props;

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const customStyle = StyleHelper.CreateStyle(props);

    const givenStyle: CSSProperties = {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        minHeight: dontGrowForChild ? 0 : "auto",
        minWidth: dontGrowForChild ? 0 : "auto",
    };

    return (
        <div style={{
            ...givenStyle,
            ...customStyle,
            ...style,
        }} {...rest}>
            {children}
        </div>
    );
}

export default Cell;
