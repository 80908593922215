import Columns, { ColumnsCSSProperties } from "../../library/components/layout/Columns";
import { CsmText } from "../../library/components/mui/CsmText";
import { DuyuruModel } from "../../models/businessModels/DuyuruModel";
import appColors from "../../app/appColors";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import DateTimeToStringHelper from "../../library/helpers/DateTimeToStringHelper";

interface IDuyuruAvatarListControl {
    duyurular: DuyuruModel[],
    style?: ColumnsCSSProperties,
    genelDurumHelper: GenelDurumHelper
}

function DuyuruAvatarListControl(props: IDuyuruAvatarListControl) {

    const getInnerContent = (duyuru: DuyuruModel) => {
        return (
            <div key={duyuru.id} style={{ borderRadius: 5, width: 165, padding: 10, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <CsmText style={{ textAlign: "center", fontSize: 13 }}>{duyuru.icerik}</CsmText>
                <CsmText style={{ textAlign: "center", fontSize: 11 }} note>{DateTimeToStringHelper.toMonthDayAndYearAndDayOfWeekString(duyuru.tarihBaslangic, props.genelDurumHelper.translate)}</CsmText>
            </div>
        );
    }

    return (
        <Columns wrap style={props.style}>
            {props.duyurular.map(item => getInnerContent(item))}
        </Columns>
    )
}

export default DuyuruAvatarListControl;