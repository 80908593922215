import Rows from '../../library/components/layout/Rows';
import Cell from '../../library/components/layout/Cell';
import Columns from '../../library/components/layout/Columns';
import DateTimeHelper from '../../library/helpers/DateTimeHelper';
import { CsmText } from '../../library/components/mui/CsmText';
import React, { CSSProperties, useState } from 'react';
import { Button } from '@mui/material';
import { appIcons } from '../../app/appIcons';
import VatanCalendar, { VatanCalendarSizes } from './VatanCalendar';
import { useScreenWidth } from '../../library/components/hooks/useScreenWidth';
import GenelDurumHelper from '../../helpers/GenelDurumHelper';
import DateTimeToStringHelper from '../../library/helpers/DateTimeToStringHelper';

export type IVatanCalendarProps = {
    getDayCircleColor?: (day: Date) => string | undefined,
    renderDate: (day: Date, size: VatanCalendarSizes) => (React.ReactNode | undefined);
    isEtkinlikDay: (dat: Date) => boolean;
    onSelectedDataChanged: (day: Date | undefined) => void;
    selectedDate: Date | undefined,
    style?: CSSProperties,
    genelDurumHelper: GenelDurumHelper,
    seciliGunIcerik: React.ReactNode | undefined,
    bosGunSecilebilir: boolean,
    tatilleriGoster: boolean,
}

function VatanCalendarWithNavigation(props: IVatanCalendarProps) {

    const { seciliGunIcerik, selectedDate, isEtkinlikDay, renderDate, onSelectedDataChanged, genelDurumHelper } = props;

    const isXXLScreen = useScreenWidth() === "MoreThan1600";

    const [gosterilenAy, setGosterilenAy] = useState<Date>(DateTimeHelper.today());

    const sezonIdler = genelDurumHelper.SeciliKoroModel.tumCalismalar
        .where(e => e.tarihBaslangic.getMonth() === gosterilenAy.getMonth() && e.tarihBaslangic.getFullYear() === gosterilenAy.getFullYear())
        .select(e => e.sezonModelId)
        .distinct();

    const sezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar.where(e => sezonIdler.contains(e.id)).orderByTurkish(e => e.isim).select(e => e.isim);

    // functions
    const gotoMonth = (monthCount: number) => {
        const newGosterilenAy = new Date(gosterilenAy.valueOf());
        newGosterilenAy.setMonth(gosterilenAy.getMonth() + monthCount);
        setGosterilenAy(newGosterilenAy);
        onSelectedDataChanged(undefined);
    }

    // react renders

    return (
        <Columns flex ml={15} mt={15}>
            <Rows flex ml={10} mr={10} style={{ display: "block", flexGrow: 1, minWidth: 300, maxWidth: 800 }}>
                <Columns height={55}>
                    <Cell width={50}>
                        <Button onClick={() => gotoMonth(-1)}>{appIcons.Genel.geri}</Button>
                    </Cell>
                    <Cell flex>
                        <Rows>
                            <CsmText header style={{ flex: 1, textAlign: "center" }}>{DateTimeToStringHelper.toYearAndMonthString(gosterilenAy, genelDurumHelper.translate)}</CsmText>
                            <CsmText note style={{ flex: 1, textAlign: "center" }}>{sezonlar.length > 0 ? (sezonlar.join(" - ")) : "-"} </CsmText>
                        </Rows>
                    </Cell>
                    <Cell width={50}>
                        <Button onClick={() => gotoMonth(1)}>{appIcons.Genel.ileri}</Button>
                    </Cell>
                </Columns>
                <VatanCalendar
                    getDayCircleColor={props.getDayCircleColor}
                    tatilleriGoster={props.tatilleriGoster}
                    geneldurumHelper={genelDurumHelper}
                    seciliAy={gosterilenAy}
                    renderDate={renderDate}
                    isEtkinlikDay={isEtkinlikDay}
                    selectedDate={selectedDate}
                    onSelectedDataChanged={(day) => onSelectedDataChanged(day)}
                    bosGunSecilebilir={props.bosGunSecilebilir}
                />
            </Rows>
            {!isXXLScreen ? null : <Rows flex ml={10} mr={10} style={{ display: "block", flexGrow: 1, minWidth: 300, maxWidth: 800 }}>
                <Cell height={55}></Cell>
                <VatanCalendar
                    getDayCircleColor={props.getDayCircleColor}
                    tatilleriGoster={props.tatilleriGoster}
                    geneldurumHelper={genelDurumHelper}
                    isSecondCalendar
                    seciliAy={gosterilenAy}
                    renderDate={renderDate}
                    isEtkinlikDay={isEtkinlikDay}
                    selectedDate={selectedDate}
                    onSelectedDataChanged={(day) => onSelectedDataChanged(day)}
                    bosGunSecilebilir={props.bosGunSecilebilir}
                /></Rows>}
            <Columns ml={15}>
                {seciliGunIcerik}
            </Columns>
        </Columns>
    )
}

export default VatanCalendarWithNavigation;