import { useState } from "react";
import { IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps, FormControl, FormHelperText, useTheme } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

type selfProps = {
    onChange: (e: string) => void,
    value: string | null | undefined,
    helperText?: string | null | undefined,
};
export type ICsmPasswordFieldProps = Omit<OutlinedInputProps, "onChange" | "type" | "value"> & selfProps;

function CsmPasswordField(props: ICsmPasswordFieldProps & { eventOnKeyPress?: boolean }) {
    const { onChange, fullWidth, helperText, style, ...otherProps } = props;

    const theme = useTheme();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    // <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
    // </FormControl>

    return <FormControl variant="outlined" style={style}>
        <InputLabel htmlFor="outlined-adornment-password" style={{ color: otherProps.error ? theme.palette.error.main : undefined}} color={otherProps.error ? "error" : undefined}>{otherProps.label}</InputLabel>
        <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            color={otherProps.error ? "error" : undefined}
            onChange={(e) => onChange(e.target.value)}
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
            {...otherProps}
        />
        <FormHelperText style={{ color: otherProps.error ? theme.palette.error.main : undefined}}>{helperText}</FormHelperText>
    </FormControl>
}

export default CsmPasswordField;