/* eslint-disable react/no-children-prop */
// cihan
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, SubPageLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Paper, alpha } from "@mui/material";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import { CsmText } from "../../../library/components/mui/CsmText";
import { HesapSarkiSesKayitModel } from "../../../models/businessModels/HesapSarkiSesKayitModel";
import KayitWithPdfPopupEditor from "./KayitWithPdfPopupEditor";
import AlertHelper from "../../../library/helpers/AlertHelper";
import KoristSarkiYuklemePopupEditor from "../../main/sarki/KoristSarkiYuklemePopupEditor";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import VatanKayitRowListItem, { VatanKayitRowInnerPropsHazirla } from "../../../components/vatan/VatanKayitRowListItem";
import appColors from "../../../app/appColors";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import { HesapKayitSesGrupGuncelleRequestModel } from "../../../models/requestModels/HesapKayitSesGrupGuncelleRequestModel";
import KayitSesGrubuSecmePopupEditor from "./KayitSesGrubuSecmePopupEditor";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import { EnmYetki } from "../../../models/enums/EnmYetki";

function KoristSarkiYorumKoristScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KoristSarkiYorumKoristScreenInner genelDurumHelper={genelDurumHelper} />
}

function KoristSarkiYorumKoristScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;
  const { hesapid } = useParams();

  const seciliHesap = genelDurumHelper.SeciliKoroModel.hesapBilgiList.single(e => e.id === hesapid);

  const kayitYukle = async (sarki: SarkiModel) => {
    const koristMi = seciliHesap.sesGrupIdList.length > 0;
    const kisininSarkiKayitlari = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === seciliHesap.id && e.sarkiModelId === sarki.id).orderBy(e => e.olusturmaTarih);

    const kisiKayitSonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kisininSarkiKayitlari);
    const eksikHataliKayitVarMi = koristMi && (kisiKayitSonDurum === null || kisiKayitSonDurum === EnmKayitDurum.Hatalı)
    const basariliKayitSesGruplar = BusinessHelper.SesKayit.KisininKayitYuklenemezSesGruplariniGetir(genelDurumHelper, kisininSarkiKayitlari);

    if (eksikHataliKayitVarMi === false) {
      const sesgrupIsimler = basariliKayitSesGruplar.select(e => e.isim).join(', ');
      const message = `Daha önce gönderilen ${sesgrupIsimler} kayıtlarınız onaylı/beklemede olduğu için, yeni kaydınızı ancak başka ses grupları için gönderebilirsiniz. Kayıt yükleme işlemine devam etmek istiyor musunuz?`;

      if (await AlertHelper.Confirm("Dikkat", message) === false)
        return;
    }

    const response = await AlertHelper.ShowClosableContent<WithContentRequestModel<HesapSarkiSesKayitModel>>(sarki.isim + " Kayıt Yükle", (closer) => (
      <KoristSarkiYuklemePopupEditor
        sarkiModel={sarki}
        basariliKayitSesGruplar={basariliKayitSesGruplar}
        hesapBilgiModel={seciliHesap}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        seciliKoro.hesapSarkiSesKayitModelList.push(response!.model);
      });
  }

  const kayitYorumla = async (kayitModel: HesapSarkiSesKayitModel, sarki: SarkiModel) => {
    const response = await AlertHelper.ShowClosableContentFullHeight<HesapSarkiSesKayitModel>("Kayıt Yorumla", (closer) => (
      <KayitWithPdfPopupEditor
        sarki={sarki}
        yorumKaydedebilir={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        hesapBilgi={seciliHesap!}
        hesapSarkiSesKayit={kayitModel} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiKayitlar = seciliKoro.hesapSarkiSesKayitModelList;
        const itemToRemove = hesapSarkiKayitlar.single(e => e.id === response?.id);
        hesapSarkiKayitlar.remove(itemToRemove);
        hesapSarkiKayitlar.push(response!);
      });
  }

  const kayitSesGrupGuncelle = async (kayitModel: HesapSarkiSesKayitModel, sarki: SarkiModel) => {
    const adSoyad = `${seciliHesap.kullaniciModel.ad} ${seciliHesap.kullaniciModel.soyad}`;

    const secilenSesGrupId = await AlertHelper.ShowClosableContent<string>(`${adSoyad} Kişisinin Ses Grubu Güncelleme`, (closer) => (
      <KayitSesGrubuSecmePopupEditor
        sarkiModel={sarki}
        orijinalSesGrupModelId={kayitModel.sesGrupId}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (secilenSesGrupId == null)
      return;

    const model = new HesapKayitSesGrupGuncelleRequestModel(kayitModel.id, secilenSesGrupId);

    const webResponse = await genelDurumHelper.requestHelper.HesapMuzikal.HesapKayitSesGrupGuncelle(model, "Kayıt ses grubu güncelleniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiKayitlar = seciliKoro.hesapSarkiSesKayitModelList;
        const itemToUpdate = hesapSarkiKayitlar.single(e => e.id === kayitModel.id);
        itemToUpdate.sesGrupId = secilenSesGrupId;
      });
  }

  const kayitSil = async (kayitModel: HesapSarkiSesKayitModel, sarki: SarkiModel, sira: number) => {
    const adSoyad = `${seciliHesap.kullaniciModel.ad} ${seciliHesap.kullaniciModel.soyad}`;
    if (await AlertHelper.Confirm("Dikkat", `${adSoyad} kullanıcısının ${sarki.isim} eserindeki ${sira + 1}. kaydı silmek istediğinizden emin misiniz?`) !== true)
      return;

    const webResponse = await genelDurumHelper.requestHelper.HesapMuzikal.HesapKayitSil(kayitModel.id, "Kayıt siliniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiKayitlar = seciliKoro.hesapSarkiSesKayitModelList;
        const itemToRemove = hesapSarkiKayitlar.single(e => e.id === kayitModel.id);
        hesapSarkiKayitlar.remove(itemToRemove);
      });
  }

  function sarkiRowListGetir(sarkiList: SarkiModel[], siraNoEkle: boolean): React.ReactNode[] | null {
    if (sarkiList.length === 0)
      return null;

    return sarkiList.map((sarki) => {
      const kayitlar = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === seciliHesap.id && e.sarkiModelId === sarki.id).orderBy(e => e.olusturmaTarih);
      const sonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar);
      const renk = sonDurum ? BusinessHelper.SesKayit.KayitDurumRenkGetir(sonDurum, 0.3) : alpha(appColors.RED, 0.3);

      const canDelete = genelDurumHelper.SeciliKoroModel.hesapBilgiModel.superAdmin;

      const kayitYuklenebilir = genelDurumHelper.HasYetki(EnmYetki["Kayıt Onaylama"]) || hesapid === genelDurumHelper.SeciliKoroModel.hesapBilgiModel.id;

      const siraNoPrefix = siraNoEkle ? `${sarki.sira}.` : "";
      const satbPostfix = sarki.sesGrupIdList.length === 0 ? "" : ` (${BusinessHelper.SesGrubu.IdListtenSATBUret(genelDurumHelper.SeciliKoroModel.tumSesGruplari, sarki.sesGrupIdList)})`;

      const kayitlarProps = VatanKayitRowInnerPropsHazirla(kayitlar, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

      return <Paper key={sarki.id} style={{ margin: "10px" }}>
        <Rows width={450}>
          <ListItem divider style={{ justifyContent: "center", fontSize: "16px", fontWeight: "bold", background: renk }}>{siraNoPrefix} {sarki.isim} {satbPostfix}</ListItem>
          {kayitlarProps.length === 0 ? (
            <CsmText note italic>Kayıt bulunmamaktadır</CsmText>
          ) : kayitlarProps.map(item =>
            <VatanKayitRowListItem
              {...item}
              key={item.kayit.id}
              style={{ background: BusinessHelper.SesKayit.KayitDurumRenkGetir(item.kayit.kayitDurum, 0.1) }}
              genelDurumHelper={genelDurumHelper}
              kayitSec={e => kayitYorumla(e, sarki)}
              kayitSil={canDelete ? (e => kayitSil(e, sarki, item.siraNo)) : undefined}
              kayitSesGrupGuncelle={canDelete ? (e => kayitSesGrupGuncelle(e, sarki)) : undefined}
            />
          )}
          {!kayitYuklenebilir ? null : <Button variant="text" onClick={() => kayitYukle(sarki)}>Kayıt Yükle</Button>}
        </Rows></Paper>
    })
  }

  const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.orderByTurkish(e => e.isim);
  const sezonSarkilari = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id));

  const guncelSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === true).orderBy(e => e.sira);
  const bekleyenSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === false).orderBy(e => e.sira);
  const eskiSarkilar = tumSarkilar.where(e => e.kayitGondermeSurecinde === false).orderByTurkish(e => e.isim);


  return (
    <SubPageLayout
      title={`${seciliHesap.kullaniciModel.ad} ${seciliHesap.kullaniciModel.soyad} Kayıtları`}
      previousLocations={[{ location: mainScreenLocations.koristSarkiYorumYonetim }]}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <CsmAccordionSimple
          bigCenteredTitle
          title="Güncel Şarkılar"
          children={sarkiRowListGetir(guncelSarkilar, true) ?? <CsmText note>Güncel şarkı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          bigCenteredTitle
          title="Bekleyen Şarkılar"
          children={sarkiRowListGetir(bekleyenSarkilar, true) ?? <CsmText note>Bekleyen şarkı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          bigCenteredTitle
          title="Eski Şarkılar"
          children={sarkiRowListGetir(eskiSarkilar, false) ?? <CsmText note>Eski şarkı bulunmamaktadır</CsmText>}
        />
      </List>
    </SubPageLayout>
  );
}

export default KoristSarkiYorumKoristScreen;