/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, IconButton, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import JsonHelper from "../../../library/helpers/JsonHelper";
import Cell from "../../../library/components/layout/Cell";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import { AnketSoruSecenekModel } from "../../../models/businessModels/AnketSoruSecenekModel";
import StringHelper from "../../../library/helpers/StringHelper";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import CsmGenericOrderableListWithHandle from "../../../library/components/mui/CsmGenericOrderableListWithHandle";

interface IAnketSecenekListPopupEditorProps {
    orijinalModelList: AnketSoruSecenekModel[],
    guncellenebilir: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: AnketSoruSecenekModel[] | null) => void,
}

function AnketSecenekListPopupEditor(props: IAnketSecenekListPopupEditorProps) {
    const { orijinalModelList, closer, guncellenebilir, genelDurumHelper } = props;

    const [modelList, setModelList] = useState<AnketSoruSecenekModel[]>(JsonHelper.deepCopy(orijinalModelList));

    const secenekEkle = async () => {
        const secenek = new AnketSoruSecenekModel();
        secenek.id = GuidHelper.NewGuid();
        secenek.agirlik = 1;
        secenek.secenekIcerik = "";
        secenek.sira = modelList.length + 1;
        secenek.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        secenek.olusturmaTarih = DateTimeHelper.now();

        const result = JsonHelper.deepCopy(modelList);

        result.push(secenek);

        setModelList(result);
    }

    const secenekSil = (secenekId: string) => {
        const result = JsonHelper.deepCopy(modelList);
        const itemToRemove = result.single(e => e.id === secenekId);
        result.remove(itemToRemove);

        const newResult = result.orderBy(e => e.sira);
        for (const item of newResult)
            if (item.sira >= itemToRemove.sira)
                item.sira--;

        setModelList(newResult);
    }

    const secenekGuncelle = (secenekId: string, action: (item: AnketSoruSecenekModel) => void) => {
        const result = JsonHelper.deepCopy(modelList);
        const itemtoUpdate = result.single(e => e.id === secenekId);
        action(itemtoUpdate);
        setModelList(result);
    }

    function orderChanged(newList: AnketSoruSecenekModel[]): void {
        const result = JsonHelper.deepCopy(newList);

        for (let i = 0; i < newList.length; i++)
            result[i].sira = i + 1;

        setModelList(result);
    }

    const hataGetir = (model: AnketSoruSecenekModel) => {
        if (StringHelper.isEmpty(model.secenekIcerik))
            return "Seçenek içeriği boş olmamalıdır."
        if (model.secenekIcerik.length > 100)
            return "Seçenek içeriği 100 karakterden fazla olmamalıdır."
        if (modelList.where(e => e.id !== model.id && e.sira < model.sira && e.secenekIcerik === model.secenekIcerik).length > 0)
            return `Aynı içerik birden fazla seçenekte kullanılamaz.`;
        return "";
    };

    const hatalar = modelList.select(e => ({ model: e, hata: hataGetir(e) }));
    const hataliSecenekVar = hatalar.any(e => StringHelper.isNotEmpty(e.hata));

    const hataVar = modelList.length === 0 || hataliSecenekVar;

    // alert(JsonHelper.stringify(hatalar));

    return (
        <Rows width={600} height={500}>
            <Columns>
                <Cell flex></Cell>
                <Cell>
                    <Button disabled={!guncellenebilir} onClick={() => secenekEkle()}>
                        {appIcons.CrudIcons.ekle}
                        <Typography>{genelDurumHelper.translate("Seçenek Ekle")}</Typography>
                    </Button>
                </Cell>
            </Columns>
            <Columns flex style={{ gap: "10px", overflowY: "scroll" }}>
                <CsmGenericOrderableListWithHandle
                    style={{ flex: 1 }}
                    list={modelList}
                    renderItem={secenek =>
                        <Columns flex style={{ placeItems: "center", gap: "10px" }}>
                            <CsmText>{`${secenek.sira}.`}</CsmText>
                            <Rows flex>
                                <CsmTextField
                                    label=""
                                    eventOnKeyPress
                                    appendMaxlenOnLabel={100}
                                    narrow
                                    value={secenek.secenekIcerik}
                                    onChange={t => secenekGuncelle(secenek.id, k => k.secenekIcerik = t)}
                                    error={StringHelper.isNotEmpty(hatalar.singleOrDefault(e => e.model.id === secenek.id)?.hata ?? "")}
                                />
                            </Rows>
                            <IconButton color="error" onClick={() => secenekSil(secenek.id)}>{appIcons.CrudIcons.sil}</IconButton>
                        </Columns>}
                    idGetter={e => e.id}
                    onOrderChanged={newList => orderChanged(newList)} />
            </Columns>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => closer(modelList)} disabled={hataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );
}

export default AnketSecenekListPopupEditor;