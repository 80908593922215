import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button, FormControl } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import { HesapCalismaModel } from "../../../models/businessModels/HesapCalismaModel";
import { EnmKatilimBildirmeDurum } from "../../../models/enums/EnmKatilimBildirmeDurum";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import CsmFormSegmentedRadioButtonList from "../../../library/components/mui/CsmFormSegmentedRadioButtonList";
import EnumHelper from "../../../library/helpers/EnumHelper";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import StringHelper from "../../../library/helpers/StringHelper";

interface IYoklamaBildirmePopupEditorProps {
    caslima: CalismaModel,
    orijinalHesapCalisma?: HesapCalismaModel | null,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: HesapCalismaModel | null) => void,
}

function YoklamaBildirmePopupEditor(props: IYoklamaBildirmePopupEditorProps) {
    const [katilimDurum, setKatilimDurum] = useState<EnmKatilimBildirmeDurum | null>(props.orijinalHesapCalisma?.katilimDurum ?? null);
    const [gerekce, setGerekce] = useState<string>(StringHelper.nullToEmpty(props.orijinalHesapCalisma?.katilmamaGerekce));

    const yoklamaKatilimDurumBildir = async () => {
        const webResponse = await props.genelDurumHelper.requestHelper.Korist.HesapCalismaKatilimDurumGuncelle(props.caslima.id, katilimDurum!, gerekce ?? " ", "Çalışmaya katılım durumu bildiriliyor");
        if (!webResponse.successfull)
            return;

        const yeniResult = new HesapCalismaModel();

        yeniResult.calismaModelId = props.caslima.id;
        yeniResult.hesapModelId = props.genelDurumHelper.SeciliHesapBilgiModel.id;
        yeniResult.katilimDurum = katilimDurum!
        yeniResult.katilmamaGerekce = gerekce;

        props.closer(yeniResult);
    }

    const gerekceGirilebilir = katilimDurum === EnmKatilimBildirmeDurum.Belirsiz || katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum;

    const gerekceMaxlenHataVarMi = gerekceGirilebilir && (!!gerekce && gerekce.length > 250);
    const gerekceHata = gerekceGirilebilir && (gerekce == null || gerekce === "" || gerekceMaxlenHataVarMi);
    const isSmallScreen = useScreenWidth() === "Max600";

    return <Rows style={{ minWidth: isSmallScreen ? 250 : 450}}>
        <FormControl style={{ width: "100%", marginRight: 5 }}>
            <CsmFormSegmentedRadioButtonList
                list={EnumHelper.getNamesAndValues<EnmKatilimBildirmeDurum>(EnmKatilimBildirmeDurum)}
                labelGetter={(e) => e.name}
                valueGetter={(e) => e.value}
                value={katilimDurum}
                onChange={(value) => setKatilimDurum(value)} />
        </FormControl>
        <FormControl style={{ maxWidth: 600, marginRight: 5, marginTop: 10, marginBottom: 10 }}>
            <CsmTextField
                fullWidth
                multiline
                showStats
                eventOnKeyPress
                rows={4}
                error={gerekceHata}
                label={gerekceMaxlenHataVarMi ? "Gerekçe (En fazla 250 karakter)" : "Gerekçe"}
                disabled={!gerekceGirilebilir}
                value={gerekceGirilebilir ? gerekce : "Çalışmaya katılma durumunda gerekçe girilmez"}
                onChange={(e) => setGerekce(e)} />
        </FormControl>

        <Columns mt={10}>
            <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {props.genelDurumHelper.translate("İptal")}</Button>
            <Button color="success" variant="contained" disabled={!!gerekceHata || !katilimDurum} onClick={() => yoklamaKatilimDurumBildir()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {props.genelDurumHelper.translate("Kaydet")}</Button>
        </Columns>
    </Rows>
        ;

}

export default YoklamaBildirmePopupEditor;