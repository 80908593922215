// AutoGenerated
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class SecmeBasvuruProfilGuncelleRequestModel {
    constructor(secmeBasvuruModelId: string, icerik: IcerikEklemeRequestModel | null) {
        this.secmeBasvuruModelId = secmeBasvuruModelId;
        this.icerik = icerik;
    }

    secmeBasvuruModelId: string;
    icerik: IcerikEklemeRequestModel | null;
}