// AutoGenerated
export class HesapKayitYorumRequestModel {
    constructor(hesapSarkiSesKayitId: string, uygun: boolean, yorum: string, resimIcerikId: string | null) {
        this.hesapSarkiSesKayitId = hesapSarkiSesKayitId;
        this.uygun = uygun;
        this.yorum = yorum;
        this.resimIcerikId = resimIcerikId;
    }

    hesapSarkiSesKayitId: string;
    uygun: boolean;
    yorum: string;
    resimIcerikId: string | null;
}