/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, IconButton } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { KonserSarkiModel } from "../../../models/businessModels/KonserSarkiModel";
import { KonserModel } from "../../../models/businessModels/KonserModel";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import CsmGenericListSelectField from "../../../library/components/mui/CsmGenericListSelectField";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import Cell from "../../../library/components/layout/Cell";
import CsmGenericGroupBox from "../../../library/components/mui/CsmGenericGroupBox";
import StringHelper from "../../../library/helpers/StringHelper";

interface IKonserSarkiListControlProps {
    sezonModelId: string,
    tumSarkilar: SarkiModel[],
    konser: KonserModel,
    konserSarkiList: KonserSarkiModel[],
    onChange: (konserSarkiList: KonserSarkiModel[]) => void,
    genelDurumHelper: GenelDurumHelper,
}

function KonserSarkiListControl(props: IKonserSarkiListControlProps) {
    const { sezonModelId, tumSarkilar, konser, konserSarkiList, onChange, genelDurumHelper } = props;

    const sezonSarkilari = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezonModelId));

    const sezonSarkilariSirali = (sezonModelId === genelDurumHelper.AktifSezon.id) ?
        sezonSarkilari.orderBy(e => e.sira) :
        sezonSarkilari.orderByTurkish(e => e.isim);

    const sezonSarkilariIdList = sezonSarkilari.select(e => e.id);
    const digerSarkilarSirali = tumSarkilar.where(e => sezonSarkilariIdList.contains(e.id) === false).orderByTurkish(e => e.isim);

    const tumSarkilarSirali = sezonSarkilariSirali.union(digerSarkilarSirali);
    const hasError = konserSarkiList.any(e => StringHelper.nullToEmpty(e.videoUrl).length > 500);

    function sarkiEkle() {
        const result = JsonHelper.deepCopy(konserSarkiList);
        const newItem = new KonserSarkiModel();

        newItem.id = GuidHelper.NewGuid();
        newItem.sarkiModelId = "";
        newItem.videoUrl = "";
        newItem.konserModelId = konser.id;
        newItem.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        newItem.olusturmaTarih = DateTimeHelper.now();
        newItem.siraNo = result.length + 1;

        result.push(newItem);
        onChange(result);
    }

    function konserSarkiSil(model: KonserSarkiModel) {
        const result = JsonHelper.deepCopy(konserSarkiList.where(e => e.id !== model.id));
        const sonrakiler = result.where(e => e.siraNo > model.siraNo);
        sonrakiler.forEach(e => e.siraNo = e.siraNo - 1);

        onChange(result);
    }

    const swapKonserSarkiModelSira = (model1: KonserSarkiModel, model2: KonserSarkiModel) => {
        const result = JsonHelper.deepCopy(konserSarkiList);

        const item1 = result.single(e => e.id === model1.id);
        const item2 = result.single(e => e.id === model2.id);

        item1.siraNo = model2.siraNo;
        item2.siraNo = model1.siraNo;

        onChange(result.orderBy(e => e.siraNo));
    }

    function renderKonserSarki(konserSarki: KonserSarkiModel, index: number): any {
        const prev = index === 0 ? null : konserSarkiList[index - 1];
        const next = index === konserSarkiList.length - 1 ? null : konserSarkiList[index + 1];

        function konserSarkiGuncelle(konserSarki: KonserSarkiModel, action: (item: KonserSarkiModel) => void): void {
            const result = JsonHelper.deepCopy(konserSarkiList);

            const item = result.single(e => e.id === konserSarki.id);
            action(item);

            onChange(result.orderBy(e => e.siraNo));
        }

        return <Columns key={konserSarki.id} mt={5} mb={5}>
            <Columns flex>
                <IconButton onClick={() => konserSarkiSil(konserSarki)}>{appIcons.CrudIcons.sil}</IconButton>
                <IconButton disabled={!prev} onClick={() => swapKonserSarkiModelSira(konserSarki, prev!)}>{appIcons.Genel.yukari}</IconButton>
                <IconButton disabled={!next} onClick={() => swapKonserSarkiModelSira(konserSarki, next!)}>{appIcons.Genel.asagi}</IconButton>

                <Cell flex mr={10}>
                    <CsmGenericListSelectField
                        isAutoComplete
                        value={konserSarki.sarkiModelId}
                        label={`${(index + 1).toFixed()}. Şarkı`}
                        list={tumSarkilarSirali}
                        labelGetter={e => e.isim}
                        valueGetter={e => e.id}
                        onChange={e => konserSarkiGuncelle(konserSarki, t => t.sarkiModelId = StringHelper.nullToEmpty(e))} />
                </Cell>
                <Cell flex mr={10}>
                    <CsmTextField
                        label="Video URL"
                        value={konserSarki.videoUrl}
                        error={StringHelper.nullToEmpty(konserSarki.videoUrl).length > 500}
                        appendMaxlenOnLabel={500}
                        onChange={e => konserSarkiGuncelle(konserSarki, t => t.videoUrl = StringHelper.nullToEmpty(e))} />
                </Cell>
            </Columns>
        </Columns>
    }

    return (
        <CsmGenericGroupBox error={hasError} label="Konserin Şarkıları" style={{ height: "100%" }}>
            <Rows height="100%" flex dontGrowForChild>
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => sarkiEkle()}>Şarkı Ekle</Button>

                <Rows dontGrowForChild height="100%" flex mt={10} style={{ overflowY: "scroll" }}>
                    {konserSarkiList.map((konserSarki, index) => renderKonserSarki(konserSarki, index))}
                </Rows>
            </Rows>
        </CsmGenericGroupBox>
    );

}

export default KonserSarkiListControl;