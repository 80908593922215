/* eslint-disable @typescript-eslint/no-explicit-any */
import { FocusEvent, useEffect, useState, KeyboardEvent } from "react";
import { IconButton, InputAdornment, TextField, TextFieldProps, ThemeProvider, Typography } from "@mui/material";
import { narrowTheme } from "../../../app/theme";
import BackspaceIcon from '@mui/icons-material/Backspace';
import StringHelper from "../../helpers/StringHelper";

type selfProps = {
    onChange: (e: string) => void,
    value: string | null | undefined,
    showStats?: boolean,
    showClear?: boolean,
    narrow?: boolean,
    appendMaxlenOnLabel?: number,
    autoShowErrorOnEmpty?: boolean,
    inputFontSize?: string,
    errorText?: string | false | undefined,
};
export type ICsmTextFieldProps = Omit<TextFieldProps, "onChange" | "type" | "value"> & selfProps;

function CsmTextField(props: ICsmTextFieldProps & { eventOnKeyPress?: boolean }) {

    const { narrow, eventOnKeyPress, ...rest } = props;

    const result = eventOnKeyPress ?
        <CsmTextFieldSlow {...rest} />
        :
        <CsmTextFieldFast {...rest} />

    if (narrow)
        return <ThemeProvider theme={narrowTheme}>{result}</ThemeProvider>
    else
        return result;
}

function kelimeSay(icerik: string | null | undefined) {
    const count = icerik?.length === 0 ? 0 : icerik?.trim().split(/\s+/).length ?? 0;
    return count === 0 ? "-" : count.toString();
}

function harfSay(icerik: string | null | undefined) {
    const count = icerik?.length ?? 0;
    return count === 0 ? "-" : count.toString();
}

function CsmTextFieldFast(props: ICsmTextFieldProps) {
    const { autoShowErrorOnEmpty, errorText, error, helperText, appendMaxlenOnLabel, showStats, showClear, fullWidth, onChange, value, label, inputFontSize, ...rest } = props;
    const [stateValue, setStateValue] = useState<string | null | undefined>(value);

    const helperTextToUse = (typeof errorText === "string") ? errorText : helperText;
    const errorToUse = (typeof errorText === "string") ? (errorText ?? "").length > 0 : error;

    useEffect(() => {
        setStateValue(() => { return props.value })
    }, [props.value]);

    const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        e.target.value = String(stringValue);
        onChange(stringValue);
    };

    const labelToUse = !appendMaxlenOnLabel ? label : (StringHelper.nullToEmpty(stateValue).length <= appendMaxlenOnLabel ? label : `${label} (max ${appendMaxlenOnLabel})`);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && !props.multiline) {
            e.preventDefault();
            const stringValue = (e.target as any).value;
            (e.target as any).value = String(stringValue);
            onChange(stringValue);
        }
    }

    const clearAdornment = <InputAdornment position="end">
        <IconButton
            onClick={() => onChange("")}
            onMouseDown={e => e.preventDefault()}
            edge="end">
            <BackspaceIcon fontSize="small" color="disabled" />
        </IconButton>
    </InputAdornment>

    const innerContent =
        <TextField
            label={labelToUse}
            value={stateValue}
            error={(autoShowErrorOnEmpty ? (stateValue ?? "") === "" : errorToUse)}
            helperText={helperTextToUse}
            type="text"
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            onChange={(e) => setStateValue(() => { return e.target.value })}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            InputProps={{
                style: { fontSize: inputFontSize ?? undefined },
                endAdornment: !showClear ? undefined : clearAdornment
            }}
            {...rest}
        />

    if (showStats === true)
        return <>
            {innerContent}
            <Typography
                style={{ marginTop: -14, marginRight: 5 }}
                fontStyle="italic"
                fontSize={9}
                textAlign="right">{`${kelimeSay(stateValue)} kelime, ${harfSay(stateValue)} harf`}
            </Typography>
        </>
    else
        return innerContent;
}

function CsmTextFieldSlow(props: ICsmTextFieldProps) {
    const { appendMaxlenOnLabel, errorText, error, helperText, label, showStats, showClear, fullWidth, onChange, inputFontSize, value, ...rest } = props;

    const helperTextToUse = (typeof errorText === "string") ? errorText : helperText;
    const errorToUse = (typeof errorText === "string") ? (errorText ?? "").length > 0 : error;
    const labelToUse = !appendMaxlenOnLabel ? label : (StringHelper.nullToEmpty(value).length <= appendMaxlenOnLabel ? label : `${label} (max ${appendMaxlenOnLabel})`);

    const onChangeInternal = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        e.target.value = String(stringValue);
        onChange(stringValue);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && !props.multiline) {
            e.preventDefault();
            const stringValue = (e.target as any).value;
            (e.target as any).value = String(stringValue);
            onChange(stringValue);
        }
    }

    const clearAdornment = <InputAdornment position="end">
        <IconButton
            onClick={() => onChange("")}
            onMouseDown={e => e.preventDefault()}
            edge="end">
            <BackspaceIcon fontSize="small" color="disabled" />
        </IconButton>
    </InputAdornment>

    const innerContent = <TextField
        label={labelToUse}
        value={value}
        type="text"
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        onChange={onChangeInternal}
        onKeyDown={onKeyDown}
        error={errorToUse}
        helperText={helperTextToUse}
        InputProps={{
            style: { fontSize: inputFontSize ?? undefined, },
            endAdornment: !showClear ? undefined : clearAdornment
        }}
        {...rest}
    />;

    if (showStats === true)
        return <>
            {innerContent}
            <Typography
                style={{ marginTop: -14, marginRight: 5 }}
                fontStyle="italic"
                fontSize={9}
                textAlign="right">{`${kelimeSay(value)} kelime, ${harfSay(value)} harf`}
            </Typography>
        </>
    else
        return innerContent;

}

export default CsmTextField;