import { createTheme, ThemeOptions } from "@mui/material";
import EnvironmentHelper from "../helpers/EnvironmentHelper";
import JsonHelper from "../library/helpers/JsonHelper";

const defaultThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: EnvironmentHelper.isTest() ? "#f1f2da" : "#fafafa",
      paper: "#ccf0ea",
    },    
    primary: {
      main: EnvironmentHelper.isTest() ? '#b6bc47' : '#004d40',      
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "58px",
          minHeight: "58px !important",
          maxHeight: "58px"
        },
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: 0,
          marginBottom: 0,
        },
      }
    },
  }
};

const narrowThemeOptions: ThemeOptions = {
  components: {
    MuiTextField: {
      defaultProps: {
        margin: "none",
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(14px, -8px) scale(0.75)",
          marginLeft: "0px",
        },
        root: {
          transform: "translate(4px, 6px) scale(1)",
          marginLeft: "5px",
        }
      }
    },
    MuiInputAdornment:
    {
      styleOverrides: {
        root: {
          marginLeft: "5px",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: "28px",
          paddingTop: "5px",
          paddingBottom: "1px",
          paddingLeft: "5px",
          paddingRight: "5px",

        },
        root: {
          // padding: "1px",
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          paddingTop: "5px !important",
          paddingBottom: "1px !important",
        },
        root: {
          padding: "0px",
        },
        inputRoot: {
          padding: "0px",
        },
      },
    },
  },
  spacing: [0, 1, 2, 4, 8, 16],
};

const narrowThemeOptionsSelectAdditions: ThemeOptions = {
  components: {
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(14px, -8px) scale(0.75)",
          marginLeft: "0px",
        },
        root: {
          transform: "translate(4px, 4px) scale(1)",
          marginLeft: "5px",
        }
      }
    }
  }
};

const theme = createTheme(defaultThemeOptions);

export const narrowTheme = createTheme(JsonHelper.mergeDeep(defaultThemeOptions, narrowThemeOptions));
export const narrowThemeForSelect = createTheme(JsonHelper.mergeDeep(JsonHelper.mergeDeep(defaultThemeOptions, narrowThemeOptions), narrowThemeOptionsSelectAdditions));

export default theme;