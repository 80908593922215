/* eslint-disable react/no-children-prop */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import SarkiBilgileriPopupEditor from "./SarkiBilgileriPopupEditor";
import SarkiLinkListPopupEditor from "./SarkiLinkListPopupEditor";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import Cell from "../../../library/components/layout/Cell";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import { CsmText } from "../../../library/components/mui/CsmText";
import appColors from "../../../app/appColors";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import AdaySarkiListPopupEditor from "./AdaySarkiListPopupEditor";

function AdaySarkiYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);

  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  });

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <Inner genelDurumHelper={genelDurumHelper} />
}

function Inner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  const yonetimYetkisi = genelDurumHelper.HasYetkiAll([EnmYetki["Şarkı Yönetim"]]);

  const sarkiRowsGetir = (sezon: SezonModel, sarkiList: SarkiModel[], siraNoGoster: boolean): JSX.Element | null => {
    const sarkiItems = sarkiList.length === 0 ?
      <CsmText note>Bu sezona dair aday şarkı bulunmamaktadır</CsmText>
      :
      sarkiList.map((item) => {
        const durum = item.kayitGondermeSurecinde ? "Kayıt Sürecince" : "Onaylı";
        const durumStyle = item.kayitGondermeSurecinde ? { color: appColors.RED } : { color: appColors.RED_DARK };

        const rightButtons: ICustomSwipeButton[] = [];
        rightButtons.push({ icon: appIcons.CrudIcons.guncelle, text: "Güncelle", onClick: () => sarkiBilgiGuncelle(item, yonetimYetkisi, yonetimYetkisi), color: "success" });
        rightButtons.push({ icon: appIcons.Sarki.link, text: "Linkler", onClick: () => sarkiLinkListGuncelle(item, yonetimYetkisi, yonetimYetkisi), color: "success" });

        const sarkiIsim = (siraNoGoster ? `${item.sira}. ` : "") + `${item.isim} (${BusinessHelper.SesGrubu.IdListtenSATBUret(genelDurumHelper.SeciliKoroModel.tumSesGruplari, item.sesGrupIdList)})`;
        return (
          <CsmSwipableListItem key={item.id} width={550}>
            <div style={{ height: 40, width: "500px" }}>
              <Columns hasLightBottomBorder>
                {rightButtons.map((item) =>
                  <Button key={item.text} variant="text" title={item.text} color={item.color} style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => item.onClick!()}>
                    {item.icon}
                  </Button>
                )}
                <Rows width={300}>
                  <Typography noWrap style={{ overflow: "hidden" }}>{sarkiIsim}</Typography>
                  <Typography style={{ fontSize: "12px", fontStyle: "italic", ...durumStyle }}>Durum: {durum} <span style={{ color: appColors.TEXT_PRIMARY }}> ({item.sarkiLinkModelList.length} Link)</span></Typography>
                </Rows>
              </Columns>
            </div>
          </CsmSwipableListItem>
        );
      });

    return <Rows>
      <Cell>
        <Button disabled={!yonetimYetkisi} onClick={() => sarkiEkleCikart(sezon)}>
          {appIcons.CrudIcons.ekle}
          <Typography>Yeni Şarkı Ekle Çıkart</Typography>
        </Button>
      </Cell>
      {sarkiItems}
    </Rows>
  }

  const sarkiEkleCikart = async (sezon: SezonModel) => {

    const response = await AlertHelper.ShowClosableContent<string[]>(genelDurumHelper.translate("Aday Şarkılarını Güncelle", { sezonIsim: sezon.isim }), (closer) =>
      <AdaySarkiListPopupEditor
        adaySarkiList={tumSarkilar.where(e => e.adaySezonModelIdList.contains(sezon.id))}
        sezonId={sezon.id}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarkilar = seciliKoro.tumSarkilar;
        const yeniSecilenIdler = response;

        const orijinalAdaySarkilar = sarkilar.where(e => e.adaySezonModelIdList.contains(sezon.id));
        const orijinalAdayOlmayanSarkilar = sarkilar.where(e => e.adaySezonModelIdList.contains(sezon.id) === false);

        const silinenler = orijinalAdaySarkilar.where(e => yeniSecilenIdler.contains(e.id) === false);
        const eklenenler = orijinalAdayOlmayanSarkilar.where(e => yeniSecilenIdler.contains(e.id) === true);

        silinenler.forEach(e => e.adaySezonModelIdList.remove(sezon.id));
        eklenenler.forEach(e => e.adaySezonModelIdList.push(sezon.id));
      });
  }

  const sarkiBilgiGuncelle = async (sarkiModel: SarkiModel, guncellenebilir: boolean, silinebilir: boolean) => {
    const response = await AlertHelper.ShowClosableContent<SarkiModel>(genelDurumHelper.translate("Şarkısı Güncelle", { sarkiIsim: sarkiModel.isim }), (closer) =>
      <SarkiBilgileriPopupEditor
        guncellenebilir={guncellenebilir}
        silinebilir={silinebilir}
        guncellemeMi={true}
        sarki={sarkiModel}
        tumSarkilar={genelDurumHelper.SeciliKoroModel.tumSarkilar}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarkilar = seciliKoro.tumSarkilar;

        sarkilar.remove(sarkilar.single(e => e.id === response!.id));
        sarkilar.push(response!);
      });
  };

  const sarkiLinkListGuncelle = async (sarki: SarkiModel, guncellenebilir: boolean, silinebilir: boolean) => {
    const response = await AlertHelper.ShowClosableContent<WithContentRequestModel<SarkiLinkModel[]>>(`${sarki.isim} Şarkısının Bağlantılarını Güncelle`, (closer) =>
      <SarkiLinkListPopupEditor
        guncellenebilir={guncellenebilir}
        silinebilir={silinebilir}
        sarki={sarki}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const sarkiOnYonetim = seciliKoro.tumSarkilar.single(e => e.id === sarki.id);

        sarkiOnYonetim.sarkiLinkModelList = response!.model!.slice();
      });
  }

  let sarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
  if (searchFilter !== "") {
    const searchFilterLower = searchFilter.toLocaleLowerCase();
    sarkilar = sarkilar.where(e => e.isim.toLocaleLowerCase().indexOf(searchFilterLower) > -1);
  }

  const tumSezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar.orderByDesc(e => e.isim);

  const tumSarkilar = sarkilar.orderByTurkish(e => e.isim);
  const sezonVeSarkilari = tumSezonlar.select(e => { return { sezon: e, sarkilar: tumSarkilar.where(t => t.adaySezonModelIdList.contains(e.id)) } });

  return (
    <MainLayout
      location={mainScreenLocations.sarkiYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <ListItem>
          <Columns flex>
            <Cell width={300}>
              <CsmTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
            </Cell>
            <Cell flex>
            </Cell>
          </Columns>
        </ListItem>
        {sezonVeSarkilari.map(sezonVeSarki =>
          <CsmAccordionSimple
            key={sezonVeSarki.sezon.id}
            title={`${sezonVeSarki.sezon.isim} (${sezonVeSarki.sarkilar.length} aday şarkı)`}
            children={sarkiRowsGetir(sezonVeSarki.sezon, sezonVeSarki.sarkilar, true)}
          />
        )}
      </List>
    </MainLayout>
  );
}

export default AdaySarkiYonetimScreen;