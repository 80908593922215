/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-extend-native */
// cihan
export { }

declare global {
    interface String {
        isNullOrEmpty(): boolean;
        splitNewLines(): string[];
        toTurkishCamelCase(): string;
        emptyToDash(): string;
    }
    interface Array<T> {
        distinct<U>(predicate?: (value: T) => U): Array<T>;
        where(condition: (value: T) => boolean): T[];
        count(condition: (value: T) => boolean): number;
        select<U>(predicate: (value: T) => U, thisArg?: any): U[];
        selectMany<U>(predicate: (value: T) => U[], thisArg?: any): U[];
        orderBy<U>(predicate: (value: T) => U, thisArg?: any): T[];
        orderByTurkish(predicate: (value: T) => string, thisArg?: any): T[];
        orderByDesc<U>(predicate: (value: T) => U, thisArg?: any): T[];
        orderByTurkishDesc(predicate: (value: T) => string, thisArg?: any): T[];
        single(condition?: (value: T) => boolean, thisArg?: any): T;
        singleOrDefault(condition?: (value: T) => boolean, thisArg?: any): T | null;
        firstOrDefault(condition?: (value: T) => boolean, thisArg?: any): T | null;
        first(condition?: (value: T) => boolean, thisArg?: any): T;
        lastOrDefault(condition?: (value: T) => boolean, thisArg?: any): T | null;
        last(condition?: (value: T) => boolean, thisArg?: any): T;
        union(other: T[], thisArg?: any): T[];
        except(other: T[], thisArg?: any): T[];
        intersection(other: T[], thisArg?: any): T[];
        remove(item: T, thisArg?: any): T[];
        contains(item: T): boolean;
        any(condition?: (value: T) => boolean, thisArg?: any): boolean;
        all(condition?: (value: T) => boolean, thisArg?: any): boolean;
        sum(predicate: (value: T) => number | undefined | null, thisArg?: any): number;
        ave(predicate: (value: T) => number | undefined | null, thisArg?: any): number;
        min<U>(predicate: (value: T) => U, thisArg?: any): U | null;
        max<U>(predicate: (value: T) => U, thisArg?: any): U | null;
        groupBy<U>(predicate: (value: T) => U, thisArg?: any): { key: U, list: T[] }[];
        groupBy2<U, K>(predicate1: (value: T) => U, predicate2: (value: T) => K, thisArg?: any): { key1: U, key2: K, list: T[] }[];
    }
}

const turkishCollator = new Intl.Collator('tr', { numeric: true, sensitivity: 'base' });

if (!String.prototype.emptyToDash) {
    String.prototype.emptyToDash = function (this: string): string {
        return this === "" ? "-" : this;
    }
}

if (!String.prototype.toTurkishCamelCase) {
    String.prototype.toTurkishCamelCase = function (this: string): string {
        return this.split(" ").select(e => e[0].toString().toLocaleUpperCase("tr-tr") + "" + e.substring(1).toLocaleLowerCase("tr-tr")).join(" ")
    }
}

if (!String.prototype.isNullOrEmpty) {
    String.prototype.isNullOrEmpty = function (this: string): boolean {
        return this === null || this === "";
    }
}

if (!String.prototype.splitNewLines) {
    String.prototype.splitNewLines = function (this: string): string[] {
        if (this.trim().length === 0) return [];
        return this.replace("\r", "").trim().split("\n");
    }
}

if (!Array.prototype.distinct) {
    Array.prototype.distinct = function <T, U>(this: T[], predicate?: (value: T) => U): T[] {
        if (!predicate)
            return Array.from(new Set(this).values());
        else {
            const predicateList: U[] = [];
            const result: T[] = [];

            this.forEach(item => {
                const distinctProp = predicate(item);
                if (!predicateList.contains(distinctProp)) {
                    predicateList.push(distinctProp);
                    result.push(item);
                }
            });

            return result;
        }
    }
}

if (!Array.prototype.where) {
    Array.prototype.where = function <T>(this: T[], condition: (value: T) => boolean): T[] {
        return this.filter(condition);
    }
}

if (!Array.prototype.count) {
    Array.prototype.count = function <T>(this: T[], condition: (value: T) => boolean): number {
        return this.filter(condition).length;
    }
}

if (!Array.prototype.select) {
    Array.prototype.select = function <T, U>(predicate: (value: T) => U, thisArg?: any): U[] {
        return this.map(predicate);
    }
}

if (!Array.prototype.min) {
    Array.prototype.min = function <T, U>(predicate: (value: T) => U, thisArg?: any): U | null {
        return this.select(predicate).orderBy(e => e).firstOrDefault();
    }
}

if (!Array.prototype.max) {
    Array.prototype.max = function <T, U>(predicate: (value: T) => U, thisArg?: any): U | null {
        return this.select(predicate).orderByDesc(e => e).firstOrDefault();
    }
}

if (!Array.prototype.selectMany) {
    Array.prototype.selectMany = function <T, U>(predicate: (value: T) => U[], thisArg?: any): U[] {
        return this.flatMap(predicate);
    }
}

if (!Array.prototype.orderBy) {
    Array.prototype.orderBy = function <T, U>(predicate: (value: T) => U, thisArg?: any): T[] {
        const copy = [...this];
        return copy.sort((a, b) => {
            const resA = predicate(a);
            const resB = predicate(b);
            return resA > resB ? 1 : resA < resB ? -1 : 0;
        });
    }
}


if (!Array.prototype.orderByTurkish) {
    Array.prototype.orderByTurkish = function <T>(predicate: (value: T) => string, thisArg?: any): T[] {
        const copy = this.slice();
        return copy.sort((a, b) => {
            const resA = predicate(a);
            const resB = predicate(b);
            return turkishCollator.compare(resA, resB);
        });
    }
}

if (!Array.prototype.orderByDesc) {
    Array.prototype.orderByDesc = function <T, U>(predicate: (value: T) => U, thisArg?: any): T[] {
        const copy = [...this];
        return copy.sort((a, b) => {
            const resA = predicate(a);
            const resB = predicate(b);
            return resA > resB ? -1 : resA < resB ? 1 : 0;
        });
    }
}

if (!Array.prototype.orderByTurkishDesc) {
    Array.prototype.orderByTurkishDesc = function <T>(predicate: (value: T) => string, thisArg?: any): T[] {
        const copy = this.slice();
        return copy.sort((a, b) => {
            const resA = predicate(a);
            const resB = predicate(b);
            return turkishCollator.compare(resB, resA);
        });
    }
}

if (!Array.prototype.single) {
    Array.prototype.single = function <T>(this: T[], condition?: (value: T) => boolean): T {
        let result: T[];
        if (condition == null)
            result = this;
        else
            result = this.filter(condition);

        if (result.length === 0) {
            debugger;
            throw new Error("Array.single metodunda hata: Listede nesne bulunmamaktadır")
        }
        else if (result.length > 1) {
            debugger;
            throw new Error("Array.single metodunda hata: Listede birden nesne bulunmaktadır")
        }

        return result[0];
    }
}

if (!Array.prototype.singleOrDefault) {
    Array.prototype.singleOrDefault = function <T>(this: T[], condition?: (value: T) => boolean): T | null {
        let result: T[];
        if (condition == null)
            result = this;
        else
            result = this.filter(condition);

        if (result.length === 0)
            return null;
        else if (result.length > 1) {
            debugger;
            throw new Error("Array.singleOrDefault metodunda hata: Listede birden nesne bulunmaktadır")
        }

        return result[0];
    }
}

if (!Array.prototype.firstOrDefault) {
    Array.prototype.firstOrDefault = function <T>(this: T[], condition?: (value: T) => boolean): T | null {
        let result: T[];
        if (condition == null)
            result = this;
        else
            result = this.filter(condition);

        if (result.length === 0)
            return null;

        return result[0];
    }
}

if (!Array.prototype.first) {
    Array.prototype.first = function <T>(this: T[], condition?: (value: T) => boolean): T {
        let result: T[];
        if (condition == null)
            result = this;
        else
            result = this.filter(condition);

        if (result.length === 0) {
            debugger;
            throw new Error("Array.first metodunda hata: Listede nesne bulunmaktadır")
        }

        return result[0];
    }
}


if (!Array.prototype.lastOrDefault) {
    Array.prototype.lastOrDefault = function <T>(this: T[], condition?: (value: T) => boolean): T | null {
        let result: T[];
        if (condition == null)
            result = this;
        else
            result = this.filter(condition);

        if (result.length === 0)
            return null;

        return result[result.length - 1];
    }
}

if (!Array.prototype.last) {
    Array.prototype.last = function <T>(this: T[], condition?: (value: T) => boolean): T {
        let result: T[];
        if (condition == null)
            result = this;
        else
            result = this.filter(condition);

        if (result.length === 0) {
            debugger;
            throw new Error("Array.last metodunda hata: Listede nesne bulunmaktadır")
        }

        return result[result.length - 1];
    }
}

if (!Array.prototype.union) {
    Array.prototype.union = function <T>(other: T[], thisArg?: any): T[] {
        return [...this, ...other].distinct();
    }
}

if (!Array.prototype.except) {
    Array.prototype.except = function <T>(other: T[], thisArg?: any): T[] {
        return this.filter((value) => other.indexOf(value) === -1);
    }
}

if (!Array.prototype.intersection) {
    Array.prototype.intersection = function <T>(other: T[], thisArg?: any): T[] {
        return this.filter((value) => other.indexOf(value) > -1);
    }
}

if (!Array.prototype.remove) {
    Array.prototype.remove = function <T>(item: T, thisArg?: any): T[] {
        const foundIndex = this.indexOf(item);
        if (foundIndex >= 0)
            this.splice(foundIndex, 1)
        return this;
    }
}

if (!Array.prototype.contains) {
    Array.prototype.contains = function <T>(item: T, thisArg?: any): boolean {
        return this.any((e) => e === item);
    }
}

if (!Array.prototype.any) {
    Array.prototype.any = function <T>(condition?: (value: T) => boolean, thisArg?: any): boolean {
        if (condition == null)
            return this.length > 0;

        for (const subItem of this)
            if (condition(subItem) === true)
                return true;

        return false;
    }
}

if (!Array.prototype.all) {
    Array.prototype.all = function <T>(condition?: (value: T) => boolean, thisArg?: any): boolean {
        if (condition == null)
            return this.length > 0;

        for (const subItem of this)
            if (condition(subItem) === false)
                return false;

        return true;
    }
}

if (!Array.prototype.sum) {
    Array.prototype.sum = function <T>(predicate: (value: T) => number | undefined | null, thisArg?: any): number {

        const list = this.select(predicate);
        let sum = 0;
        for (let i = 0; i < list.length; i++)
            if (list[i])
                sum += list[i] as any;

        return sum;
    }
}

if (!Array.prototype.groupBy) {
    Array.prototype.groupBy = function <T, U>(predicate: (value: T) => U, thisArg?: any): { key: U, list: T[] }[] {

        const keyList = this.select(predicate).distinct();

        return keyList.map(key => {
            return {
                key: key,
                list: this.where((e: T) => predicate(e) === key),
            }
        });
    }
}

if (!Array.prototype.groupBy2) {
    Array.prototype.groupBy2 = function <T, U, K>(predicate1: (value: T) => U, predicate2: (value: T) => K, thisArg?: any): { key1: U, key2: K, list: T[] }[] {

        const key1List = this.select(predicate1).distinct();

        return key1List.flatMap(key1 => {
            const list1 = this.where((e: T) => predicate1(e) === key1);

            const key2List = list1.select(predicate2).distinct();

            return key2List.map(key2 => {
                return {
                    key1: key1,
                    key2: key2,
                    list: list1.where((e: T) => predicate2(e) === key2)
                }
            });
        });
    }

    if (!Array.prototype.ave) {
        Array.prototype.ave = function <T>(predicate: (value: T) => number | undefined | null, thisArg?: any): number {

            if (this.length === 0)
                return 0;

            const list = this.select(predicate);
            let ave = 0;
            for (let i = 0; i < list.length; i++)
                if (list[i])
                    ave += list[i] as any;

            return ave / this.length;
        }
    }
}