// AutoGenerated
import { EnmKatilimBildirmeDurum } from "../enums/EnmKatilimBildirmeDurum"
import { EnmKatilimDurum } from "../enums/EnmKatilimDurum"

export class HesapCalismaModel {
    hesapModelId!: string;
    calismaModelId!: string;
    katilimDurum!: EnmKatilimBildirmeDurum | null;
    katilmamaGerekce!: string | null;
    derseGercekKatilimDurum!: EnmKatilimDurum | null;
}