import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import Columns from "../../../library/components/layout/Columns";
import { CsmText } from "../../../library/components/mui/CsmText";
import StringHelper from "../../../library/helpers/StringHelper";
import KorocanThumbnailIcerikId from "../../../components/business/KorocanThumbnailIcerikId";
import Cell from "../../../library/components/layout/Cell";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";


interface IGuncelOzgecmisDisplayerProps {
    genelDurumHelper: GenelDurumHelper,
}

function GuncelOzgecmisDisplayer(props: IGuncelOzgecmisDisplayerProps) {
    const { genelDurumHelper } = props;

    const aktifKoro = genelDurumHelper.SeciliKoroModel;

    if (!aktifKoro.koroAyarlari.ozgecmisKullanilsin)
        return null;

    const ozgecmisselHesaplar = aktifKoro.hesapBilgiList.where(e => e.aktif && StringHelper.isNotEmpty(e.ozgecmis));

    function renderRastgeleOzgecmis() {
        let randomIndex = Math.ceil(Math.random() * ozgecmisselHesaplar.length) - 1;
        if (randomIndex < 0) randomIndex = 0;
        if (randomIndex > ozgecmisselHesaplar.length - 1) randomIndex = ozgecmisselHesaplar.length - 1;

        const ozgecmisHesap = ozgecmisselHesaplar[randomIndex];

        return renderHesapOzgecmis(ozgecmisHesap);
    }

    function renderHesapOzgecmis(ozgecmisHesap: HesapBilgiVeKullaniciModel) {
        const kadroIdList = ozgecmisHesap.kadroVeSesGrubuBilgiModelList.select(e => e.kadroModelId).distinct();
        const sezonIdList = aktifKoro.tumKadrolar.where(e => kadroIdList.contains(e.id)).select(e => e.sezonModelId).distinct();
        const sezonlar = aktifKoro.tumSezonlar.where(sezon => sezonIdList.contains(sezon.id)).orderByDesc(e => e.isim);
        const konserler = aktifKoro.tumKonserler.where(e => kadroIdList.contains(e.kadroModelId));
        const calismalar = aktifKoro.hesapCalismaModelList.where(e => e.hesapModelId === ozgecmisHesap.id);
        const kayitlarSarkiIdList = aktifKoro.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === ozgecmisHesap.id).select(e => e.sarkiModelId).distinct();

        return <Columns wrap>
            <Cell width={150} mr={10} mt={15}>
                <KorocanThumbnailIcerikId
                    key="MenuFoto"
                    icerikId={ozgecmisHesap.resimIcerikId}
                    size="xlarge"
                    genelDurumHelper={genelDurumHelper}
                    title={`${ozgecmisHesap.kullaniciModel.ad} ${ozgecmisHesap.kullaniciModel.soyad}`}
                    saveToStorage="None" />
            </Cell>
            <Rows mt={15} style={{ minWidth: "300px" }}>
                <CsmText header>{`${ozgecmisHesap.kullaniciModel.ad} ${ozgecmisHesap.kullaniciModel.soyad}`}</CsmText>
                {sezonlar.length > 0 &&
                    <Columns>
                        <CsmText bold>Aktif Olduğu Sezonlar:</CsmText>
                        <CsmText style={{ marginLeft: "5px" }}>{`${sezonlar.length} Sezon`}</CsmText>
                    </Columns>
                }
                {sezonlar.length === 0 ? null : sezonlar.length === 1 ?
                    <CsmText note>{sezonlar[0].isim}</CsmText>
                    :
                    <CsmText note>{`${sezonlar.last().isim} - ${sezonlar.first().isim} arası`}</CsmText>
                }
                {aktifKoro.koroAyarlari.konserlerKullanilsin && konserler.length > 0 &&
                    <Columns>
                        <CsmText bold>Katıldığı Konserler:</CsmText>
                        <CsmText style={{ marginLeft: "5px" }}>{`${konserler.length} Konser`}</CsmText>
                    </Columns>
                }
                {calismalar.length > 0 &&
                    <Columns>
                        <CsmText bold>Katıldığı Çalışma Sayısı:</CsmText>
                        <CsmText style={{ marginLeft: "5px" }}>{`${calismalar.length}`}</CsmText>
                    </Columns>
                }
                {kayitlarSarkiIdList.length > 0 &&
                    <Columns>
                        <CsmText bold>Kayıt yaptığı şarkı sayısı:</CsmText>
                        <CsmText style={{ marginLeft: "5px" }}>{`${kayitlarSarkiIdList.length}`}</CsmText>
                    </Columns>
                }
            </Rows>
            <Rows flex mt={15} style={{ minWidth: 300}}>
                <CsmText bold>Sevimli Özgeçmişi :)</CsmText>
                <CsmText note style={{ whiteSpace: "pre-line" }} >{ozgecmisHesap.ozgecmis}</CsmText>
            </Rows>
        </Columns>
    }

    return <Rows flex>
        {ozgecmisselHesaplar.length > 0 &&
            <Rows mt={15} ml={15}>
                <CsmHeader ml={-15} pl={15} hasVeryLightBottomBorder>{genelDurumHelper.translate("Bir Koristimiz ile tanışalım") + " :)"}</CsmHeader>
                {StringHelper.isNotEmpty(genelDurumHelper.SeciliHesapBilgiModel.ozgecmis) ? null : <CsmText smallNote>{genelDurumHelper.translate("Siz de burada görünmek istiyorsanız, Profilinizdeki 'Özgeçmiş Güncelle' düğmesi üzerinden özgeçmişinizi güncelleyiniz :)")}</CsmText>}
                {renderRastgeleOzgecmis()}
            </Rows>
        }
    </Rows>
}


export default GuncelOzgecmisDisplayer;