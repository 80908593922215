// AutoGenerated
export class AnketSoruSecenekModel {
    sira!: number;
    secenekIcerik!: string;
    agirlik!: number;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}