/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import axios, { AxiosError, AxiosProgressEvent } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AppDispatch } from "../app/store";
import { hideLoading, showLoading, showLoadingWithPercent } from "../features/loadingMessageSlice";
import AlertHelper from "../library/helpers/AlertHelper";
import JsonHelper from "../library/helpers/JsonHelper";
import { KorocanTranslateFuncType } from "../translations/TranslationHelper";
import { RequestEndpoints } from "./RequestEndpoints";

export class WebResponse<T> {
    constructor(data: T | null, status: number, statusText: string, successfull: boolean) {
        this.data = data;
        this.status = status;
        this.statusText = statusText;
        this.successfull = successfull;
    }

    data: T | null;
    status: number;
    statusText: string;
    successfull: boolean;
}

export type IRequestHelper = ReturnType<typeof RequestEndpoints>;  // string

export interface IRequestProxy {
    PerformGetFileAction: (uri: string, data: Record<string, string | null> | undefined, message: string) => Promise<WebResponse<Blob>>;
    PerformGetAction: <T>(uri: string, data: Record<string, string | null> | undefined, message: string) => Promise<WebResponse<T>>;
    PerformPostAction: <TResult>(uri: string, data: any, message: string) => Promise<WebResponse<TResult>>;
}

export function RequestProxy(navigate: NavigateFunction, dispatch: AppDispatch, translate: KorocanTranslateFuncType): IRequestProxy {
    return {
        PerformGetAction: async <TResult>(uri: string, data: Record<string, string | null> | undefined, message: string): Promise<WebResponse<TResult>> => {
            let result: WebResponse<TResult>;
            try {
                if (message)
                    dispatch(showLoading(message));

                result = await RequestHttpMethods._get<TResult>(uri, data)

                if (message)
                    dispatch(hideLoading(message));
                return result;
            }
            catch (error) {
                if (message)
                    dispatch(hideLoading(message));
                if ((error as any).isAxiosError === true) {
                    const errorAsAxios: AxiosError = error as AxiosError;

                    // request atarken hata aldık
                    if (errorAsAxios.response == null) {
                        AlertHelper.ShowMessage(translate("Client Request Hatası"), `Sunucuya istek yapılırken aşağıdaki hata alınmıştir.\n\nAdres: ${errorAsAxios.config?.baseURL}\nPatika: ${errorAsAxios.config?.url}\n\nHata İçeriği: ${errorAsAxios.message}`);
                        return new WebResponse<TResult>(null, 0, "", false);
                    }
                    else if (errorAsAxios.response?.status === 401) {
                        navigate('/login');
                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                    else if (errorAsAxios.response?.status === 500 || errorAsAxios.response?.status === 400) {
                        const errorLog: any = errorAsAxios.response.data as any; //Cii

                        showAxiosError(errorLog, "get");

                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                }

                throw error;
            }
        },
        PerformPostAction: async <TResult>(uri: string, data: any, message?: string): Promise<WebResponse<TResult>> => {
            let result: WebResponse<TResult>;
            try {
                if (message)
                    dispatch(showLoading(message));

                result = await RequestHttpMethods._post<TResult>(uri, data)

                if (message)
                    dispatch(hideLoading(message));
                return result;
            }
            catch (error) {
                if (message)
                    dispatch(hideLoading(message));
                if ((error as any).isAxiosError === true) {
                    const errorAsAxios: AxiosError = error as AxiosError;

                    // request atarken hata aldık
                    if (errorAsAxios.response == null) {
                        AlertHelper.ShowMessage(translate("Client Request Hatası"), `Sunucuya istek yapılırken aşağıdaki hata alınmıştir.\n\nAdres: ${errorAsAxios.config?.baseURL}\nPatika: ${errorAsAxios.config?.url}\n\nHata İçeriği: ${errorAsAxios.message}`);
                        return new WebResponse<TResult>(null, 0, "", false);
                    }
                    else if (errorAsAxios.response?.status === 401) {
                        navigate('/login');
                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                    else if (errorAsAxios.response?.status === 500 || errorAsAxios.response?.status === 400) {
                        const errorLog: any = errorAsAxios.response.data as any; //Cii

                        showAxiosError(errorLog, "post")

                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                }

                throw error;
            }
        },
        PerformGetFileAction: async (uri: string, data: Record<string, string | null> | undefined, message: string): Promise<WebResponse<Blob>> => {
            let result: WebResponse<Blob>;
            try {
                if (message)
                    dispatch(showLoadingWithPercent({ message: message, percent: 0}));

                result = await RequestHttpMethods._getBlob(uri, data, progress => {
                    const prgs = progress.progress ?? 0;
                    if (prgs <= 0 || prgs >= 1)
                        return;
                    dispatch(showLoadingWithPercent({ message: message, percent: prgs }));
                })

                if (message)
                    dispatch(hideLoading(message));
                return result;
            }
            catch (error) {
                if (message)
                    dispatch(hideLoading(message));
                if ((error as any).isAxiosError === true) {
                    const errorAsAxios: AxiosError = error as AxiosError;

                    // request atarken hata aldık
                    if (errorAsAxios.response == null) {
                        AlertHelper.ShowMessage("Client Request Hatası", `Sunucuya istek yapılırken aşağıdaki hata alınmıştir.\n\nAdres: ${errorAsAxios.config?.baseURL}\nPatika: ${errorAsAxios.config?.url}\n\nHata İçeriği: ${errorAsAxios.message}`);
                        return new WebResponse<Blob>(null, 0, "", false);
                    }
                    else if (errorAsAxios.response?.status === 401) {
                        navigate('/login');
                        return new WebResponse<Blob>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                    else if (errorAsAxios.response?.status === 500 || errorAsAxios.response?.status === 400) {
                        const errorLog: any = errorAsAxios.response.data as any; //Cii

                        showAxiosError(errorLog, "File");

                        return new WebResponse<Blob>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                }

                throw error;
            }
        },
    };
}
export function RequestHelper(navigate: NavigateFunction, dispatch: AppDispatch, translate: KorocanTranslateFuncType): IRequestHelper {
    const proxy = RequestProxy(navigate, dispatch, translate);

    return RequestEndpoints(proxy, translate);
}

export const RequestHttpMethods = {
    async _get<T>(url: string, data: Record<string, string | null> | undefined, onDownloadProgress?: (e: AxiosProgressEvent) => void): Promise<WebResponse<T>> {
        if (data) {
            const list: string[] = [];
            for (const key of Object.keys(data)) {
                if (data[key] != null && data[key] !== "")
                    list.push(`${key}=${data[key]}`)
            }
            for (let i = 0; i < list.length; i++) {
                if (i === 0)
                    url += "?";
                else
                    url += "&";
                url += list[i];
            }
        }

        const response = await axios.get<T>(url, {
            onDownloadProgress: onDownloadProgress
        });

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<T>(null, response.status, response.statusText, true);
        else {
            // // if (url.indexOf("api/Genel/IcerikGetir?icerikId=") > -1) {
            // //     if ('caches' in window) {
            // //         // Opening given cache and putting our data into it 
            // //         caches.open('thumbnailCache').then((cache) => {
            // //             const myResponse = new Response(JSON.stringify(response.data))
            // //             cache.put(url, myResponse);
            // //         });
            // //     }
            // // }

            return new WebResponse<T>(response.data, response.status, response.statusText, true);
        }
    },
    async _getBlob(url: string, data: Record<string, string | null> | undefined, onDownloadProgress?: (e: AxiosProgressEvent) => void): Promise<WebResponse<Blob>> {
        if (data) {
            const list: string[] = [];
            for (const key of Object.keys(data)) {
                if (data[key] != null && data[key] !== "")
                    list.push(`${key}=${data[key]}`)
            }
            for (let i = 0; i < list.length; i++) {
                if (i === 0)
                    url += "?";
                else
                    url += "&";
                url += list[i];
            }
        }

        const response = await axios.get<Blob>(url, {
            onDownloadProgress: onDownloadProgress,
            responseType: "blob"

        });

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<Blob>(null, response.status, response.statusText, true);
        else {
            // // if (url.indexOf("api/Genel/IcerikGetir?icerikId=") > -1) {
            // //     if ('caches' in window) {
            // //         // Opening given cache and putting our data into it 
            // //         caches.open('thumbnailCache').then((cache) => {
            // //             const myResponse = new Response(JSON.stringify(response.data))
            // //             cache.put(url, myResponse);
            // //         });
            // //     }
            // // }

            return new WebResponse<Blob>(response.data, response.status, response.statusText, true);
        }
    },

    async _post<T>(url: string, data: any): Promise<WebResponse<T>> {
        const response = await axios.post<T>(url, JsonHelper.stringify(data));

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<T>(null, response.status, response.statusText, true);
        else
            return new WebResponse<T>(response.data, response.status, response.statusText, true);
    },

    async _patch<T>(url: string, data: any): Promise<WebResponse<T>> {
        const response = await axios.patch<T>(url, JsonHelper.stringify(data));

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<T>(null, response.status, response.statusText, true);
        else
            return new WebResponse<T>(response.data, response.status, response.statusText, true);
    },

    // async _file<T>(url: string, data: any): Promise<T| null> {
    //     const response = await axios.post<FileViewModel>(url, JsonHelper.stringify(data));

    //     const linkUrl = window.URL.createObjectURL(RequestHelper.b64toBlob(response.data.contents));
    //     const link = document.createElement('a');
    //     link.href = linkUrl;
    //     link.setAttribute('download', response.data.filename);
    //     document.body.appendChild(link);
    //     link.click();

    //     return null;
    // },

    b64toBlob(b64Data: any, sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays);
        return blob;
    }
}

export default RequestHelper;

function showAxiosError(errorLog: any, source: string) {
    if (errorLog.Message)
        AlertHelper.ShowMessage("Dikkat", errorLog.Message);
    else {
        if (errorLog.errors != undefined) {
            const keys = Object.keys(errorLog.errors);
            const message = keys.select(e => `${e}: ${errorLog.errors[e]}`).join("\n");
            AlertHelper.ShowMessage("Dikkat", message);
        }
        else if (errorLog.errorId == null || errorLog.errorId === "")
            AlertHelper.ShowMessage("Dikkat", errorLog.errorMessage);
        else
            AlertHelper.ShowMessage("Sunucu Hatası", errorLog.errorMessage + " - " + errorLog.errorId);
    }
}
