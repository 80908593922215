import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import Columns from "../../../library/components/layout/Columns";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import { IconButton, Tooltip } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import appColors from "../../../app/appColors";
import { CSSProperties } from "react";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { DuyuruModel } from "../../../models/businessModels/DuyuruModel";
import { HesapDuyuruModel } from "../../../models/businessModels/HesapDuyuruModel";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";


interface IGuncelDuyuruDisplayerProps {
    genelDurumHelper: GenelDurumHelper,
}

function GuncelDuyuruDisplayer(props: IGuncelDuyuruDisplayerProps) {
    const { genelDurumHelper } = props;

    const aktifKoro = genelDurumHelper.SeciliKoroModel;

    if (!aktifKoro.koroAyarlari.duyuruArabirimiKullanilsin)
        return null;

    const duyuruYetki = genelDurumHelper.HasYetkiAny([EnmYetki["Duyuru Yönetim"], EnmYetki["Duyuru İzleme"]]);
    const yukumluDuyuruIdler = !aktifKoro.koroAyarlari.duyuruArabirimiKullanilsin ? [] : BusinessHelper.Duyuru.KisiyeUygunDuyurulariGetir(aktifKoro.tumDuyurular, genelDurumHelper.SeciliHesapBilgiModel, false).select(e => e.id);
    const gosterilecekDuyurular = !aktifKoro.koroAyarlari.duyuruArabirimiKullanilsin ? [] : BusinessHelper.Duyuru.KisiyeUygunDuyurulariGetir(aktifKoro.tumDuyurular, genelDurumHelper.SeciliHesapBilgiModel, duyuruYetki);

    async function duyuruClick(duyuruId: string) {
        const webResponse = await props.genelDurumHelper.requestHelper.Korist.DuyuruOkunduIsaretle(duyuruId, "Duyuru okuma durumu güncelleniyor");
        if (!webResponse.successfull)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const duyuruList = seciliKoro.hesapDuyuruModelList;
                const foundItem = duyuruList.singleOrDefault(e => e.duyuruModelId === duyuruId && e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);

                if (foundItem)
                    foundItem.okundu = true;
                else {
                    const newItem = new HesapDuyuruModel();
                    newItem.duyuruModelId = duyuruId;
                    newItem.hesapModelId = genelDurumHelper.SeciliHesapBilgiModel.id;
                    newItem.okundu = true;
                    duyuruList.push(newItem);
                }
            });
    }

    function renderDuyuruList(duyuruList: DuyuruModel[]): JSX.Element[] | null {
        if (duyuruList.length === 0)
            return null;

        return duyuruList.map((item) => {
            const hedefKitle = BusinessHelper.Genel.HedefKitleTextGetir(item.kadroIdList, item.sesGrupIdList, aktifKoro.tumKadrolar, aktifKoro.tumSesGruplari);

            const hedefKitleMi = yukumluDuyuruIdler.contains(item.id);
            const hedefKitleStyle: CSSProperties = hedefKitleMi ? { color: appColors.TEXT_SECONDARY } : {};

            const okunduModel = aktifKoro.hesapDuyuruModelList.singleOrDefault(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.duyuruModelId === item.id);

            // hedef kitleyse ve okumamışsa, okumak zorunda
            const okumali = hedefKitleMi && (okunduModel == null || okunduModel.okundu === false);
            const badeContent = !okumali ? null : <Tooltip title="Okundu olarak işaretle">
                <IconButton color="success" onClick={() => duyuruClick(item.id)}>{appIcons.CrudIcons.onayla}</IconButton>
            </Tooltip>;

            return <CsmSwipableListItem key={item.id}>
                <div style={{ justifyContent: "space-around", width: "45px" }}>
                    {okumali ? appIcons.Genel.duyuruKirmizi : appIcons.Genel.duyuruPrimary}
                </div>
                <Rows>
                    <CsmText>{item.icerik}</CsmText>
                    <CsmText style={hedefKitleStyle} note>{genelDurumHelper.translate("Hedef Kitle")}: {hedefKitle}</CsmText>
                    <CsmText note>Tarih: {DateTimeToStringHelper.toMonthDayString(item.tarihBaslangic, genelDurumHelper.translate)} - {DateTimeToStringHelper.toMonthDayString(item.tarihBitis, genelDurumHelper.translate)}</CsmText>
                </Rows>
                {badeContent}
            </CsmSwipableListItem>;
        });
    }

    return <Rows flex>
        <CsmHeader pl={15} hasVeryLightBottomBorder>{genelDurumHelper.translate("Duyurular")}</CsmHeader>
        <Columns wrap>
            {renderDuyuruList(gosterilecekDuyurular) ?? <CsmSwipableListItem><CsmText note>{genelDurumHelper.translate("Güncel duyuru bulunmamaktadır")}</CsmText></CsmSwipableListItem>}
        </Columns>
    </Rows>
}


export default GuncelDuyuruDisplayer;