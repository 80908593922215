/* eslint-disable @typescript-eslint/no-unused-vars */
import { IcerikMetaViewModel } from '../models/viewModels/IcerikMetaViewModel';
import { KullaniciKoroViewModel } from '../models/viewModels/KullaniciKoroViewModel';
import { BusinessHelper } from './BusinessHelper';
import GenelDurumHelper from './GenelDurumHelper';
import { GuidHelper } from '../library/helpers/GuidHelper';
import JsonHelper from '../library/helpers/JsonHelper';
import { IRequestHelper } from './RequestHelper';
import StorageHelper from './StorageHelper';

interface IIcerikDurum {
    uzanti: string,
    boyut: number,
}

export default class IcerikHelper {

    private static IcerikCache: { [id: string]: IIcerikDurum; } = {}

    public static async IcerikGetirDirekt(guidValue: string, genelDurumHelper: GenelDurumHelper): Promise<string> {
        const metaResponse = await genelDurumHelper.requestHelper.Genel.IcerikGetir(guidValue);
        if (!metaResponse.successfull)
            return "";

        return metaResponse.data!.icerik!;
    }

    static IcerikMetalariHafizayaAl() {
        const infoDosyalari = StorageHelper.GetFiles(".info");
        const infoIdLer = infoDosyalari.select(e => e.substring(0, e.length - 5));

        // dosyası olanların info dosyaları ve içerikleri oku ve cache'e yaz
        for (const infoId of infoIdLer) {
            const dosyaContent = StorageHelper.LoadFile(infoId + ".info");
            const yeniIcerikModel = JsonHelper.parse<IIcerikDurum>(dosyaContent)!;

            // if (StorageHelper.FileExists(infoId + yeniIcerikModel.uzanti))
            //     yeniIcerikModel.indirildiMi = true;
            IcerikHelper.IcerikCache[infoId] = yeniIcerikModel;
        }
    }

    public static async DownloadIcerikMetaAsync(metaIdList: string[], requestHelper: IRequestHelper): Promise<boolean> {
        metaIdList = metaIdList.where(e => StorageHelper.FileExists(e + ".info") === false);
        if (metaIdList.length === 0)
            return true;

        const metaResponse = await requestHelper.Genel.IcerikMetaListGetir(metaIdList, "İçerik metaları getiriliyor");
        if (!metaResponse.successfull)
            return false;

        metaResponse.data!.forEach(e => {
            const meta: IIcerikDurum = {
                uzanti: e.uzanti,
                //aciklama: e.aciklama,
                boyut: e.boyut,
            }

            IcerikHelper.IcerikCache[e.id] = meta;

            StorageHelper.SaveFile(e.id + ".info", JsonHelper.stringify(meta));
        });

        return true;
    }

    public static async IcerikSil(id: string): Promise<void> {
        if (GuidHelper.IsEmpty(id))
            return;

        await StorageHelper.DeleteFile(id + IcerikHelper.IcerikCache[id].uzanti);
        await StorageHelper.DeleteFile(id + ".info");

        delete IcerikHelper.IcerikCache[id];
    }

    public static async AddNewIcerik(guidValue: string, uzanti: string, icerik: string) { // aciklama: string
        const sadeModel: IIcerikDurum = {
            uzanti: uzanti,
            //aciklama: aciklama,
            boyut: icerik.length,
            //indirildiMi: true,
        }

        const stringified = JsonHelper.stringify(sadeModel);
        await StorageHelper.SaveFile(guidValue + ".info", stringified);
        await StorageHelper.SaveFileBase64(guidValue + uzanti, icerik);
        IcerikHelper.IcerikCache[guidValue] = sadeModel;
    }

    // Private Methods

    // private static async ErisimTarihGuncelle(guidValue: string): Promise<any> {
    //     let filename = guidValue + ".info";
    //     let dosyaContent = await StorageHelper.LoadFile(filename);
    //     let yeniIcerikModel = JsonHelper.parse<IIcerikDurum>(dosyaContent)!;

    //     yeniIcerikModel.erisimTarih = new Date();
    //     await StorageHelper.SaveFile(filename, JsonHelper.stringify(yeniIcerikModel));
    // }

    public static IcerikDurumGetir(guidValue: string): IIcerikDurum {
        return IcerikHelper.IcerikCache[guidValue];
    }

    public static IcerikMetaGetir(guidValue: string): IcerikMetaViewModel {
        const meta = IcerikHelper.IcerikCache[guidValue];
        const result: IcerikMetaViewModel = {
            //aciklama: meta.aciklama,
            boyut: meta.boyut,
            id: guidValue,
            uzanti: meta.uzanti,
        }

        return result;
    }

    // Boyutsallar

    public static async TumDosyalarBoyutGetir(korolar: KullaniciKoroViewModel[]) {
        const infos = await StorageHelper.GetAllFileInfos();

        const result = {
            kullanilanToplamHafiza: 0,
            gereksizIcerik: 0,
            sonBirAyErisilmeyenIcerik: 0,
            sesKayitIcerik: 0
        };

        const kullanilanIcerikIdLer = BusinessHelper.Icerik.KullanilanIcerikIdleriGetir(korolar);
        const kullanilanSesKayitIcerikIdLer = BusinessHelper.Icerik.SesKayitIcerikleriGetir(korolar);

        for (const item of infos) {
            const filename = StorageHelper.GetFileNameWithoutExtesion(item.name);

            if (GuidHelper.IsValidGuid(filename) === false)
                continue;

            result.kullanilanToplamHafiza += Number(item.size);

            if (kullanilanIcerikIdLer.contains(filename) === false)
                result.gereksizIcerik += Number(item.size);
            if (kullanilanSesKayitIcerikIdLer.contains(filename) === false)
                result.sesKayitIcerik += Number(item.size);
            // if (IcerikHelper.IcerikDurumGetir(filename).erisimTarih < DateTimeHelper.addMinutes(new Date(), -30))
            //     result.sonBirAyErisilmeyenIcerik += Number(item.size);
        }

        return result;
    }

    public static ConvertNumberToSizeString(value: number): string {
        if (value < 1024)
            return `${Math.floor(value).toLocaleString('en-US', { maximumFractionDigits: 0 })} bytes`;
        else
            return `${(value / 1024).toLocaleString('en-US', { maximumFractionDigits: 0 })} Kb`;
    }

    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    public static ResizeDataURL(baseUri: string, wantedWidth: number) {
        const response = new Promise<string>((resolve, reject) => {
            const img = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const ratio = img.height / img.width;
                const wantedHeight = Math.floor(wantedWidth * ratio);
                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx!.drawImage(img, 0, 0, wantedWidth, wantedHeight);

                const dataURI = canvas.toDataURL();
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = baseUri;
        })
        // We create an image to receive the Data URI

        return response;
    }
}