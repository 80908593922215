// AutoGenerated
export class DuyuruModel {
    tarihBaslangic!: Date;
    tarihBitis!: Date;
    icerik!: string;
    kadroIdList!: string[];
    sesGrupIdList!: string[];
    sezonModelId!: string;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}