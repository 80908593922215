import { CSSProperties } from "react";

export interface IBaseKorocanThumbnailProps {
    size: "small" | "normal" | "large" | "xlarge",
    square?: boolean,
    style?: CSSProperties,
}

interface IKorocanThumbnailProps extends IBaseKorocanThumbnailProps {
    title: string,
    icerik: string,
    icerikType: "Url" | "Icerik64Url"| "Icerik64"
}

function KorocanThumbnail(props: IKorocanThumbnailProps) {
    const borderRadius = props.square ? 0 : 30;
    const size = getSizeInPixels(props.size);

    const actualSrc = (props.icerikType === "Url" || props.icerikType === "Icerik64Url" ) ? props.icerik! : ("data:image/png;base64," + props.icerik);

    return <img
        alt={props.title}
        title={props.title}
        src={actualSrc}
        style={{
            ...props.style,
            objectPosition: "center",
            objectFit: "cover",
            borderRadius: borderRadius,
            height: size,
            width: size,
            minHeight: size,
            minWidth: size,
        }} />
}

export default KorocanThumbnail;

function getSizeInPixels(size?: "small" | "normal" | "large" | "xlarge") {
    switch (size) {
        case "small": return 36;
        case "normal":
        case undefined: return 58;
        case "large": return 80;
        case "xlarge": return 150;
    }
}