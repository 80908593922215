/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SxProps, Theme } from "@mui/material";
import { CSSProperties, useState } from "react";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import { EnmSesGrupTip } from "../../models/enums/EnmSesGrupTip";
import Rows from "../../library/components/layout/Rows";
import Columns from "../../library/components/layout/Columns";
import { CsmText } from "../../library/components/mui/CsmText";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { appIcons } from "../../app/appIcons";
import StringHelper from "../../library/helpers/StringHelper";

export interface IVatanSesGrupSelectFieldProps {
    id?: string,
    value: string | null,
    label: string,
    tumSesGruplari: SesGrupModel[],
    onChange: (e: any) => void,
    error?: boolean,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
    showClear?: boolean,
    isSesGrupDisabled?: (model: SesGrupModel) => boolean
}

export default function VatanSesGrupSelectField(props: IVatanSesGrupSelectFieldProps) {
    const { showClear, id, value, label, tumSesGruplari, onChange, error, fontSize, sx, disabled, isSesGrupDisabled, style } = props;
    const fontSizeToUse = fontSize ?? 14;
    const [open, setOpen] = useState(false);

    const list = (tumSesGruplari ?? []).orderBy(e => e.sira);
    const tipList = list
        .select(e => e.sesGrupTip)
        .distinct()
        .orderBy(BusinessHelper.SesGrubu.SesGrupTipSortFunc);

    const chipClicked = (newId: string) => {
        if (value !== newId)
            onChange(newId);
        handleClose();
    }

    const getChipMenuItem = (sesGrup: SesGrupModel, isSelected: boolean): React.ReactNode => {
        return <Chip
            key={sesGrup.id}
            disabled={isSesGrupDisabled ? isSesGrupDisabled(sesGrup) : false}
            label={sesGrup.isim}
            onClick={() => { chipClicked(sesGrup.id) }}
            color={isSelected ? "primary" : undefined}
            style={{ margin: "5px", cursor: "pointer", display: "flex" }}
        />;
        // return <MenuItem style={{ display: "flex" }} key={sesGrup.id} value={sesGrup.id}>
        //     <Chip style={{ display: "flex"}} key={sesGrup.id} label={sesGrup.isim} />
        // </MenuItem>
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const endAdorment = !showClear ? null :
        <InputAdornment sx={{ marginRight: "10px" }} position="end">
            <IconButton onClick={() => onChange(null)}>
                {appIcons.Genel.temizle}
            </IconButton>
        </InputAdornment>

    return (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
            <Select
                defaultValue=""
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                native={false}
                disabled={disabled}
                sx={sx}
                inputProps={{ style: { paddingTop: "5px !important", paddingBottom: "5px !important", fontSize: fontSizeToUse } }}
                error={error}
                labelId={id}
                MenuProps={{ MenuListProps: { style: { display: "flex", flexWrap: "wrap" } } }}
                endAdornment={endAdorment}
                id={id}
                value={StringHelper.nullToEmpty(value)}
                label={label}
                renderValue={() => <CsmText>{list.singleOrDefault(e => e.id === value)?.isim}</CsmText>}
                onChange={(e) => { onChange(e.target.value); }}>
                {list.map(e => <MenuItem key={e.id} value={e.id} style={{ display: "none" }} />)}
                <Rows flex>
                    {tipList.map(tip => (
                        <Rows key={tip} mb={10}>
                            <CsmText style={{ marginLeft: "10px" }} note>{EnmSesGrupTip[tip]}</CsmText>
                            <MenuItem style={{ padding: "0px" }} divider></MenuItem>
                            <Columns wrap>
                                {list.where(e => e.sesGrupTip === tip).map(e => getChipMenuItem(e, e.id === value))}
                            </Columns>
                        </Rows>
                    ))}
                </Rows>
            </Select>
        </FormControl>
    );
}
