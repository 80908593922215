// AutoGenerated
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class WithContentRequestModel<T> {
    constructor(model: T, icerikList: IcerikEklemeRequestModel[]) {
        this.model = model;
        this.icerikList = icerikList;
    }

    model: T;
    icerikList: IcerikEklemeRequestModel[];
}