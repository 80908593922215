// AutoGenerated
import { HesapAnketCevapModel } from "../businessModels/HesapAnketCevapModel"

export class AnketSonucGonderRequestModel {
    constructor(anketId: string, hesapAnketCevapList: HesapAnketCevapModel[]) {
        this.anketId = anketId;
        this.hesapAnketCevapList = hesapAnketCevapList;
    }

    anketId: string;
    hesapAnketCevapList: HesapAnketCevapModel[];
}