import CsmGenericListSelectField, { ICsmGenericListSelectFieldProps } from './CsmGenericListSelectField';

type ThreeWayValueType = 1 | 2 | 3;

export interface ICsmThreeWaySelectFieldProps extends Omit<ICsmGenericListSelectFieldProps<ThreeWayValueType, ThreeWayValueType>,
    "value" | "list" | "valueGetter" | "labelGetter" | "onChange" | "isAutoComplete"> {
    value: boolean | undefined
    onChange: (e: boolean | undefined) => void,
    trueText: string,
    falseText: string,
    emptyText: string,
}

export default function CsmThreeWaySelectField(props: ICsmThreeWaySelectFieldProps) {
    const { value, onChange, trueText, falseText, emptyText, ...rest } = props;

    const list: ThreeWayValueType[] = [1, 2, 3];

    const convertBoolToTWT = (value: boolean | undefined): ThreeWayValueType => {
        return value === undefined ? 1 : value === true ? 2 : 3;
    }

    const convertTWTToBool = (value: ThreeWayValueType | null): boolean | undefined => {
        if (value === null) return undefined;
        else
            return value === 1 ? undefined : value === 2 ? true : false;
    }

    const convertTWTToLabel = (value: ThreeWayValueType | null): string => {
        return (value === null || value === 1) ? emptyText : value === 2 ? trueText : falseText;
    }

    return <CsmGenericListSelectField
        value={convertBoolToTWT(value)}
        list={list}
        labelGetter={e => convertTWTToLabel(e)}
        valueGetter={e => e}
        onChange={e => onChange(convertTWTToBool(e))}
        {...rest}
    />;
}
