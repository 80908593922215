export const GuidHelper = {

    IsValidGuid: (testValue: string) => {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(testValue);
    },

    NewGuid: () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                // eslint-disable-next-line no-mixed-operators
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    IsEmpty: (guidValue: string | null): boolean => {
        if (guidValue && guidValue !== undefined && guidValue !== GuidHelper.Empty)
            return false;
        else
            return true;
    },

    Empty: "00000000-0000-0000-0000-000000000000",
}