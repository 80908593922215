import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import Columns from "../../../library/components/layout/Columns";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import { appIcons } from "../../../app/appIcons";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import appColors from "../../../app/appColors";
import { CSSProperties } from "react";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { Badge, Button } from "@mui/material";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";
import Cell from "../../../library/components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";


interface IGuncelAnketDisplayerProps {
    genelDurumHelper: GenelDurumHelper,
}

function GuncelAnketDisplayer(props: IGuncelAnketDisplayerProps) {
    const { genelDurumHelper } = props;

    const isSmallScreen = useScreenWidth() === "Max600"

    const aktifKoro = genelDurumHelper.SeciliKoroModel;
    const anketArabirimiKullanilsin = aktifKoro.koroAyarlari.anketArabirimiKullanilsin;

    if (!anketArabirimiKullanilsin)
        return null;

    const anketYetki = genelDurumHelper.HasYetkiAny([EnmYetki["Anket Yönetim"], EnmYetki["Anket İzleme"]]);
    const yukumluAnketIdler = BusinessHelper.Anket.KisiyeUygunAnketleriGetir(aktifKoro.tumAnketler, genelDurumHelper.SeciliHesapBilgiModel, false).select(e => e.id);
    const gosterilecekAnketler = BusinessHelper.Anket.KisiyeUygunAnketleriGetir(aktifKoro.tumAnketler, genelDurumHelper.SeciliHesapBilgiModel, anketYetki);

    if (gosterilecekAnketler.length === 0)
        return null;

    const anketlerIcerik = gosterilecekAnketler.map(anket => {
        const hedefKitle = BusinessHelper.Genel.HedefKitleTextGetir(anket.kadroIdList, anket.sesGrupIdList, aktifKoro.tumKadrolar, aktifKoro.tumSesGruplari);

        const hedefKitleMi = yukumluAnketIdler.contains(anket.id);
        const hedefKitleStyle: CSSProperties = hedefKitleMi ? { color: appColors.TEXT_SECONDARY } : {};

        const anketeVerilenCevaplar = aktifKoro.hesapAnketCevapModelList.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.anketModelId === anket.id);

        // hedef kitleyse ve okumamışsa, doldurmak zorunda
        const doldurmali = hedefKitleMi && (anketeVerilenCevaplar.length === 0);

        const onAnketPress = async () => {
            if (!hedefKitleMi) {
                AlertHelper.ShowMessage("Dikkat", "Bu anketin hedef kitlesinde olmadığınız için cevaplayamazsınız");
                return;
            }
            if (doldurmali == true)
                genelDurumHelper.navigate(mainScreenLocations.anketYanitla_func(anket.id));
            else {
                if (await AlertHelper.Confirm("Dikkat", "Bu anketi daha önce doldurdunuz. Tekrar doldurmak mı istiyorsunuz?") == true)
                    genelDurumHelper.navigate(mainScreenLocations.anketYanitla_func(anket.id));
            }
        };

        return <Columns key={anket.id} hasLightRightBorder pr={20} mt={10}>
            <Badge style={{ flex: 1}} color={"error"} badgeContent={doldurmali ? "!" : null}>
                <Columns style={{ alignItems: "center" }} pl={15} mr={10} gap={10}>
                    <Rows flex>
                        <CsmText>{anket.baslik}</CsmText>
                        <CsmText note>Süre: {DateTimeToStringHelper.toMonthDayAndDayOfWeekString(anket.tarihBaslangic)} - {DateTimeToStringHelper.toMonthDayAndDayOfWeekString(anket.tarihBitis)}</CsmText>
                        <CsmText note style={{ ...hedefKitleStyle }}>Hedef Kitle: {hedefKitle}</CsmText>
                    </Rows>
                    <Cell>
                        <Button variant="contained" color={doldurmali ? "error" : "info"} onClick={() => onAnketPress()} style={{ width: 130, height: 55}} >
                            {appIcons.Genel.anketBuyuk}
                            {genelDurumHelper.translate("Anketi Doldur")}
                        </Button>
                    </Cell>
                </Columns>
            </Badge>
        </Columns>
    });

    return <Rows flex>
        <CsmHeader pl={15} hasVeryLightBottomBorder>{genelDurumHelper.translate("Anketler")}</CsmHeader>
        <Columns wrap>
            {anketlerIcerik}
        </Columns>
    </Rows>
}


export default GuncelAnketDisplayer;