import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import SesGrupPopupEditor from "./SesGrupPopupEditor";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import appColors from "../../../app/appColors";
import SesGrupSiraPopupEditor from "./SesGrupSiraPopupEditor";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function SesGrupYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SesGrupYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function SesGrupYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const aktifKoro = genelDurumHelper.SeciliKoroModel;

  const sesGrupRowGetir = (sesGrupList: SesGrupModel[]): JSX.Element[] => {
    return sesGrupList.map((item) => {
      const koristCount = aktifKoro.hesapBilgiList.count(e => e.sesGrupIdList.contains(item.id));
      let altBilgi = `${koristCount} korist`;

      const calismaCount = aktifKoro.tumCalismalar.count(e => e.sesGrupIdList.contains(item.id));
      const duyuruCount = !aktifKoro.koroAyarlari.duyuruArabirimiKullanilsin ? 0 : aktifKoro.tumDuyurular.count(e => e.sesGrupIdList.contains(item.id));
      const anketCount = !aktifKoro.koroAyarlari.anketArabirimiKullanilsin ? 0 : aktifKoro.tumAnketler.count(e => e.sesGrupIdList.contains(item.id));

      if (calismaCount > 0)
        altBilgi = `${altBilgi}, ${calismaCount} çalışma`;

      if (duyuruCount > 0)
        altBilgi = `${altBilgi}, ${duyuruCount} duyuru`;

      if (anketCount > 0)
        altBilgi = `${altBilgi}, ${anketCount} anket`;

      const silinebilir = true; //item.ontanimli === false;
      const guncellenebilir = true; //item.ontanimli === false;
      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => sesGrupSil(item, silinebilir) });
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => sesGrupGuncelle(item, guncellenebilir) });

      return (
        <CsmSwipableListItem leftButtons={rightButtons} key={item.id} style={{ width: "400px" }}>
          <Rows>
            <CsmText style={{ alignSelf: "flex-start" }}>{item.isim}</CsmText>
            <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi}</CsmText>
          </Rows>
        </CsmSwipableListItem>
      );
    });
  }

  const sesGrupSiraDuzenle = async () => {
    const response = await AlertHelper.ShowClosableContent<SesGrupModel[]>("Ses Grubu Ekle", (closer) => (
      <SesGrupSiraPopupEditor
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;


    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sesGruplar = seciliKoro.tumSesGruplari;
        for (let i = 0; i < response!.length; i++) {
          const found = sesGruplar.single(e => e.id === response![i].id);
          found.sira = i + 1;
        }
      });
  }

  const sesGrupEkle = async () => {
    const sesGrupModel = new SesGrupModel();
    sesGrupModel.id = GuidHelper.NewGuid();
    sesGrupModel.koroModelId = aktifKoro.koroModel.id;
    sesGrupModel.isim = "";
    sesGrupModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    sesGrupModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<SesGrupModel>("Ses Grubu Ekle", (closer) => (
      <SesGrupPopupEditor
        sesGrupModel={sesGrupModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sesGruplar = seciliKoro.tumSesGruplari;
        sesGruplar.push(response!);
      });
  }

  const sesGrupSil = async (sesGrupModel: SesGrupModel, silinebilir: boolean): Promise<void> => {
    if (!silinebilir) {
      AlertHelper.ShowMessage("Dikkat", "Ön tanımlı Ses Grupları silinemez");
      return;
    }

    if (await AlertHelper.Confirm("Dikkat", `${sesGrupModel.isim} isimli ses grubunu silme istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.SesGrup.SesGrupSil(sesGrupModel.id, "Ses grubu siliniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sesGruplar = seciliKoro.tumSesGruplari;
        sesGruplar.remove(sesGruplar.single(e => e.id === sesGrupModel.id));
      });
  }

  const sesGrupGuncelle = async (sesGrupModel: SesGrupModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<SesGrupModel>("Ses Grubu Güncelle", (closer) => (
      <SesGrupPopupEditor
        guncellenebilir={guncellenebilir}
        sesGrupModel={sesGrupModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sesGruplar = seciliKoro.tumSesGruplari;
        sesGruplar.remove(sesGruplar.single(e => e.id === response!.id));
        sesGruplar.push(response!);
      });
  }

  const tumSesGruplar = aktifKoro.tumSesGruplari.orderBy(e => e.sira);

  let ustSesGrupIcerik: JSX.Element[] | JSX.Element = <ListItem><CsmText note>Üst ses grubu bulunmamaktadır</CsmText></ListItem>;
  let anaSesGrupIcerik: JSX.Element[] | JSX.Element = <ListItem><CsmText note>Ana ses grubu bulunmamaktadır</CsmText></ListItem>;
  let altSesGrupIcerik: JSX.Element[] | JSX.Element = <ListItem><CsmText note>Alt ses grubu bulunmamaktadır</CsmText></ListItem>;
  let altDiviziSesGrupIcerik: JSX.Element[] | JSX.Element = <ListItem><CsmText note>Alt divizi ses grubu bulunmamaktadır</CsmText></ListItem>;
  let digerSesGrupIcerik: JSX.Element[] | JSX.Element = <ListItem><CsmText note>Diğer ses grubu bulunmamaktadır</CsmText></ListItem>;

  const ustSesGruplar = tumSesGruplar.where(e => e.sesGrupTip === EnmSesGrupTip["Üst Ses Grubu"]);
  const anaSesGruplar = tumSesGruplar.where(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"]);
  const altSesGruplar = tumSesGruplar.where(e => e.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"]);
  const altDiviziSesGruplar = tumSesGruplar.where(e => e.sesGrupTip === EnmSesGrupTip["Alt Divizi Ses Grubu"]);
  const digerSesGruplar = tumSesGruplar.where(e => e.sesGrupTip === EnmSesGrupTip["Diğer"]);

  if (anaSesGruplar.length > 0) {
    ustSesGrupIcerik = sesGrupRowGetir(ustSesGruplar);
  }
  if (anaSesGruplar.length > 0) {
    anaSesGrupIcerik = sesGrupRowGetir(anaSesGruplar);
  }
  if (altSesGruplar.length > 0) {
    altSesGrupIcerik = sesGrupRowGetir(altSesGruplar);
  }
  if (altDiviziSesGruplar.length > 0) {
    altDiviziSesGrupIcerik = sesGrupRowGetir(altDiviziSesGruplar);
  }
  if (digerSesGruplar.length > 0) {
    digerSesGrupIcerik = sesGrupRowGetir(digerSesGruplar);
  }

  return (
    <MainLayout
      location={mainScreenLocations.sesgrupYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "500px",
        '&:last-child td, &:last-child th': { border: 0 },
        '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
      }}>
        <ListItem>
          <Button onClick={() => sesGrupEkle()}>
            {appIcons.CrudIcons.ekle}
            <Typography>Yeni Ses Grubu Ekle</Typography>
          </Button>
          <Button onClick={() => sesGrupSiraDuzenle()}>
            {appIcons.CrudIcons.ekle}
            <Typography>Ses Gruplarının Sırasını Güncelle</Typography>
          </Button>
        </ListItem>
        <CsmSwipableListItem itemDividerText="Ana Ses Grupları" />
        {anaSesGrupIcerik}
        <CsmSwipableListItem itemDividerText="Alt Ses Grupları" />
        {altSesGrupIcerik}
        <CsmSwipableListItem itemDividerText="Alt Divizi Ses Grupları" />
        {altDiviziSesGrupIcerik}
        <CsmSwipableListItem itemDividerText="Üst Ses Grupları" />
        {ustSesGrupIcerik}
        <CsmSwipableListItem itemDividerText="Diğer Ses Grupları" />
        {digerSesGrupIcerik}

      </List>
    </MainLayout>
  );
}

export default SesGrupYonetimScreen;
