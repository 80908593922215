import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SxProps, Theme, alpha, useTheme } from "@mui/material";
import { CSSProperties } from "react";
import { StringOrNumber } from "../../helpers/StringOrNumber";
import Columns from "../layout/Columns";
import Rows from "../layout/Rows";
import { CsmText } from "./CsmText";

export interface ICsmGenericListMultiSelectFieldProps<T, U extends StringOrNumber> {
    id?: string,
    value: U[] | null,
    label: string,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    groupGetter?: (e: T) => string,
    onChange: (e: U[]) => void,
    error?: boolean,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
    small?: boolean,
    isItemInactive?: (e: T) => boolean,
    chipColorOverride?: (e: T) => string | undefined,
    isItemDisabled?: (e: U) => boolean,
    sticky?: boolean
}

export default function CsmGenericListMultiSelectField<T, U extends StringOrNumber>(props: ICsmGenericListMultiSelectFieldProps<T, U>) {
    const { small, sticky, isItemDisabled, isItemInactive, id, value, label, list, labelGetter, valueGetter, groupGetter, onChange, error, fontSize, sx, disabled, style } = props;
    const fontSizeToUse = fontSize ?? 14;

    const theme = useTheme();
    const color_TextPrimary = theme.palette.text.primary;
    const color_Black = theme.palette.common.black;
    const color_White = theme.palette.common.white;
    const color_Black023 = alpha(theme.palette.common.black, 0.23);
    const color_Black060 = alpha(theme.palette.common.black, 0.60);
    const color_ErrorMain = theme.palette.error.main;
    const color_GrayLight2 = theme.palette.grey.A400;
    const color_GrayLight3 = theme.palette.grey[300];
    const color_PrimaryLight = theme.palette.primary.light;

    const chipClicked = (newValue: T) => {
        const newIdList = value?.slice() ?? [];
        if (isSelected(newValue))
            newIdList.remove(valueGetter(newValue));
        else
            newIdList.push(valueGetter(newValue));

        onChange(newIdList);
    }

    const isSelected = (e: T) => (value?.contains(valueGetter(e)) ?? false);
    const isLight = (e: T) => isItemInactive ? isItemInactive(e) : false;

    const findColor = (e: T, forDisplay: boolean) => {
        const overrideColor = props.chipColorOverride ? props.chipColorOverride(e) : undefined;

        if (forDisplay) {
            if (overrideColor)
                return { background: alpha(overrideColor, 0.30), color: color_TextPrimary };
            else if (isLight(e))
                return { background: alpha(color_Black, 0.03), color: color_TextPrimary };
            else
                return { background: alpha(color_Black, 0.08), color: color_TextPrimary };
        }
        else {
            if (isSelected(e)) {
                if (overrideColor)
                    return { background: alpha(overrideColor, 0.30), color: color_TextPrimary };
                else if (isLight(e))
                    return { background: alpha(color_PrimaryLight, 0.5), color: color_White };
                else
                    return { background: color_PrimaryLight, color: color_White };
            }
            else {
                if (isLight(e))
                    return { background: color_GrayLight3, color: color_TextPrimary };
                else
                    return { background: color_GrayLight2, color: color_TextPrimary };
            }
        }
    }

    const getChipMenuItem = (item: T, isSelected: boolean): React.ReactNode => {
        return <Chip
            key={valueGetter(item).toString()}
            disabled={isItemDisabled ? isItemDisabled(valueGetter(item)) : undefined}
            label={labelGetter(item)}
            onClick={() => chipClicked(item)}
            color={isSelected ? "primary" : undefined}
            style={{ ...findColor(item, false), margin: "5px", cursor: "pointer", display: "flex" }}
        />;
        // return <MenuItem style={{ display: "flex" }} key={sesGrup.id} value={sesGrup.id}>
        //     <Chip style={{ display: "flex"}} key={sesGrup.id} label={sesGrup.isim} />
        // </MenuItem>
    }

    const groupList = !groupGetter ? undefined : list.select(e => groupGetter(e)).distinct();

    return sticky ?
        <FormControl disabled={disabled} fullWidth style={{ marginTop: 0, ...style }} >
            <fieldset style={{ borderRadius: "4px", borderColor: error ? color_ErrorMain : color_Black023, borderWidth: "1px" }}>
                <legend style={{ fontSize: "12px", fontWeight: 400, color: error ? color_ErrorMain : color_Black060, }}>{label}</legend>
                <Columns>
                    {list.map(item => <Chip
                        disabled={isItemDisabled ? isItemDisabled(valueGetter(item)) : undefined}
                        key={valueGetter(item).toString()}
                        label={labelGetter(item)}
                        onClick={() => chipClicked(item)}
                        // color={isSelected(e) ? (isLight(e) ? "secondary" : "primary") : undefined}
                        style={{ ...findColor(item, false), margin: "5px", cursor: "pointer", display: "flex" }}
                    />
                    )}
                </Columns>
            </fieldset>
        </FormControl>
        :
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
            <Select
                multiple
                size={small ? "small" : undefined}
                disabled={disabled}
                sx={{ overflow: "hidden", ...sx }}
                inputProps={{ style: { paddingTop: "5px !important", paddingBottom: "5px !important", fontSize: fontSizeToUse } }}
                error={error}
                MenuProps={{ MenuListProps: { style: { display: "flex", flexWrap: "wrap" } } }}
                labelId={id}
                id={id}
                value={value}
                label={label}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((_value) => (
                            <Chip key={_value} style={{ ...findColor(list.single(e => valueGetter(e) === _value), true) }} label={labelGetter(list.single(e => valueGetter(e) === _value))?.toString().trim()} />
                        ))}
                    </Box>
                )}
            // onChange={(e) => onChange((e.target.value as U[]).where(e => !!e))}
            >
                {!groupList ?
                    list.map(e => <Chip
                        disabled={isItemDisabled ? isItemDisabled(valueGetter(e)) : undefined}
                        key={valueGetter(e).toString()}
                        label={labelGetter(e)}
                        onClick={() => chipClicked(e)}
                        // color={isSelected(e) ? (isLight(e) ? "secondary" : "primary") : undefined}
                        style={{ ...findColor(e, false), margin: "5px", cursor: "pointer", display: "flex" }}
                    />
                    )
                    :
                    <Rows flex>
                        {groupList.map(group => (
                            <Rows key={group} mb={10}>
                                <CsmText style={{ marginLeft: "10px" }} note>{group}</CsmText>
                                <MenuItem style={{ padding: "0px" }} divider></MenuItem>
                                <Columns wrap>
                                    {list.where(e => groupGetter!(e) === group).map(e => getChipMenuItem(e, value?.contains(valueGetter(e)) ?? false))}
                                </Columns>
                            </Rows>
                        ))}
                    </Rows>
                }
            </Select>
        </FormControl>
}
