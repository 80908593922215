// AutoGenerated
export class SecmeBasvuruSonucModel {
    inceNota!: number | null;
    kalinNota!: number | null;
    normalMelodiPuan!: number | null;
    arizaMelodiPuan!: number | null;
    digerDetaylar!: string | null;
    sesKullanim!: string | null;
    teknikDetaylar!: string | null;
    sonucSesGrup!: string | null;
    sonuc!: boolean | null;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}