/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxisValueFormatterContext } from '@mui/x-charts/internals';
import CsmScatterChart, { ICsmScatterChartData, ICsmScatterChartProps } from './CsmScatterChart';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import DateTimeToStringHelper, { CsmDateTimeTranslateFuncType } from '../../helpers/DateTimeToStringHelper';

export interface ICsmTimeSeriesScatterChartData<TY> {
    seriesLabel: string,
    data: {
        x: Date,
        y: TY,
        id: string,
    }[]
}

export interface ICsmTimeSeriesScatterChartProps<TY> extends Omit<ICsmScatterChartProps, "xAxisValueFormatter" | "yAxisValueFormatter" | "data"> {
    xAxisValueFormatter?: (key: Date, c: AxisValueFormatterContext) => string,
    yAxisValueFormatter: (key: TY, c: AxisValueFormatterContext) => string,
    yAxisValueToNumberConverter: (value: TY) => number,
    data: ICsmTimeSeriesScatterChartData<TY>[],
    translate: CsmDateTimeTranslateFuncType,
}

export default function CsmTimeSeriesScatterChart<TY>(props: ICsmTimeSeriesScatterChartProps<TY>) {

    const { xAxisValueFormatter, yAxisValueFormatter, yAxisValueToNumberConverter, data, translate, ...rest } = props;

    const yValues = data.selectMany(e => e.data.select(t => t.y)).distinct();

    const minDate = data.selectMany(e => e.data.select(t => t.x)).min(e => e) ?? new Date(1970, 1, 1);
    const dateToNumber = (d: Date) => {
        const ayinBirindenFarki = DateTimeHelper.substractForDays(DateTimeHelper.getFirstOfMonth(d), d) / 31;
        const ayDegeri = d.getMonth() + (d.getFullYear() * 12);
        return ayDegeri + ayinBirindenFarki;
        //DateTimeHelper.substractForDays(baseDate, d) / 32
    };

    const numberToDate = (v: number) => {
        const ayToplamDegeri = Math.floor(v);
        const gunDegeri = ((v - ayToplamDegeri) * 31) + 1;

        const bulunanYil = Math.floor(ayToplamDegeri / 12);
        const bulunanAy = ayToplamDegeri - bulunanYil * 12;
        return new Date(bulunanYil, bulunanAy, gunDegeri)
    }

    const yTypeMap: { key: TY, value: number }[] = yValues.select(e => ({
        key: e,
        value: yAxisValueToNumberConverter(e)
    }));

    const dataToUse: ICsmScatterChartData[] = data.select(e => ({
        seriesLabel: e.seriesLabel,
        data: e.data.select(t => ({
            x: dateToNumber(t.x),
            y: yTypeMap.single(k => k.key === t.y).value,
            id: t.id,
        }))
    }));

    const defaultDateFormatter = (d: Date) => {
        const currentMonth = d.getMonth();
        if (currentMonth === 0 || currentMonth === minDate.getMonth())
            return `${d.getFullYear()}\n${DateTimeToStringHelper.toMonthString(d, translate)}`
        else
            return ` \n${DateTimeToStringHelper.toMonthString(d, translate)}`
    }

    const xAxisValueFormatterFinal = (v:number, c:AxisValueFormatterContext) => xAxisValueFormatter ? xAxisValueFormatter(numberToDate(v), c) : defaultDateFormatter(numberToDate(v));
    const yAxisValueFormatterFinal = (v:number, c:AxisValueFormatterContext) => {
        const item = yTypeMap.singleOrDefault(k => k.value === v)?.key ?? null
        return item ? yAxisValueFormatter(item, c) : "";
    };

    return <CsmScatterChart
        xAxisValueFormatter={xAxisValueFormatterFinal}
        yAxisValueFormatter={yAxisValueFormatterFinal}
        data={dataToUse}
        {...rest}
    />
}