// AutoGenerated
export class OtpSifreGuncellemeRequestModel {
    constructor(kullaniciAdi: string, otp: string, yeniSifre: string) {
        this.kullaniciAdi = kullaniciAdi;
        this.otp = otp;
        this.yeniSifre = yeniSifre;
    }

    kullaniciAdi: string;
    otp: string;
    yeniSifre: string;
}