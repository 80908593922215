// AutoGenerated
import { KadroModel } from "../businessModels/KadroModel"
import { HesapVeSesGrubuBilgiModel } from "../businessModels/HesapVeSesGrubuBilgiModel"

export class KadroEkleGuncelleRequestModel {
    constructor(model: KadroModel, hesapVeSesGrubuBilgiModelList: HesapVeSesGrubuBilgiModel[], calismaIdList: string[]) {
        this.model = model;
        this.hesapVeSesGrubuBilgiModelList = hesapVeSesGrubuBilgiModelList;
        this.calismaIdList = calismaIdList;
    }

    model: KadroModel;
    hesapVeSesGrubuBilgiModelList: HesapVeSesGrubuBilgiModel[];
    calismaIdList: string[];
}