// AutoGenerated
import { SarkiLinkModel } from "../businessModels/SarkiLinkModel"
import { SezonSarkiModel } from "../businessModels/SezonSarkiModel"

export class SarkiModel {
    isim!: string;
    aktif!: boolean;
    tarih!: string | null;
    sure!: number;
    sira!: number;
    olcuSayisi!: string | null;
    dil!: string | null;
    donem!: string | null;
    zorluk!: string | null;
    ekYorum!: string | null;
    eslik!: string | null;
    tempo!: string | null;
    sozYazari!: string | null;
    besteci!: string | null;
    duzenleme!: string | null;
    kisaAciklama!: string | null;
    uzunAciklama!: string | null;
    kisaIngilizceAciklama!: string | null;
    uzunIngilizceAciklama!: string | null;
    kayitGondermeSurecinde!: boolean;
    sarkiLinkModelList!: SarkiLinkModel[];
    sezonSarkiModelList!: SezonSarkiModel[];
    adaySezonModelIdList!: string[];
    tarzModelIdList!: string[];
    sesGrupIdList!: string[];
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}