// AutoGenerated
import { EnmSesGrupTip } from "../enums/EnmSesGrupTip"

export class SesGrupModel {
    isim!: string;
    sesGrupTip!: EnmSesGrupTip;
    sira!: number;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}