import { Dialog, DialogContent, DialogTitle, } from "@mui/material";

export interface IClosableCustomContentDialogProps {
  titleMessage: string;
  content: JSX.Element | null;
  open: boolean;
  onClose: (() => void) | null;
  extraFullHeight: boolean;
  enableCloseOnEscOrBackClick: boolean;
}

export default function ClosableCustomContentDialog(props: IClosableCustomContentDialogProps) {
  return props.open === true ? (
    <Dialog
      PaperProps={{ sx: props.extraFullHeight ? { height: "calc(100% - 10px) !important", maxHeight: "calc(100% - 10px) !important" } : undefined }}
      maxWidth="xl"
      open={props.open}
      onClose={(props.enableCloseOnEscOrBackClick == true && props.onClose != null) ? props.onClose : (() => { })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.titleMessage}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        {props.content}
      </DialogContent>
    </Dialog>
  ) : null;
}
