/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { Button, IconButton } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";

interface ISesGrupSiraPopupEditorProps {
    genelDurumHelper: GenelDurumHelper,
    closer: (model: SesGrupModel[] | null) => void,
}

function SesGrupSiraPopupEditor(props: ISesGrupSiraPopupEditorProps) {
    const { closer, genelDurumHelper } = props;

    const [sesGrupModelList, setSesGrupModelList] = useState<SesGrupModel[]>(JsonHelper.deepCopy(genelDurumHelper.SeciliKoroModel.tumSesGruplari.orderBy(e => e.sira)));

    const swapSesGrupModelSira = (model1: SesGrupModel, model2: SesGrupModel) => {
        const result = JsonHelper.deepCopy(sesGrupModelList);

        const item1 = result.single(e => e.id === model1.id);
        const item2 = result.single(e => e.id === model2.id);

        item1.sira = model2.sira;
        item2.sira = model1.sira;

        setSesGrupModelList(result.orderBy(e => e.sira));
    }

    const kaydet = async () => {
        const webResponse = await genelDurumHelper.requestHelper.SesGrup.TopluSiraNoGuncelle(sesGrupModelList.select(e => e.id), "Ses grubu sıra numaraları güncelleniyor");
        if (!webResponse.successfull)
            return;

        closer(sesGrupModelList);
    }

    function renderSesGrup(sesGrup: SesGrupModel, index: number): any {
        const margin = 
        sesGrup.sesGrupTip === EnmSesGrupTip["Üst Ses Grubu"] ? 0 : 
        sesGrup.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"] ? 20 : 
        sesGrup.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"] ? 40 : 
        sesGrup.sesGrupTip === EnmSesGrupTip["Alt Divizi Ses Grubu"] ? 60 : 
        sesGrup.sesGrupTip === EnmSesGrupTip["Diğer"] ? 0 : 0;

        const prev = index === 0 ? null : sesGrupModelList[index - 1];
        const next = index === sesGrupModelList.length - 1 ? null : sesGrupModelList[index + 1];

        return <Columns ml={margin}>
            <IconButton disabled={!prev} onClick={() => swapSesGrupModelSira(sesGrup, prev!)}>{appIcons.Genel.yukari}</IconButton>
            <IconButton disabled={!next} onClick={() => swapSesGrupModelSira(sesGrup, next!)}>{appIcons.Genel.asagi}</IconButton>
            <CsmText>{sesGrup.isim}</CsmText>
        </Columns>
    }

    return (
        <Rows>
            {sesGrupModelList.map((sesGrup, index) => renderSesGrup(sesGrup, index))}
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default SesGrupSiraPopupEditor;