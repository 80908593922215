/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { ChangeEvent, useRef, useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, IconButton } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import VatanSesGrupSelectField from "../../../components/vatan/VatanSesGrupSelectField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmFormSegmentedRadioButtonList from "../../../library/components/mui/CsmFormSegmentedRadioButtonList";
import EnumHelper from "../../../library/helpers/EnumHelper";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import IcerikHelper from "../../../helpers/IcerikHelper";
import Cell from "../../../library/components/layout/Cell";
import CsmGenericGroupBox from "../../../library/components/mui/CsmGenericGroupBox";
import useDragging from "../../../library/components/hooks/useDragging";
import appColors from "../../../app/appColors";
import { CsmText } from "../../../library/components/mui/CsmText";
import CsmIcerikHelper, { CsmBase64DosyaModel } from "../../../library/helpers/CsmIcerikHelper";

interface ISarkiLinkMultiUploadPopupEditorProps {
    sarkiModel: SarkiModel,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: WithContentRequestModel<SarkiLinkModel>[] | null) => void,
}

function SarkiLinkMultiUploadPopupEditor(props: ISarkiLinkMultiUploadPopupEditorProps) {
    const { sarkiModel, guncellemeMi, genelDurumHelper, closer } = props;
    const [sarkiLinkList, setSarkiLinkList] = useState<{ model: SarkiLinkModel, icerik: IcerikEklemeRequestModel }[]>([]);

    const secilebilirSesGruplariniGetir = (sarkiSesGrupIdList: string[], tumSesGruplari: SesGrupModel[], seciliSesGrupId: string): SesGrupModel[] => {
        if (sarkiSesGrupIdList.length === 0)
            return tumSesGruplari
        else
            return tumSesGruplari.where(e => sarkiSesGrupIdList.contains(e.id) || e.id === seciliSesGrupId);
    }
    const sesGrubuGoster = (e: SarkiLinkModel) => e.linkTip === EnmSarkiLinkTip["Midi Kaydı"] || e.linkTip === EnmSarkiLinkTip["Ses Kaydı"];
    const sesGrubuZorunlu = (e: SarkiLinkModel) => e.linkTip === EnmSarkiLinkTip["Midi Kaydı"] || e.linkTip === EnmSarkiLinkTip["Ses Kaydı"];

    const hataVar = sarkiLinkList.any(e => !e.model.linkTip || (sesGrubuZorunlu(e.model) && !e.model.sesGrupId));
    const possibleLinkTipValues = EnumHelper.getNamesAndValues<EnmSarkiLinkTip>(EnmSarkiLinkTip).where(e => e.value !== EnmSarkiLinkTip["Başka Koro Videosu"] && e.value !== EnmSarkiLinkTip["Konser Videosu"] && e.value !== EnmSarkiLinkTip.PDF && e.value !== EnmSarkiLinkTip["Eski PDF"]);

    /*--*/


    // const handleAddedFileList = async (fileList: KorocanBase64DosyaModel[]) => {
    //     const newItems = ConvertTkkDosyaListToSarkiDosyaList(fileList);
    //     const newList = sarkiDosyaList.slice();

    //     newList.push(...newItems);
    //     setSarkiDosyaList(newList);
    // }

    const labelRef = useRef<HTMLDivElement>(null);

    const validateFiles = (files: File | Array<File>): boolean => {
        if (!files)
            return false;

        return true;
    };

    const dragging = useDragging({
        labelRef: labelRef,
        multiple: true,
        validateFiles: validateFiles,
        onDropBase64Async: addFileListToSarkiLinkList
    });

    /*--*/

    const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        const targetFiles = event.target!.files!;
        event.preventDefault();

        const fileList: File[] = [];
        for (let i = 0; i < targetFiles.length; i++)
            fileList.push(targetFiles[i]);

        const responses = await CsmIcerikHelper.ConvertFilesToIcerikBase64(targetFiles)

        addFileListToSarkiLinkList(responses);
    }

    async function addFileListToSarkiLinkList(dosyaModelList: CsmBase64DosyaModel[]) {
        const addItems = dosyaModelList.select(response => {
            const icerikEklemeRequestModel = new IcerikEklemeRequestModel(GuidHelper.NewGuid(), response.uzanti, response.base64Icerik);

            const result = new SarkiLinkModel();
            result.id = GuidHelper.NewGuid();
            result.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
            result.olusturmaTarih = new Date();
            result.icerikId = icerikEklemeRequestModel.id;
            result.aciklama = response.isim;

            return { model: result, icerik: icerikEklemeRequestModel };
        })

        const newList = sarkiLinkList.slice();
        newList.push(...addItems);
        setSarkiLinkList(newList);
    }

    function updateLinkModel(id: string, action: (t: SarkiLinkModel) => any) {
        const result = JsonHelper.deepCopy(sarkiLinkList);

        const found = result.single(e => e.model.id === id);
        action(found.model);

        setSarkiLinkList(result);
    }

    function dosyaSil(id: string) {
        const result = sarkiLinkList.where(e => e.model.id !== id);
        setSarkiLinkList(result);
    }

    return (
        <Rows style={{ minWidth: 300, minHeight: 400 }}>
            <Button component="label">
                {appIcons.Genel.fileUpload}
                Dosya Seç
                <input
                    style={{ display: "none" }}
                    type="file"
                    hidden
                    onChange={handleUpload}
                    multiple
                    name="[licenseFile]"
                    accept={undefined}
                />
            </Button>
            {sarkiLinkList.map(sarkiLink =>
                <CsmGenericGroupBox key={sarkiLink.model.id} label={`${sarkiLink.model.aciklama!} (${IcerikHelper.ConvertNumberToSizeString(sarkiLink.icerik.base64Icerik.length)})`}
                    error={!sarkiLink.model.linkTip || (sesGrubuZorunlu(sarkiLink.model) && (!sarkiLink.model.sesGrupId || sarkiLink.model.sesGrupId === GuidHelper.Empty))}
                    style={{ marginTop: 10 }}>
                    <Columns height={60} style={{ placeItems: "center" }}>
                        <Cell>
                            <IconButton color="error" onClick={() => dosyaSil(sarkiLink.model.id)}>
                                {appIcons.CrudIcons.sil}
                            </IconButton>
                        </Cell>
                        <Cell width={300}>
                            <CsmFormSegmentedRadioButtonList
                                disabled={guncellemeMi}
                                value={sarkiLink.model.linkTip}
                                list={possibleLinkTipValues}
                                labelGetter={e => e.name}
                                valueGetter={e => e.value}
                                onChange={e => { updateLinkModel(sarkiLink.model.id, t => t.linkTip = e as any) }}
                                style={{ marginBottom: "-5px", marginTop: "-5px" }}
                            />
                        </Cell>
                        <Cell width={150}>
                            {sesGrubuGoster(sarkiLink.model) && <VatanSesGrupSelectField
                                label={genelDurumHelper.translate("Ses Grubu")}
                                error={sesGrubuZorunlu(sarkiLink.model) && (!sarkiLink.model.sesGrupId || sarkiLink.model.sesGrupId === GuidHelper.Empty)}
                                tumSesGruplari={secilebilirSesGruplariniGetir(sarkiModel.sesGrupIdList, props.genelDurumHelper.SeciliKoroModel.tumSesGruplari, GuidHelper.Empty)}
                                value={sarkiLink.model.sesGrupId}
                                onChange={(e) => { updateLinkModel(sarkiLink.model.id, t => t.sesGrupId = e) }}
                                style={{ marginBottom: "10px", marginRight: "10px" }} />}
                        </Cell>
                    </Columns>
                </CsmGenericGroupBox>
            )}
            <Rows flex>
                {sarkiLinkList.length === 0 &&
                    <Rows flex forwardRef={labelRef} style={{ borderRadius: 20, borderColor: appColors.LIGHTGRAY, borderStyle: "dotted", placeContent: "center", textAlign: "center", background: !dragging ? undefined : appColors.LIGHTGREEN }}>
                        <CsmText lightColor note>Dosyaları buraya da bırakabilirsiniz</CsmText>
                    </Rows>
                }
            </Rows>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => closer(sarkiLinkList.select(e => new WithContentRequestModel<SarkiLinkModel>(e.model, [e.icerik])))} disabled={hataVar} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );
}

export default SarkiLinkMultiUploadPopupEditor;