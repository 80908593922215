/* eslint-disable react/no-children-prop */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import KoristPopupEditor from "./HesapPopupEditor";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import Columns from "../../../library/components/layout/Columns";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { KullaniciModel } from "../../../models/businessModels/KullaniciModel";
import { mainScreenLocations } from "../../../app/mainScreens";
import Cell from "../../../library/components/layout/Cell";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import KorocanThumbnailForHesap from "../../../components/business/KorocanThumbnailForHesap";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function HesapYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <HesapYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function HesapYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  const koristRowGetir = (koristList: HesapBilgiVeKullaniciModel[]): JSX.Element[] | null => {
    if (koristList.length === 0)
      return null;

    return koristList.map((item) => {
      const sesGrupIsim = BusinessHelper.SesGrubu.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumSesGruplari, item.sesGrupIdList);
      const kadroIsim = BusinessHelper.Kadro.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumKadrolar, item.kadroVeSesGrubuBilgiModelList.select(e => e.kadroModelId), "AsPlus");
      const rolIsim = BusinessHelper.Rol.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumRoller, item.rolIdList);

      const altBilgi1 = item.superAdmin ? "System Admin" : <><span style={{ fontWeight: "bold" }}>Rol: </span><span>{rolIsim}</span></>;
      const altBilgi2 = !kadroIsim ? "" : <><span style={{ fontWeight: "bold" }}>Kadrolar: </span><span>{kadroIsim}</span></>;
      const altBilgi3 = !sesGrupIsim ? "" : <><span style={{ fontWeight: "bold" }}>Ses Grupları: </span><span>{sesGrupIsim}</span></>;

      const guncellenebilir = genelDurumHelper.SeciliHesapBilgiModel.superAdmin || (genelDurumHelper.SeciliHesapBilgiModel.id !== item.id && item.superAdmin === false);
      const silinebilir = genelDurumHelper.SeciliHesapBilgiModel.id !== item.id && item.superAdmin === false;
      const aktifYapilabilir = item.aktif === false && genelDurumHelper.SeciliHesapBilgiModel.id !== item.id && item.superAdmin === false;
      const pasifYapilabilir = item.aktif === true && genelDurumHelper.SeciliHesapBilgiModel.id !== item.id && item.superAdmin === false;

      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => koristGuncelle(item, guncellenebilir) });
      rightButtons.push({ icon: appIcons.CrudIcons.sil, disabled: !silinebilir, color: "error", onClick: () => koristSil(item) });

      if (aktifYapilabilir)
        rightButtons.push({ icon: appIcons.CrudIcons.onayla, color: "success", onClick: () => koristAktifle(item) });
      else if (pasifYapilabilir)
        rightButtons.push({ icon: appIcons.CrudIcons.onayKaldir, color: "secondary", onClick: () => koristPasifle(item) });
      else
        rightButtons.push({ icon: appIcons.CrudIcons.onayla, disabled: true, color: "success", onClick: () => koristAktifle(item) });

      const adSoyad = `${item.kullaniciModel.ad} ${item.kullaniciModel.soyad}`;

      return (
        <CsmSwipableListItem hasLightBottomBorder leftButtons={rightButtons} key={item.id} style={{ width: "490px" }}>
          <Columns flex>
            <Cell style={{ alignSelf: "center" }}>
              <KorocanThumbnailForHesap hesap={item} genelDurumHelper={genelDurumHelper} size="normal" />
            </Cell>
            <Rows ml={10} mb={10}>
              <CsmText style={{ alignSelf: "flex-start" }}>{adSoyad}</CsmText>
              <CsmText style={{ alignSelf: "flex-start", lineHeight: 1.2 }} note>{altBilgi1}</CsmText>
              <CsmText style={{ alignSelf: "flex-start", lineHeight: 1.2 }} note>{altBilgi2}</CsmText>
              <CsmText style={{ alignSelf: "flex-start", lineHeight: 1.2 }} note>{altBilgi3}</CsmText>
            </Rows>
          </Columns>
        </CsmSwipableListItem>
      );
    });
  }

  const koristEkle = async () => {
    const koristModel = new HesapBilgiVeKullaniciModel();
    koristModel.id = GuidHelper.NewGuid();
    koristModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;

    koristModel.kadroVeSesGrubuBilgiModelList = [];
    koristModel.rolIdList = [];
    koristModel.sesGrupIdList = [];
    koristModel.araVerdi = false;
    koristModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    koristModel.olusturmaTarih = new Date();

    koristModel.kullaniciModel = new KullaniciModel();
    koristModel.kullaniciModel.ad = "";
    koristModel.kullaniciModel.soyad = "";

    koristModel.kullaniciModel.dogumTarihi = new Date(1970, 1, 1);
    koristModel.kullaniciModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    koristModel.kullaniciModel.olusturmaTarih = koristModel.olusturmaTarih;

    const response = await AlertHelper.ShowClosableContent<HesapBilgiVeKullaniciModel>("Korist Ekle", (closer) => (
      <KoristPopupEditor
        hesapModel={koristModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const koristler = seciliKoro.hesapBilgiList;
        koristler.push(response!);
      });
  }

  const koristSil = async (koristModel: HesapBilgiVeKullaniciModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", "Seçili hesabı silmek istediğinizden emin misiniz?") !== true)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Hesap.HesapSil(koristModel.id, "Hesap siliniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const koristler = seciliKoro.hesapBilgiList
        koristler.remove(koristler.single(e => e.id === koristModel.id));
      });
  }

  const koristGuncelle = async (koristModel: HesapBilgiVeKullaniciModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<HesapBilgiVeKullaniciModel>("Korist Güncelle", (closer) => (
      <KoristPopupEditor
        guncellenebilir={guncellenebilir}
        hesapModel={koristModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const koristler = seciliKoro.hesapBilgiList
        koristler.remove(koristler.single(e => e.id === response!.id));
        koristler.push(response!);
      });
  }

  const koristAktifle = async (koristModel: HesapBilgiVeKullaniciModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", "Seçili hesabı aktif hale getirmek istediğinizden emin misiniz?") !== true)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Hesap.HesapAktifYap(koristModel.id, "Hesap aktife alınıyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const koristler = seciliKoro.hesapBilgiList;
        koristler.single(e => e.id === koristModel.id).aktif = true;
      });
  }

  const koristPasifle = async (koristModel: HesapBilgiVeKullaniciModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", "Seçili hesabı pasif hale getirmek istediğinizden emin misiniz?") !== true)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Hesap.HesapPasifYap(koristModel.id, "Hesap pasife alınıyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const koristler = seciliKoro.hesapBilgiList;
        koristler.single(e => e.id === koristModel.id).aktif = false;
      });
  }

  let tumKoristler = genelDurumHelper.SeciliKoroModel.hesapBilgiList.orderByTurkish(e => e.kullaniciModel.ad);

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase().includes(searchFilter.toLocaleUpperCase())
  }

  const searchRolIdList = genelDurumHelper.SeciliKoroModel.tumRoller.where(e => includesSearchText(e.isim)).select(e => e.id);
  const searchKadroIdList = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => includesSearchText(e.isim)).select(e => e.id);
  const searchSesGrupIdList = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => includesSearchText(e.isim)).select(e => e.id);

  if (searchFilter.length > 2)
    tumKoristler = tumKoristler
      .where(e => includesSearchText(e.kullaniciModel.ad) ||
        includesSearchText(e.kullaniciModel.soyad) ||
        includesSearchText(e.kullaniciModel.kullaniciAdi) ||
        searchRolIdList.intersection(e.rolIdList).length > 0 ||
        searchKadroIdList.any(t => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, t)) ||
        searchSesGrupIdList.intersection(e.sesGrupIdList).length > 0);

  const aktifKoristler = tumKoristler.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e) && !e.araVerdi);
  const araVerenKoristler = tumKoristler.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e) && e.araVerdi);
  const aktifKullanicilar = tumKoristler.where(e => e.aktif && e.sesGrupIdList.length === 0);
  const pasifKoristler = tumKoristler.where(e => !e.aktif);

  const aktifKoristlerIcerik = koristRowGetir(aktifKoristler);
  const araVerenKoristlerIcerik = koristRowGetir(araVerenKoristler);
  const aktifKullanicilarIcerik = koristRowGetir(aktifKullanicilar);
  const pasifKullanicilarIcerik = koristRowGetir(pasifKoristler);

  return (
    <MainLayout
      location={mainScreenLocations.hesapYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns style={{ margin: "10px" }}>
          <Cell flex>
            <CsmTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell>
            <Button onClick={() => koristEkle()}>
              {appIcons.CrudIcons.ekle}
              <Typography>Yeni Korist Ekle</Typography>
            </Button>
          </Cell>
        </Columns>
        <CsmAccordionSimple
          title="Güncel Koristler"
          children={aktifKoristlerIcerik ?? <CsmText note>Güncel Korist bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Ara Veren Koristler"
          children={araVerenKoristlerIcerik ?? <CsmText note>Ara Veren Korist bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Diğer Kullanıcılar"
          children={aktifKullanicilarIcerik ?? <CsmText note>Diğer Kullanıcı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Hesabı kapanmış Kullanıcılar"
          children={() => (pasifKullanicilarIcerik ?? <CsmText note>Hesabı kapanmış kullanıcı bulunmamaktadır</CsmText>)}
        />
      </Rows>
    </MainLayout>
  );
}

export default HesapYonetimScreen;

