// AutoGenerated
export class HataAraRequestModel {
    constructor(sonDakika: number | null, isimIceren: string | null, guidIceren: string | null, hataIceren: string | null) {
        this.sonDakika = sonDakika;
        this.isimIceren = isimIceren;
        this.guidIceren = guidIceren;
        this.hataIceren = hataIceren;
    }

    sonDakika: number | null;
    isimIceren: string | null;
    guidIceren: string | null;
    hataIceren: string | null;
}