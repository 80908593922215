import { alpha, FormControl, useTheme } from "@mui/material";
import { CSSProperties, RefObject } from "react";

export type ICsmGenericGroupBoxProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
{
    label: string,
    error?: boolean,
    warning?: boolean,
    disabled?: boolean,
    forwardRef?: RefObject<HTMLDivElement>
    fieldsetStyle?: CSSProperties
}

export default function CsmGenericGroupBox(props: ICsmGenericGroupBoxProps) {
    const { label, error, warning, disabled, forwardRef, style, fieldsetStyle, children, ...rest } = props;

    const theme = useTheme();
    const color_Black023 = alpha(theme.palette.common.black, 0.23);
    const color_Black060 = alpha(theme.palette.common.black, 0.60);
    const color_ErrorMain = theme.palette.error.main;
    const color_WarningLight = theme.palette.warning.light;
    
    return <div ref={forwardRef} style={{ minHeight: 0, ...style }} {...rest}>
        <FormControl disabled={disabled} fullWidth style={{ minHeight: 0, height: "100%" }} >
            <fieldset style={{ borderRadius: "4px", borderColor: error ? color_ErrorMain : warning ? color_WarningLight : color_Black023, borderWidth: (error || warning) ? "2px" : "1px", flex: 1, height: "100%", ...fieldsetStyle }}>
                {label === "" ? null : <legend style={{ fontSize: "12px", fontWeight: 400, color: error ? color_ErrorMain : warning ? color_WarningLight : color_Black060 }}>{label}</legend>}
                {children}
            </fieldset>
        </FormControl>
    </div>
}