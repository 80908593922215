import Columns, { ColumnsCSSProperties } from "../../library/components/layout/Columns";
import { CsmText } from "../../library/components/mui/CsmText";
import { AnketModel } from "../../models/businessModels/AnketModel";
import appColors from "../../app/appColors";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import DateTimeToStringHelper from "../../library/helpers/DateTimeToStringHelper";

interface IAnketAvatarListControlProps {
    anketler: AnketModel[],
    style?: ColumnsCSSProperties,
    genelDurumHelper: GenelDurumHelper,
}

function AnketAvatarListControl(props: IAnketAvatarListControlProps) {
    const getInnerContent = (anket: AnketModel) => {
        return (
            <div key={anket.id} style={{ borderRadius: 5, width: 165, padding: 10, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <CsmText style={{ textAlign: "center", fontSize: 13 }}>{anket.baslik}</CsmText>
                <CsmText style={{ textAlign: "center" }} note small>{DateTimeToStringHelper.toMonthDayAndYearAndDayOfWeekString(anket.tarihBaslangic, props.genelDurumHelper.translate)}</CsmText>
            </div>
        );
    }

    return (
        <Columns wrap style={props.style}>
            {props.anketler.map(item => getInnerContent(item))}
        </Columns>
    )
}

export default AnketAvatarListControl;