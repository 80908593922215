// AutoGenerated
export class HesapKuartetModel {
    sarkiModelId!: string;
    hesapModelId!: string;
    tarih!: Date;
    kuartetBasarili!: boolean;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}