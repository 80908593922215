/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, Link, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import AnketPopupEditor from "./AnketPopupEditor";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { AnketModel } from "../../../models/businessModels/AnketModel";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import Columns from "../../../library/components/layout/Columns";
import Cell from "../../../library/components/layout/Cell";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { AnketAcKapatRequestModel } from "../../../models/requestModels/AnketAcKapatRequestModel";
import AnketSoruListPopupEditor from "./AnketSoruListPopupEditor";
import appColors from "../../../app/appColors";

function AnketYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <AnketYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function AnketYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  const yonetimYetkisi = genelDurumHelper.HasYetkiAll([EnmYetki["Anket Yönetim"]]);
  const aktifKoro = genelDurumHelper.SeciliKoroModel;

  function showKullaniciList(hesapIdList: string[]): void {
    AlertHelper.ShowMessage(`Anketi Doldurmayanlar (${hesapIdList.length} kişi)`, aktifKoro.hesapBilgiList.where(e => hesapIdList.contains(e.id)).select(e => `${e.kullaniciModel.ad} ${e.kullaniciModel.soyad}`).join("\n"));
  }


  const anketRowListGetir = (anketList: AnketModel[]): JSX.Element[] | null => {
    if (anketList.length === 0)
      return null;

    return anketList.map(anket => {
      let sesGrupIsim = BusinessHelper.SesGrubu.IdListtenIsimBirlestir(aktifKoro.tumSesGruplari, anket.sesGrupIdList);
      const kadroIsim = BusinessHelper.Kadro.IdListtenIsimBirlestir(aktifKoro.tumKadrolar, anket.kadroIdList, "Full");

      const uygunKisiIdList = BusinessHelper.Anket.AnketeUygunKisiIdleriGetir(anket, genelDurumHelper);
      const tumCevaplayanlarIdList = aktifKoro.hesapAnketCevapModelList.where(e => e.anketModelId === anket.id).select(e => e.hesapModelId).distinct();
      const anketeDahilOlacakHerkes = [...uygunKisiIdList, ...tumCevaplayanlarIdList].distinct();
      const cevaplamayanHerkes = anketeDahilOlacakHerkes.except(tumCevaplayanlarIdList).distinct();

      const durumIcerik = anket.aktif ? <>
        <span style={{ fontWeight: "bold", color: appColors.GREEN }}>
          &nbsp;{anket.aktif ? "Açık" : "Kapalı"}
        </span>
        &nbsp;
        <Link onClick={() => showKullaniciList(cevaplamayanHerkes)} style={{ cursor: "pointer" }}>
          ({tumCevaplayanlarIdList.length} / {anketeDahilOlacakHerkes.length})
        </Link>
      </> : <>
        <span>
          Kapalı
        </span>
        &nbsp;
        ({tumCevaplayanlarIdList.length} Kişi doldurdu)</>

      if (sesGrupIsim)
        sesGrupIsim = ` (${sesGrupIsim})`;

      const altBilgi2 = `Hedef kitle: ${kadroIsim}${sesGrupIsim}`;
      const altBilgi3 = `Tarih: ${DateTimeToStringHelper.toMonthDayStringOptionalYear(anket.tarihBaslangic, genelDurumHelper.translate)} - ${DateTimeToStringHelper.toMonthDayStringOptionalYear(anket.tarihBitis, genelDurumHelper.translate)}`;

      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, text: "Güncelle", color: "primary", onClick: () => anketGuncelle(anket, yonetimYetkisi) });
      rightButtons.push({ icon: appIcons.Genel.soruList, text: "Sorular", color: "primary", onClick: () => sorulariGuncelle(anket, yonetimYetkisi) });

      rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", text: "Sil", disabled: !yonetimYetkisi, onClick: () => anketSil(anket) });

      if (anket.aktif === false)
        rightButtons.push({ icon: appIcons.CrudIcons.onayla, color: "success", text: "Anketi Aç", disabled: !yonetimYetkisi, onClick: () => anketAcKapat(anket, true) });
      else
        rightButtons.push({ icon: appIcons.CrudIcons.onayKaldir, color: "secondary", text: "Anketi Kapat", disabled: !yonetimYetkisi, onClick: () => anketAcKapat(anket, false) });

      return (
        <CsmSwipableListItem leftButtons={rightButtons} key={anket.id} style={{ width: "400px" }}>
          <Rows>
            <CsmText style={{ alignSelf: "flex-start" }}>{anket.baslik}</CsmText>
            <Columns>
              <CsmText style={{ alignSelf: "flex-start" }} note>
                Durum:
                {durumIcerik}
              </CsmText>
            </Columns>
            <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi2}</CsmText>
            <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi3}</CsmText>
          </Rows>
        </CsmSwipableListItem>
      );
    });
  }

  const anketEkle = async () => {
    const anketModel = new AnketModel();
    anketModel.id = GuidHelper.NewGuid();
    anketModel.aktif = false;
    anketModel.koroModelId = aktifKoro.koroModel.id;
    anketModel.sezonModelId = genelDurumHelper.AktifSezon.id;
    anketModel.baslik = "";
    anketModel.tarihBaslangic = DateTimeHelper.today();
    anketModel.tarihBitis = DateTimeHelper.addDays(DateTimeHelper.today(), 3);
    anketModel.soruModelList = [];
    anketModel.kadroIdList = [];
    anketModel.sesGrupIdList = [];
    anketModel.ekHesapIdList = [];
    anketModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    anketModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<AnketModel>("Anket Ekle", (closer) => (
      <AnketPopupEditor
        anketModel={anketModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const anketler = seciliKoro.tumAnketler;
        anketler.push(response!);
      });
  }

  const anketSil = async (anketModel: AnketModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", `Anketi silmek istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Anket.AnketSil(anketModel.id, "Anket siliniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const anketler = seciliKoro.tumAnketler;
        anketler.remove(anketler.single(e => e.id === anketModel.id));
      });
  }

  const sorulariGuncelle = async (anketModel: AnketModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<AnketModel>("Soruları Güncelle", (closer) => (
      <AnketSoruListPopupEditor
        guncellenebilir={guncellenebilir}
        anketModel={anketModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const anketler = seciliKoro.tumAnketler;
        anketler.remove(anketler.single(e => e.id === response!.id));
        anketler.push(response!);
      });
  }

  const anketAcKapat = async (anketModel: AnketModel, hedefDurum: boolean): Promise<void> => {
    if (anketModel.soruModelList.length === 0) {
      AlertHelper.ShowMessage("Dikkat", "Anketi açmanız için içinde en az bir soru bulunması gerekmektedir.");
      return;
    }

    const webResponse = await genelDurumHelper.requestHelper.Anket.AnketAcKapat(new AnketAcKapatRequestModel(anketModel.id, hedefDurum), hedefDurum ? "Anket Açılıyor" : "Anket Kapanıyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const anketler = seciliKoro.tumAnketler;
        anketler.single(e => e.id === anketModel.id).aktif = hedefDurum;
      });
  }

  const anketGuncelle = async (anketModel: AnketModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<AnketModel>("Anket Güncelle", (closer) => (
      <AnketPopupEditor
        guncellenebilir={guncellenebilir}
        anketModel={anketModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const anketler = seciliKoro.tumAnketler;
        anketler.remove(anketler.single(e => e.id === response!.id));
        anketler.push(response!);
      });
  }

  const tumAnketler = aktifKoro.tumAnketler.orderByDesc(e => e.tarihBaslangic);

  const today = DateTimeHelper.today();

  const ileriAnketler = tumAnketler.where(e => e.tarihBaslangic > today);
  const guncelAnketler = tumAnketler.where(e => e.tarihBaslangic <= today && e.tarihBitis >= today);
  const eskiAnketler = tumAnketler.where(e => e.tarihBitis < today);

  return (
    <MainLayout
      location={mainScreenLocations.anketYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns width="100%" mt={10}>
          <Cell width={400}>
            <CsmTextField label={genelDurumHelper.translate("Filtre")} value={searchFilter} onChange={e => setSearchFilter(e)} eventOnKeyPress narrow />
          </Cell>
          <Cell flex></Cell>
          <Cell>
            <Button disabled={!yonetimYetkisi} onClick={() => anketEkle()}>
              {appIcons.CrudIcons.ekle}
              <Typography>{genelDurumHelper.translate("Anket Ekle")}</Typography>
            </Button>
          </Cell>
        </Columns>
        <CsmAccordionSimple
          title={genelDurumHelper.translate("İleri Tarihli Anketler")}
          children={() => anketRowListGetir(ileriAnketler)}
          emptyNote={genelDurumHelper.translate("Anket bulunmamaktadır")}
          alwaysOpen
        />
        <CsmAccordionSimple
          title={genelDurumHelper.translate("Güncel Anketler")}
          children={() => anketRowListGetir(guncelAnketler)}
          emptyNote={genelDurumHelper.translate("Anket bulunmamaktadır")}
          alwaysOpen
        />
        <CsmAccordionSimple
          title={genelDurumHelper.translate("Eski Anketler")}
          children={() => anketRowListGetir(eskiAnketler)}
          emptyNote={genelDurumHelper.translate("Anket bulunmamaktadır")}
          alwaysOpen
        />
      </Rows>
    </MainLayout>
  );
}

export default AnketYonetimScreen;

