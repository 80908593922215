import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import Cell from "../../../library/components/layout/Cell";
import Columns from "../../../library/components/layout/Columns";
import appColors from "../../../app/appColors";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { HesapKuartetModel } from "../../../models/businessModels/HesapKuartetModel";
import { mainScreenLocations } from "../../../app/mainScreens";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { Button, FormControl, Switch, Typography } from "@mui/material";
import CizelgeLegend from "./CizelgeLegend";
import { ICizelgeStyles, CizelgeCellInfo, CizelgeData, CizelgeColumnInfo, CizelgeRowInfo, CizelgeTable, GetColorForPercent, GetColorForHesap, TekilCizelgeTable } from "./CizelgeTable";
import Rows from "../../../library/components/layout/Rows";
import VatanKadroSelectField from "../../../components/vatan/VatanKadroSelectField";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import CsmLightCollapsableContainer from "../../../library/components/mui/CsmVatanLightCollapsableContainer";

const mainStyles: ICizelgeStyles = {
    cellHeight: 15,
    cellWidth: 15,
    rightmostColumnWidth: 30,
    firstColumnWidth: 135,
    firstRowHeight: 100,
    leftHeaderColumnFontSize: "10px",
    topHeaderRowFontSize: "8px",
    firstRowGroupInnerHeight: 0, // ay başlıkları
    bottomSummaryRowHeight: 150,
}

type KuartetCellInfo = CizelgeCellInfo<SarkiModel, HesapBilgiVeKullaniciModel, HesapKuartetModel | null>;
type KuartetData = CizelgeData<SarkiModel, HesapBilgiVeKullaniciModel, HesapKuartetModel | null>;
type KuartetColumnInfo = CizelgeColumnInfo<SarkiModel>;
type KuartetRowInfo = CizelgeRowInfo<HesapBilgiVeKullaniciModel>;

function KuartetCizelgeScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <KuartetCizelgeScreenInner genelDurumHelper={genelDurumHelper} />
}

function KuartetCizelgeScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);
    const [eskiKoristGoster, setEskiKoristGoster] = useState<boolean>(false);
    const [araVerenKoristGoster, setAraVerenKoristGoster] = useState<boolean>(false);
    const [sadeceKayitSurecindekiSarkilariGoster, setSadeceKayitSurecindekiSarkilariGoster] = useState<boolean>(false);

    const tumHesapKuartetler2 = genelDurumHelper.SeciliKoroModel.hesapKuartetModelList;
    const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
    const koroAyarlari = genelDurumHelper.SeciliKoroModel.koroAyarlari;

    const sadeceKendisiMi = genelDurumHelper.SeciliHesapBilgiModel.kadroVeSesGrubuBilgiModelList.length > 0 && genelDurumHelper.SeciliKoroModel.hesapKuartetModelList.all(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);

    const secilebilirSezonlar = sadeceKendisiMi ? BusinessHelper.Genel.KoristinKatildigiSezonlariGetir(genelDurumHelper) : genelDurumHelper.SeciliKoroModel.tumSezonlar;

    const secilebilirKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === seciliSezonId).where(e => !sadeceKendisiMi || BusinessHelper.Kadro.HesapKadroyaDahilMi(genelDurumHelper.SeciliHesapBilgiModel, e.id));

    const [seciliKadroId, setSeciliKadroId] = useState<string>(StringHelper.nullToEmpty(secilebilirKadrolar.orderBy(e => e.olusturmaTarih).firstOrDefault()?.id));

    const koristList = genelDurumHelper.SeciliKoroModel.hesapBilgiList
        .where(e => BusinessHelper.Genel.KoristMi(e) && (!sadeceKendisiMi || e.id === genelDurumHelper.SeciliHesapBilgiModel.id))
        .where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, seciliKadroId))
        .where(e => !e.araVerdi || araVerenKoristGoster)
        .where(e => e.aktif || eskiKoristGoster);

    const sarkiList: SarkiModel[] = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === seciliSezonId) && (!sadeceKayitSurecindekiSarkilariGoster || e.kayitGondermeSurecinde)).orderBy(e => seciliSezonId === genelDurumHelper.AktifSezon.id ? e.sira : e.isim);
    const alakaliHesapKuartetler = tumHesapKuartetler2.where(hk => sarkiList.any(e => e.id === hk.sarkiModelId) && koristList.any(e => e.id === hk.hesapModelId));

    const contentSolUst = <CizelgeLegend
        miniWidth={mainStyles.cellWidth}
        miniHeight={mainStyles.cellHeight}
        textWidth={100}
        fontSize={10}
        items={[
            { text: "Kuartet başarılı", color: appColors.VERYLIGHTGREEN },
            { text: "Kuartet hatalı", color: appColors.RED },
            { text: "Kuartet yapılmadı", color: appColors.VERYLIGHTRED },
        ]}
    />

    const mySarkiInfoList: KuartetColumnInfo[] = sarkiList.orderBy(e => e.sira).map((item, i) => {
        return {
            backgroundColor: undefined,
            isUnused: false,
            id: item.id,
            data: item,
            group: "",
            order: i,
        }
    });

    const hesabinSesAltGrubunuGetir = (hesap: HesapBilgiVeKullaniciModel) => {
        const bulunanGrupId = hesap.kadroVeSesGrubuBilgiModelList.singleOrDefault(e => e.kadroModelId === seciliKadroId)?.altSesGrupModelId;
        return bulunanGrupId ? genelDurumHelper.SeciliKoroModel.tumSesGruplari.single(e => e.id === bulunanGrupId) : BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => hesap.sesGrupIdList.contains(e.id)))!;
    };

    const sesGruplar = koristList.select(korist => hesabinSesAltGrubunuGetir(korist)).distinct().orderBy(e => e.sira);
    const myKoristInfoList: KuartetRowInfo[] = sesGruplar.flatMap(sesGrup => koristList.where(e => hesabinSesAltGrubunuGetir(e).id === sesGrup.id).map((korist, i) => {
        return {
            id: korist.id,
            data: korist,
            backColor: GetColorForHesap(korist),
            group: sesGrup!.isim,
            order: i,
        }
    }));

    const myData: KuartetCellInfo[] = myKoristInfoList.flatMap(korist => mySarkiInfoList.map(sarki => {
        return {
            column: sarki,
            row: korist,
            data: alakaliHesapKuartetler.singleOrDefault(e => e.sarkiModelId === sarki.data.id && e.hesapModelId === korist.data.id),
        }
    }));

    const mainData: KuartetData = {
        cells: myData,
        columns: mySarkiInfoList,
        rows: myKoristInfoList,
        hasSummaryBottomRow: myKoristInfoList.length > 1,
        hasSummaryRightmostColumn: true,
        GetRowsFirstColumnString: (data: HesapBilgiVeKullaniciModel) => (data.kullaniciModel.ad + " " + data.kullaniciModel.soyad),
        GetColumnsFirstRowContent: (data: SarkiModel) => data.isim,
        GetColumnsFirstRowSummaryHeaderString: "Kişi Ağırlıklı Ortalaması",
        GetRowsFirstColumnSummaryHeaderString: "Şarkı Ortalaması",
        GetCellContent: GenerateKisiSarkiKutusu,
        GetRowsLastSummaryColumnCellContent: GenerateKisininOrtalamasiKutusu,
        GetColumnsLastSummaryRowCellContent: GenerateSarkiOrtalamasiKutusu,
        GetGlobalSummaryCellContent: GenerateGlobalOrtalamaKutu,
        GetLeftTopContent: contentSolUst,
    };

    function listeyiKopyala() {
        const textList = myKoristInfoList.select(hesap => {
            const isim = hesap.data.kullaniciModel.ad + " " + hesap.data.kullaniciModel.soyad;

            const sarkilar = myData.where(e => e.row.data.id === hesap.id).where(e => e?.data?.kuartetBasarili !== true).select(e => e.column.data.isim).join(", ");

            return { isim: isim, sarkilar: sarkilar };
        })

        navigator.clipboard.writeText(textList.select(e => e.isim + "\t" + e.sarkilar).join("\n"));

        AlertHelper.ShowMessage("Dikkat", "İçerik Kopyalandı");
    }

    function GenerateKisiSarkiKutusu(cellInfo: KuartetCellInfo) {
        const sarki = cellInfo.column.data;
        // const hesap = cellInfo.row.data;

        let backgroundColor: string | undefined = undefined;

        if (cellInfo.data == null) {
            if (sarki.kayitGondermeSurecinde)
                backgroundColor = appColors.VERYLIGHTRED;
            else
                backgroundColor = appColors.GREYALPHA_02;
        }
        else if (cellInfo.data.kuartetBasarili === false)
            backgroundColor = appColors.RED;
        else
            backgroundColor = appColors.LIGHTGREEN;

        return { text: "", backcolor: backgroundColor }
    }

    function GenerateKisininOrtalamasiKutusu(row: HesapBilgiVeKullaniciModel) {
        const hesapKuartetList = alakaliHesapKuartetler.where(e => e.hesapModelId === row.id);
        const basariliKuartet = hesapKuartetList.count(e => e.kuartetBasarili === true);
        const basarisizKuartet = hesapKuartetList.count(e => e.kuartetBasarili === false);
        const toplamKuartet = basariliKuartet + basarisizKuartet;
        const oran = (basariliKuartet) / (toplamKuartet === 0 ? 1 : toplamKuartet);

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari), extraText: "" };
    }

    function GenerateSarkiOrtalamasiKutusu(column: SarkiModel) {
        const hesapKuartetList = alakaliHesapKuartetler.where(e => e.sarkiModelId === column.id);
        const basariliKuartet = hesapKuartetList.count(e => e.kuartetBasarili === true);
        const basarisizKuartet = hesapKuartetList.count(e => e.kuartetBasarili === false);
        const toplamKuartet = basariliKuartet + basarisizKuartet;
        const oran = (basariliKuartet) / (toplamKuartet === 0 ? 1 : toplamKuartet);

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari) };
    }

    function GenerateGlobalOrtalamaKutu() {
        const basariliKuartet = alakaliHesapKuartetler.count(e => e.kuartetBasarili === true);
        const basarisizKuartet = alakaliHesapKuartetler.count(e => e.kuartetBasarili === false);
        const toplamKuartet = basariliKuartet + basarisizKuartet;
        const oran = (basariliKuartet) / (toplamKuartet === 0 ? 1 : toplamKuartet);

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari) };
    }

    return <MainLayout
        location={mainScreenLocations.kuartetCizelge}
        genelDurumHelper={genelDurumHelper}
        hideHeader={false}
        hideMenu={false}
        disallowScroll={true}>
        {mainData.rows.length === 0 ? <CsmText header italic style={{ marginLeft: "10px", marginTop: "10px", minHeight: 0 }} >{genelDurumHelper.translate("Gösterilecek veri bulunmamaktadır")}</CsmText> :
            <Rows ml={10} mt={10} style={{ minHeight: 0 }}>
                <Rows mb={10}>
                    <CsmLightCollapsableContainer collapsedTitle="Filtreler" initialOpen>
                        <Columns mt={10} mb={10} wrap>
                            <Cell style={{ flexGrow: 0, minWidth: "250px" }}>
                                <VatanSezonSelectField
                                    value={seciliSezonId}
                                    label="Seçili Sezon"
                                    tumSezonlar={secilebilirSezonlar}
                                    onChange={value => setSeciliSezonId(value)}
                                />
                            </Cell>
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ width: "250px", marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <VatanKadroSelectField
                                        value={secilebilirKadrolar.any(e => e.id === seciliKadroId) ? seciliKadroId : ""}
                                        label="Seçili Kadro"
                                        tumKadrolar={secilebilirKadrolar}
                                        onChange={value => setSeciliKadroId(value)}
                                    />
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={araVerenKoristGoster} onChange={(e) => setAraVerenKoristGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{araVerenKoristGoster ? "Ara Veren Koristleri Göster" : "Ara Veren Koristleri Gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={sadeceKayitSurecindekiSarkilariGoster} onChange={(e) => setSadeceKayitSurecindekiSarkilariGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{sadeceKayitSurecindekiSarkilariGoster ? "Sadece Kayıt sürecindeki şarkıları göster" : "Tüm Şarkıları Göster"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={eskiKoristGoster} onChange={(e) => setEskiKoristGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{eskiKoristGoster ? "Eski Koristleri Göster" : "Eski Koristleri Gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <Button variant="contained" onClick={() => listeyiKopyala()}>
                                    Listeyi Yazı Olarak Kopyala
                                </Button>
                            }
                            <Cell flex></Cell>
                        </Columns>
                    </CsmLightCollapsableContainer>
                </Rows>
                {sadeceKendisiMi ?
                    <TekilCizelgeTable data={mainData} styles={mainStyles} />
                    :
                    <CizelgeTable data={mainData} styles={mainStyles} />
                }
            </Rows>
        }
    </MainLayout>;
}

export default KuartetCizelgeScreen;