import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import Columns from "../../../library/components/layout/Columns";
import { CsmText } from "../../../library/components/mui/CsmText";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import { alpha, Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import appColors from "../../../app/appColors";
import { CSSProperties } from "react";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import YoklamaBildirmePopupEditor from "../takvim/YoklamaBildirmePopupEditor";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { HesapCalismaModel } from "../../../models/businessModels/HesapCalismaModel";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";


interface IGuncelCalismaDisplayerProps {
    genelDurumHelper: GenelDurumHelper,
}

function GuncelCalismaDisplayer(props: IGuncelCalismaDisplayerProps) {
    const { genelDurumHelper } = props;

    const aktifKoro = genelDurumHelper.SeciliKoroModel;
    const yoklamaArabirimiKullanilsin = aktifKoro.koroAyarlari.yoklamaArabirimiKullanilsin;
    const calismaYetki = genelDurumHelper.HasYetkiAny([EnmYetki["Calışma Yönetim"], EnmYetki["Calışma İzleme"]]);

    const calismalar = aktifKoro.tumCalismalar.where(e => DateTimeHelper.getDateWithoutTime(e.tarihBaslangic) >= DateTimeHelper.today());
    const yukumluCalismaIdler = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(calismalar, genelDurumHelper.SeciliHesapBilgiModel, aktifKoro.hesapCalismaModelList, false).select(e => e.id);

    const gosterilecekCalismalar = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(calismalar, genelDurumHelper.SeciliHesapBilgiModel, aktifKoro.hesapCalismaModelList, calismaYetki || !aktifKoro.koroAyarlari.koristlerSadeceSorumluCalismalariGorebilir).orderBy(e => e.tarihBaslangic);

    const pazartesi1 = DateTimeHelper.getMondayOfWeek(DateTimeHelper.today());
    const pazartesi2 = DateTimeHelper.addDays(pazartesi1, 7);
    const pazartesi3 = DateTimeHelper.addDays(pazartesi2, 7);
    const ay1 = DateTimeHelper.addMonth(pazartesi2, 1);
    const ay2 = DateTimeHelper.addMonth(ay1, 1);
    const ay3 = DateTimeHelper.addMonth(ay2, 1);
    const ay4 = DateTimeHelper.addMonth(ay3, 1);

    const buHaftaCalismalarData = gosterilecekCalismalar.where(e => e.tarihBaslangic >= pazartesi1 && e.tarihBitis < pazartesi2);

    const buHaftaCalismalar: { calismaList: CalismaModel[], title: string, showEmpty?: boolean }[] = buHaftaCalismalarData.length <= 4 ? [
        { calismaList: buHaftaCalismalarData, title: genelDurumHelper.translate("Bu Hafta"), showEmpty: true },
    ] : Array.from(Array(7).keys()).map(additionDays => {
        const currentDay = DateTimeHelper.addDays(pazartesi1, additionDays);
        const nextDay = DateTimeHelper.addDays(pazartesi1, additionDays + 1);
        const relatedCalismalar = buHaftaCalismalarData.where(e => e.tarihBaslangic >= currentDay && e.tarihBitis < nextDay);

        return { calismaList: relatedCalismalar, title: DateTimeToStringHelper.toMonthDayAndDayOfWeekStringWithoutYear(currentDay, genelDurumHelper.translate), showEmpty: true };
    });

    const groupedCalismalar: { calismaList: CalismaModel[], title: string, showEmpty?: boolean }[] = [
        ...buHaftaCalismalar,
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= pazartesi2 && e.tarihBitis < pazartesi3), title: genelDurumHelper.translate("Sonraki Hafta Haftası", { hafta: DateTimeToStringHelper.toMonthDayStringOptionalYear(pazartesi2, genelDurumHelper.translate) }) },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= pazartesi3 && e.tarihBitis < ay1), title: genelDurumHelper.translate("Diğer Haftalar Haftası ve Sonrası", { hafta: DateTimeToStringHelper.toMonthDayStringOptionalYear(pazartesi3, genelDurumHelper.translate) }) },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay1 && e.tarihBitis < ay2), title: `${DateTimeToStringHelper.toMonthAndYearString(ay1, genelDurumHelper.translate)}` },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay2 && e.tarihBitis < ay3), title: `${DateTimeToStringHelper.toMonthAndYearString(ay2, genelDurumHelper.translate)}` },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay3 && e.tarihBitis < ay4), title: `${DateTimeToStringHelper.toMonthAndYearString(ay3, genelDurumHelper.translate)}` },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay4), title: genelDurumHelper.translate("TARIH ve sonrası", { tarih: DateTimeToStringHelper.toMonthAndYearString(ay4, genelDurumHelper.translate) }) },
    ];

    async function katilimDurumBildir(calisma: CalismaModel, hesapCalisma: HesapCalismaModel | null) {
        const response = await AlertHelper.ShowClosableContent<HesapCalismaModel>(`Yoklama Durum Bildir`, (closer) =>
            <YoklamaBildirmePopupEditor
                closer={closer}
                caslima={calisma}
                genelDurumHelper={genelDurumHelper}
                orijinalHesapCalisma={hesapCalisma}
            />
        );

        if (response == null)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const seciliHesap = seciliKoro.hesapBilgiModel;
                const hesapCalismaList = seciliKoro.hesapCalismaModelList;
                const itemToRemove = hesapCalismaList.singleOrDefault(e => e.calismaModelId === calisma.id && e.hesapModelId === seciliHesap.id);

                if (itemToRemove)
                    hesapCalismaList.remove(itemToRemove);
                hesapCalismaList.push(response!);
            });
    }

    function renderCalismaList(calismaList: CalismaModel[]): JSX.Element[] | null {
        if (calismaList.length === 0)
            return null;

        return calismaList.map((calisma) => {
            const hedefKitle = BusinessHelper.Genel.HedefKitleTextGetir(calisma.kadroIdList, calisma.sesGrupIdList, aktifKoro.tumKadrolar, aktifKoro.tumSesGruplari);

            const hedefKitleMi = yukumluCalismaIdler.contains(calisma.id);
            const hedefKitleStyle: CSSProperties = hedefKitleMi ? { color: appColors.TEXT_SECONDARY } : {};

            const hesapCalismaModel = aktifKoro.hesapCalismaModelList.singleOrDefault(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.calismaModelId === calisma.id);

            // hedef kitleyse ve okumamışsa, doldurmak zorunda
            const doldurmali = (calisma.tarihBitis > DateTimeHelper.today()) && hedefKitleMi && (hesapCalismaModel == null || hesapCalismaModel.katilimDurum == null);
            const katilimRenkYazi = BusinessHelper.Calisma.KatilimDurumRenkYaziGetir(calisma, aktifKoro.hesapCalismaModelList, genelDurumHelper.SeciliHesapBilgiModel, yoklamaArabirimiKullanilsin);
            //zorunlu değilse katılım satırı olmasın ve bildirim yapılmamışsa
            //zorunlu ise özel renklensin

            return (
                <Columns hasLightBottomBorder key={calisma.id} width={390} mb={5}>
                    <div style={{ display: "flex", placeContent: "center", width: "40px" }}>
                        {appIcons.Genel.takvimPrimary}
                    </div>
                    <Rows flex>
                        <CsmText>{DateTimeToStringHelper.toMonthDayAndDayOfWeekString(calisma.tarihBaslangic, genelDurumHelper.translate)} {DateTimeToStringHelper.toTimeString(calisma.tarihBaslangic)} - {DateTimeToStringHelper.toTimeString(calisma.tarihBitis)}</CsmText>
                        <CsmText style={{ ...hedefKitleStyle }} note>{genelDurumHelper.translate("Hedef Kitle")}: {hedefKitle}</CsmText>
                        <CsmText note>{genelDurumHelper.translate("İçerik")}: {calisma.icerik}</CsmText>
                        {(doldurmali || hesapCalismaModel) ?
                            <div style={{ marginLeft: "-10px" }} >
                                <Button fullWidth onClick={() => katilimDurumBildir(calisma, hesapCalismaModel)}>
                                    <CsmText style={{ width: "100%", textTransform: "none", color: appColors.BACKGROUND, paddingLeft: "15px", paddingRight: "15px", borderRadius: "5px", background: katilimRenkYazi.renk ? alpha(katilimRenkYazi.renk!, 0.7) : undefined }} note>{katilimRenkYazi.yazi}</CsmText>
                                </Button></div>
                            : null}
                    </Rows>
                </Columns>
            );
        });
    }

    const calismalarIcerik = <Rows>
        {groupedCalismalar.map((item, i) => (item.calismaList.length === 0 && item.showEmpty === false) ? null : (
            <Rows key={i} ml={15} mb={15}>
                <CsmText bold style={{ marginBottom: "5px" }}>{item.title}</CsmText>
                <Columns wrap>
                    {renderCalismaList(item.calismaList) ?? <CsmText note>{genelDurumHelper.translate("Çalışma bulunmamaktadır")}</CsmText>}
                </Columns>

            </Rows>
        ))}
    </Rows>;

    return <Rows flex>
        <CsmHeader pl={15} hasVeryLightBottomBorder>{genelDurumHelper.translate("Yaklaşan Çalışmalar")}</CsmHeader>
        {calismalarIcerik}
    </Rows>
}


export default GuncelCalismaDisplayer;