interface colorRgba { r: number, g: number, b: number, a: number }

export const ColorCalculationHelper = {
    rgbaColors: {
        yellow: (a: number) => ({ r: 255, g: 255, b: 0, a: a }),
        red: (a: number) => ({ r: 255, g: 0, b: 0, a: a }),
        green: (a: number) => ({ r: 0, g: 255, b: 0, a: a }),
        blue: (a: number) => ({ r: 0, g: 0, b: 255, a: a }),
    },
    rgb2hex: (r: number, g: number, b: number, a?: number) => {
        return `#${Math.round(r).toString(16).padStart(2, "0")}${Math.round(g).toString(16).padStart(2, "0")}${Math.round(b).toString(16).padStart(2, "0")}${(Math.round((a ?? 1) * 255)).toString(16).padStart(2, "0")}`;
    },
    DecomposeColor: (colorString: string): { r: number, g: number, b: number, a: number } => {
        return {
            r: parseInt(colorString.slice(1, 3), 16),
            g: parseInt(colorString.slice(3, 5), 16),
            b: parseInt(colorString.slice(5, 7), 16),
            a: colorString.length <= 7 ? 255 : parseInt(colorString.slice(7, 9), 16),
        }
    },
    GetColorAtPoint: (percentValue: number | undefined, gradient: "YellowToRed" | "GreenYellowRed") => {
        if (percentValue === undefined) return null;

        if (gradient === "YellowToRed")
            return ColorCalculationHelper.MinMaxRenkGetir(percentValue, 0, 1, ColorCalculationHelper.rgbaColors.yellow(1), ColorCalculationHelper.rgbaColors.red(1));
        else {
            if (percentValue <= 0.5)
                return ColorCalculationHelper.MinMaxRenkGetir(percentValue, 0, 0.5, ColorCalculationHelper.rgbaColors.green(1), ColorCalculationHelper.rgbaColors.yellow(1));
            else
                return ColorCalculationHelper.MinMaxRenkGetir(percentValue, 0.5, 1, ColorCalculationHelper.rgbaColors.yellow(1), ColorCalculationHelper.rgbaColors.red(1));
        }
    },
    MinMaxRenkGetir: (value: number, min: number, max: number, colorMin: colorRgba, colorMax: colorRgba) => {
        if (min === max) {
            return ColorCalculationHelper.rgb2hex(
                (colorMin.r + colorMax.r) / 2,
                (colorMin.g + colorMax.g) / 2,
                (colorMin.b + colorMax.b) / 2,
                (colorMin.a + colorMax.a) / 2,
            );
        }

        const rDiff = colorMax.r - colorMin.r;
        const gDiff = colorMax.g - colorMin.g;
        const bDiff = colorMax.b - colorMin.b;
        const aDiff = colorMax.a - colorMin.a;

        const stepPercent = (value - min) / (max - min);

        return ColorCalculationHelper.rgb2hex(colorMin.r + stepPercent * rDiff,
            colorMin.g + stepPercent * gDiff,
            colorMin.b + stepPercent * bDiff,
            colorMin.a + stepPercent * aDiff
        );
    },
    MinMaxRenkGetir_YellowToColor: (value: number, min: number, max: number) => {
        return ColorCalculationHelper.MinMaxRenkGetir(value, min, max, { r: 255, g: 0, b: 0, a: 0.2 }, { r: 255, g: 255, b: 0, a: 0.2 });
    },
    ChartPalettes: {
        colors_Bright: [
            "#1f77b4",
            "#ff7f0e",
            "#2ca02c",
            "#d62728",
            "#9467bd",
            "#8c564b",
            "#e377c2",
            "#7f7f7f",
            "#bcbd22",
            "#17becf",
            "#ffbb78",
            "#98df8a",
            "#ff9896",
            "#c5b0d5",
            "#f7b6d2"
        ],

        colors_LessBright: [
            "#4a6e8f",
            "#e08a2c",
            "#4a7f4e",
            "#a73d34",
            "#7d5a8b",
            "#6e4a4b",
            "#d76e8d",
            "#9d9d9d",
            "#9a9b3d",
            "#2a8f8f",
            "#e0a469",
            "#8fd08d",
            "#e08a8a",
            "#b2a2c7",
            "#f2a2b9"
        ],
    }
}

