import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import GenelDurumHelper from '../helpers/GenelDurumHelper';
import { MainScreenInfo, ScreenDefContainer, mainScreenLocations } from '../app/mainScreens';
import Columns from '../library/components/layout/Columns';
import Cell from '../library/components/layout/Cell';
import theme from '../app/theme';
import { Badge, Breadcrumbs } from '@mui/material';
import { appIcons } from '../app/appIcons';
import appColors from '../app/appColors';
import Rows from '../library/components/layout/Rows';
import KorocanThumbnailIcerikId from '../components/business/KorocanThumbnailIcerikId';
import KorocanImage from '../components/business/KorocanImage';
import TranslationHelper from '../translations/TranslationHelper';
import { BusinessHelper } from '../helpers/BusinessHelper';
import EnvironmentHelper from '../helpers/EnvironmentHelper';

export type IKorocanAppBarProps = {
  genelDurumHelper: GenelDurumHelper,
  activeLocationForMenu: string,
  parentPageLocation: string | null,
  pageTitle: string,
  onReloadSonDurum: () => void,
}

function KorocanAppBar(props: IKorocanAppBarProps) {
  const { genelDurumHelper, activeLocationForMenu, parentPageLocation, pageTitle } = props;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(null);

  const adSoyad = `${genelDurumHelper.genelDurum.kullanici.ad} ${genelDurumHelper.genelDurum.kullanici.soyad}`;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  /*-----------------------------------------------*/

  const screenDefContainer = ScreenDefContainer(genelDurumHelper.translate);
  // const yonetimScreens2 = yonetimScreens(translate);
  // const raporlarScreens2 = raporlarScreens(translate);
  // const allScreens2 = allScreens(translate);
  // const mainScreensIlk522 = mainScreensIlk5(translate);
  // const mainScreensIlk5WithYonetim2 = mainScreensIlk5WithYonetim(translate);

  function findParentPage() {
    if (parentPageLocation)
      return screenDefContainer.allScreens.single(e => e.location === parentPageLocation);
    else if (screenDefContainer.yonetimScreens.any(e => e.location === activeLocationForMenu))
      return screenDefContainer.yonetimScreenItem;
    else if (screenDefContainer.raporlarScreens.any(e => e.location === activeLocationForMenu))
      return screenDefContainer.raporlarScreenItem;
    else
      return null;
  }

  const currentParentPage = findParentPage();

  const generateAnaYonetimList = (genelDurumHelper: GenelDurumHelper, ekranlar: MainScreenInfo[]) => {
    const anaActionList: { altBaslik: string, dugmeler: MainScreenInfo[] }[] = [];

    for (const screen of ekranlar) {
      if (screen.inUse && screen.inUse(genelDurumHelper) === false)
        continue;

      if (screen.yetkiler!) {
        if (screen.yetkiler.length > 0 && genelDurumHelper.HasYetkiAll(screen.yetkiler) === false)
          continue;
      }
      else {
        if ((genelDurumHelper.SeciliKoroModel.koroVeHesapModelList?.length ?? 0) < 1)
          continue;
      }

      let foundAnaBaslik = anaActionList.singleOrDefault(e => e.altBaslik === screen.kategori);
      if (foundAnaBaslik == null) {
        foundAnaBaslik = { altBaslik: screen.kategori, dugmeler: [] };
        anaActionList.push(foundAnaBaslik);
      }

      foundAnaBaslik.dugmeler.push(screen);
    }

    return anaActionList;
  }

  const koristMi = BusinessHelper.Genel.KoristMi(genelDurumHelper.SeciliHesapBilgiModel);
  const yonetimMenuList = generateAnaYonetimList(genelDurumHelper, screenDefContainer.yonetimScreens);

  let anaMenuList = yonetimMenuList.length === 0 ? screenDefContainer.mainScreensIlk5 : screenDefContainer.mainScreensIlk5WithYonetim;
  anaMenuList = anaMenuList.where(e => !e.sadeceKorist || koristMi).where(e => !e.inUse || e.inUse(genelDurumHelper) === true);

  const isMenuCurrentPage = (menuLocation: string) => {
    if (activeLocationForMenu === menuLocation)
      return true;

    if (screenDefContainer.yonetimScreenItem.location === menuLocation)
      return screenDefContainer.yonetimScreens.any(e => e.location === activeLocationForMenu);
    else if (screenDefContainer.raporlarScreenItem.location === menuLocation)
      return screenDefContainer.raporlarScreens.any(e => e.location === activeLocationForMenu);

    return false;
  }

  function getPageNotificationCount(page: MainScreenInfo): number {
    return page.getNotificationCount ? page.getNotificationCount(genelDurumHelper) : 0;
  }

  function getPageNotificationTooltip(page: MainScreenInfo): string | undefined {
    return page.getPageNotificationTooltip ? page.getPageNotificationTooltip(genelDurumHelper) : undefined;
  }

  /*-----------------------------------------------*/

  const testTag = !EnvironmentHelper.isTest() ? null : <span style={{ marginLeft: "5px", fontSize: "10px" }}>(Test)</span>;
  const currentLang = TranslationHelper.getCurrentLanguageItem(genelDurumHelper.i18n);

  const menuIcon = (
    <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleOpenNavMenu} sx={{ mr: 2 }}>
      <MenuIcon />
    </IconButton>
  );

  const breadcrumbsXs = (
    <Breadcrumbs aria-label="breadcrumb" style={{ alignSelf: "center", color: appColors.BACKGROUND, fontSize: theme.typography.h6.fontSize }} >
      {!currentParentPage ? null :
        <Columns>
          <Button size='small' variant='contained' color='secondary' sx={{ fontSize: "12px", textTransform: "none" }} onClick={() => genelDurumHelper.navigate(currentParentPage.location)}>
            {appIcons.Genel.geri}
            {genelDurumHelper.translate("Geri")}
          </Button>
        </Columns>}
      <Typography style={{ whiteSpace: "nowrap", overflow: "hidden" }} fontSize="14px">{pageTitle}{testTag}</Typography>
    </Breadcrumbs>
  );

  const breadcrumbsSm = (
    <Breadcrumbs aria-label="breadcrumb" style={{ alignSelf: "center", color: appColors.BACKGROUND, fontSize: theme.typography.h6.fontSize }} >
      {!currentParentPage ? null :
        <Columns>
          <Button size='small' variant='contained' color='secondary' sx={{ fontSize: "14px", textTransform: "none" }} onClick={() => genelDurumHelper.navigate(currentParentPage.location)}>
            {appIcons.Genel.geri}
            {genelDurumHelper.translate("Geri")}
          </Button>
        </Columns>}
      <Typography fontSize="16px">{pageTitle}{testTag}</Typography>
    </Breadcrumbs>
  );

  const breadcrumbsBig = (
    <Breadcrumbs aria-label="breadcrumb" style={{ alignSelf: "center", color: appColors.BACKGROUND, fontSize: theme.typography.h6.fontSize }} >
      {!currentParentPage ? null :
        <Columns>
          <Button variant='contained' color='secondary' sx={{ fontSize: "16px", textTransform: "none" }} onClick={() => genelDurumHelper.navigate(currentParentPage.location)}>
            {appIcons.Genel.geri}
            {genelDurumHelper.translate("Geri")}
          </Button>
        </Columns>
      }
      <Typography variant='h6' ><span style={{ textOverflow: "clip", whiteSpace: "nowrap" }}>{pageTitle}</span>{testTag}</Typography>
    </Breadcrumbs>
  );

  const anamenu = (
    <Menu id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{ display: { xs: 'block', md: 'none' }, }}
    >
      {anaMenuList.map((page, i) => (
        <MenuItem component={Link} to={page.location} key={page.location} onClick={() => genelDurumHelper.navigate(page.location)} divider={i === anaMenuList.length - 1} >
          <Tooltip title={getPageNotificationTooltip(page)}>
            <>
              <Badge badgeContent={getPageNotificationCount(page)} color='error'>
                <Columns>
                  <Cell width={35}>
                    {page.icon}
                  </Cell>
                  <Typography textAlign="center">{page.menuText}</Typography>
                </Columns>
              </Badge>
            </>
          </Tooltip>
        </MenuItem>
      ))}
      {TranslationHelper.KorocanLanguages.length > 1 &&
        <MenuItem onClick={handleOpenLangMenu}>
          <Columns>
            <Cell width={35}>
              <img style={{ borderRadius: "10px", width: "30px", height: "15px", marginTop: "5px" }} src={"/" + currentLang.img} />
            </Cell>
            <Typography textAlign="center">{currentLang.title}</Typography>
          </Columns>
        </MenuItem>
      }
      {/* <MenuItem onClick={() => onReloadSonDurum()}>
        <Tooltip title={translate("Tazele")}>
          <>
            <Columns>
              <Cell width={35}>
                {appIcons.Genel.refresh}
              </Cell>
              <Typography textAlign="center">{translate("Tazele")}</Typography>
            </Columns></>
        </Tooltip>
      </MenuItem> */}
    </Menu>
  );

  const langMenu = (
    <Menu id="menu-lang"
      anchorEl={anchorElLang}
      anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      open={Boolean(anchorElLang)}
      onClose={handleCloseLangMenu}
    >
      {TranslationHelper.KorocanLanguages.map(lang => (
        <MenuItem key={lang.lang} onClick={() => { genelDurumHelper.i18n.changeLanguage(lang.lang); handleCloseLangMenu(); }} disabled={genelDurumHelper.i18n.resolvedLanguage === lang.lang} >
          <Tooltip title={lang.title}>
            <>
              <img src={"/" + lang.img} />
            </>
          </Tooltip>
        </MenuItem>
      ))}
    </Menu>
  );

  const navigationButtonsMd = anaMenuList.map((page) => (
    <Button
      component={Link}
      to={page.location}
      size='small'
      key={page.location}
      onClick={() => genelDurumHelper.navigate(page.location)}
      sx={{ ":hover": { background: appColors.SECONDARY_LIGHT }, background: isMenuCurrentPage(page.location) ? appColors.SECONDARY_LIGHT : undefined, my: 0, mr: 0, color: 'white', display: 'block', borderRadius: 0 }}
      color="secondary"
    >
      <Tooltip title={getPageNotificationTooltip(page)}>
        <>
          <Badge badgeContent={getPageNotificationCount(page)} color='error'>
            <Rows mb={-5} mt={3}>
              <Cell mb={-4} style={{ alignSelf: "center"}}>
                {page.icon}
              </Cell>
              <Cell ml={3} mr={5}>
                <Typography fontSize="14px" >{page.menuText}</Typography>
              </Cell>
            </Rows>
          </Badge></>
      </Tooltip>
    </Button>
  ));

  const logo72 = <Rows>
    <img alt="Logo" src="/krnc-logo-bos-beyaz.png" style={{ height: "48px", margin: "auto" }} />
  </Rows>

  const refreshButton = (
    <Rows style={{ placeContent: "center" }}>
      {TranslationHelper.KorocanLanguages.length > 1 && <Cell height={29}>
        <Tooltip title={currentLang.title}>
          <Button size="small" color="inherit" aria-label="menu" onClick={handleOpenLangMenu} sx={{ mr: 0, mt: 0 }}>
            <img style={{ borderRadius: "10px" }} src={"/" + currentLang.img} />
          </Button>
        </Tooltip>
      </Cell>}
      {/* <Cell height={29}>
        <Tooltip title={translate("Bilgileri Tazele")}>
          <>
            <Button size="small" color="inherit" aria-label="menu" onClick={() => onReloadSonDurum()} sx={{ mr: 0, mt: 0 }}>
              {appIcons.Genel.refresh}
            </Button>
          </>
        </Tooltip>
      </Cell> */}
    </Rows>
  );

  const koro = genelDurumHelper.SeciliKoroModel.koroModel;

  const profilFoto = (
    <div style={{
      width: "158px",
      height: "58px",
      background: appColors.WHITE,
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    }}>
      <IconButton onClick={() => genelDurumHelper.navigate(mainScreenLocations.profil)} sx={{ p: 0 }}>
        <KorocanThumbnailIcerikId
          key="MenuFoto"
          icerikId={genelDurumHelper.SeciliHesapBilgiModel.resimIcerikId}
          size="normal"
          genelDurumHelper={genelDurumHelper}
          title={adSoyad}
          saveToStorage="FullSize" />
        <KorocanImage
          style={{
            alignSelf: "center",
            width: 100,
            height: 58,
            objectFit: "scale-down",
            borderRadius: "10px",
          }}
          icerikId={koro.resimIcerikId}
          title={koro.isim}
          genelDurumHelper={genelDurumHelper}
          saveToStorage="FullSize" />
      </IconButton>
    </div>
  );

  // const appBarDebugBreakpointColors: SxProps<Theme> = { background: { xs: alpha("#220000", 0.9), sm: alpha("#000022", 0.9), md: alpha("#009999", 0.9), lg: alpha("#002200", 0.9), xl: alpha("#000000", 0.9) } };

  // Cii
  return (
    <AppBar position="relative">
      <Container maxWidth="xl" style={{ paddingRight: "0px" }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, minWidth: 0, display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }}>
            {menuIcon}
            <Box sx={{ display: 'flex', flexGrow: 1, minWidth: 0 }}>
              {breadcrumbsXs}
              {anamenu}
            </Box>
            {profilFoto}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }}>
            {menuIcon}
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              {breadcrumbsSm}
              {anamenu}
            </Box>
            {logo72}
            {profilFoto}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}>
            {logo72}
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'none', xl: 'none' }, flexGrow: 1 }}>
              {breadcrumbsSm}
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }, flexGrow: 1 }}>
              {breadcrumbsBig}
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 0 }}>
              {navigationButtonsMd}
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 0 }}>
              {refreshButton}
              {langMenu}
              {profilFoto}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default KorocanAppBar;