/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, IconButton, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { AnketModel } from "../../../models/businessModels/AnketModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import AnketSoruEditor from "./AnketSoruEditor";
import { AnketSoruModel } from "../../../models/businessModels/AnketSoruModel";
import Cell from "../../../library/components/layout/Cell";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { EnmSoruTipi } from "../../../models/enums/EnmSoruTipi";
import EnumHelper from "../../../library/helpers/EnumHelper";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";

interface IAnketSoruListPopupEditorProps {
    anketModel: AnketModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: AnketModel | null) => void,
}

function AnketSoruListPopupEditor(props: IAnketSoruListPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [anketModel, setAnketModel] = useState<AnketModel>(JsonHelper.deepCopy(props.anketModel));
    const [bolumler, setBolumler] = useState<number[]>(props.anketModel.soruModelList.length === 0 ? [1] : props.anketModel.soruModelList.select(e => e.bolumNo).distinct().orderBy(e => e));

    const bolumEklenebilir = anketModel.soruModelList.length > 0;

    const bolumEkle = () => {
        const result = JsonHelper.deepCopy(bolumler);
        result.push(bolumler.length + 1);
        setBolumler(result);
    }


    const sorulariSil = async (soruIdList: string[], silinenBolumNo?: number) => {
        const result = JsonHelper.deepCopy(anketModel);

        result.soruModelList = result.soruModelList.where(e => soruIdList.contains(e.id) === false).orderBy(e => e.sira);

        for (let i = 0; i < result.soruModelList.length; i++)
            result.soruModelList[i].sira = i + 1;

        if (silinenBolumNo !== undefined)
            result.soruModelList.where(e => e.bolumNo > silinenBolumNo!).forEach(e => e.bolumNo = e.bolumNo - 1);

        setAnketModel(result);
    }

    const bolumSil = async (bolumNo: number) => {
        const bolumunSorulari = anketModel.soruModelList.where(e => e.bolumNo === bolumNo);

        if (bolumunSorulari.length > 0)
            if ((await AlertHelper.Confirm("Dikkat", `Bu bölümde ${bolumunSorulari.length} soru bulunmaktadır. Bu soruları da silmek istediğinizden emin misiniz?`)) === false)
                return;

        // vars sorulari sil, sonraki soruların da bölümünü kaydır
        sorulariSil(bolumunSorulari.select(e => e.id), bolumNo);

        // son bölümü sil
        setBolumler(Array.from(Array(bolumler.length - 1).keys()).select(e => e + 1));
    }

    const anketSoruEkle = async (bolumNo: number) => {
        const tumDegerler = EnumHelper.getNamesAndValues(EnmSoruTipi);
        const secilebilirIsimler = tumDegerler.where(e => e.value !== EnmSoruTipi["Tek Seçmeli"] && e.value !== EnmSoruTipi["Çok Seçmeli"]).select(e => e.name);
        const response = await AlertHelper.ConfirmWithChoices("Dikkat", "Soru Tipini seçin:", [...secilebilirIsimler, "Seçenek"], true);

        if (!response)
            return;

        const result = JsonHelper.deepCopy(anketModel);

        const bolumunEskiSonSorusu = result.soruModelList.where(e => e.bolumNo === bolumNo).orderBy(e => e.sira).lastOrDefault();
        const kayacakSorular = result.soruModelList.where(e => e.bolumNo > bolumNo);
        kayacakSorular.forEach(e => e.sira = e.sira + 1);

        const model = new AnketSoruModel();

        model.id = GuidHelper.NewGuid();
        model.bolumNo = bolumNo;
        model.soruTipi = response === "Seçenek" ? EnmSoruTipi["Tek Seçmeli"] : tumDegerler.single(e => e.name === response).value;
        model.sira = kayacakSorular.length === 0 ? (result.soruModelList.length + 1) : (kayacakSorular.select(e => e.sira).min(e => e)! - 1);
        model.secenekModelList = [];
        model.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        model.olusturmaTarih = DateTimeHelper.now();
        model.kosulModelList = [];

        result.soruModelList.push(model);

        if (bolumunEskiSonSorusu !== null)
            bolumunEskiSonSorusu.kosulModelList = [];

        setAnketModel(result);
        if (bolumler.length < model.bolumNo)
            bolumEkle();
    }

    const deleteAnketSoru = (model: AnketSoruModel) => {
        const result = JsonHelper.deepCopy(anketModel);
        const itemtoRemove = result.soruModelList.single(e => e.id === model.id);
        result.soruModelList.remove(itemtoRemove);

        result.soruModelList = result.soruModelList.orderBy(e => e.sira);
        for (const item of result.soruModelList)
            if (item.sira >= itemtoRemove.sira)
                item.sira--;

        setAnketModel(result);
    }

    const updateAnketSoru = (model: AnketSoruModel) => {
        const result = JsonHelper.deepCopy(anketModel);
        const itemtoRemove = result.soruModelList.single(e => e.id === model.id);
        result.soruModelList.remove(itemtoRemove);
        result.soruModelList.push(model);
        result.soruModelList = result.soruModelList.orderBy(e => e.sira);
        setAnketModel(result);
    }

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Anket.AnketGuncelle(anketModel, "Anket güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Anket.AnketEkle(anketModel, "Anket ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(anketModel);
    }

    return (
        <Rows width={800} style={{ minHeight: "500px" }}>
            <Rows flex style={{ gap: "10px" }}>
                {bolumler.map(bolum => {
                    const bolumunSorulari = anketModel.soruModelList.where(e => e.bolumNo === bolum).orderBy(e => e.sira);

                    return <Rows key={bolum.toFixed()} gap={10}>
                        {bolumler.length === 1 ? null :
                            <Columns style={{ placeItems: "center" }}>
                                <Rows flex>
                                    <CsmHeader bigCentered hasLeftRightLines>{`${bolum}. Bölüm`}</CsmHeader>
                                </Rows>
                                <IconButton color="error" onClick={() => bolumSil(bolum)}>{appIcons.CrudIcons.sil}</IconButton>
                            </Columns>
                        }
                        {bolumunSorulari.map(soru => (<AnketSoruEditor
                            key={soru.id}
                            anketSoruModel={soru}
                            anket={anketModel}
                            guncellenebilir={guncellenebilir}
                            genelDurumHelper={genelDurumHelper}
                            onChanged={e => updateAnketSoru(e)}
                            onDeleted={e => deleteAnketSoru(e)} />))}
                        <Columns>
                            <Cell flex></Cell>
                            <Cell>
                                <Button variant="contained" disabled={!guncellenebilir} onClick={() => anketSoruEkle(bolum)}>
                                    {appIcons.CrudIcons.ekle}
                                    <Typography>{genelDurumHelper.translate("Soru Ekle")}</Typography>
                                </Button>
                            </Cell>
                            <Cell flex></Cell>
                        </Columns>
                    </Rows>
                })}
                {!bolumEklenebilir ? null :
                    <Rows>
                        <Columns hasGrayTopBorder></Columns>
                        <Cell flex></Cell>
                        <Button disabled={!guncellenebilir} onClick={() => bolumEkle()}>
                            {appIcons.CrudIcons.ekle}
                            <Typography>{genelDurumHelper.translate("Bölüm Ekle")}</Typography>
                        </Button>
                    </Rows>
                }

            </Rows>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={!guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default AnketSoruListPopupEditor;