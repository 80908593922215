import StringHelper from "./StringHelper";

export type CsmBase64DosyaModel = { isim: string, uzanti: string, base64Icerik: string };

export default class CsmIcerikHelper {

    public static findEmbedLink(linkUrl: string): string | undefined {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = linkUrl.match(regExp);

        const result = (match && match[2].length === 11)
            ? match[2]
            : null;

        if (result === null)
            return linkUrl;
        else
            return `https://www.youtube.com/embed/${result}`;
    }

    public static UzantiGetir(filename: string) {
        const lastDotIndex = filename.lastIndexOf(".");
        const uzanti = (lastDotIndex === -1 || lastDotIndex === filename.length) ? "." : filename.substring(lastDotIndex);
        return uzanti.toLocaleLowerCase();
    }

    public static async ConvertFilesToIcerikBase64(givenFiles: FileList | File[]): Promise<CsmBase64DosyaModel[]> {
        const fileList: File[] = [];
        for (let i = 0; i < givenFiles.length; i++)
            fileList.push(givenFiles[i]);

        const promises = fileList.select(targetFile =>
            new Promise<CsmBase64DosyaModel>((accept, reject) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    let base64Content = (e.target!.result) as string;

                    const lastDotIndex = targetFile.name.lastIndexOf(".");
                    const uzanti = (lastDotIndex === -1 || lastDotIndex === targetFile.name.length) ? "." : targetFile.name.substring(lastDotIndex);

                    base64Content = base64Content.substring(base64Content.indexOf(",") + 1);
                    accept({ isim: targetFile.name, base64Icerik: base64Content, uzanti: uzanti });
                };
                reader.readAsDataURL(targetFile);
            }));

        const responses = await Promise.all(promises);

        return responses;
    }

    public static DownloadToClient(fileName: string, linkSource: string) {
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public static async GetAudioSourceFromBlob(content: Blob, extension: string) {
        const base64Content = await CsmIcerikHelper.GetBase64FromBlob(content);
        return CsmIcerikHelper.GetAudioSourceFromBase64Content(base64Content, extension);
    }

    public static async GetImageSourceFromBlob(content: Blob) {
        const base64Content = await CsmIcerikHelper.GetBase64FromBlob(content);
        return CsmIcerikHelper.GetImageSourceFromBase64Content(base64Content)
    }

    public static async GetBase64FromBlob(content: Blob) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(content);
            reader.onload = function () {
                const result = reader.result as string;
                const commaIndex = result.indexOf(",");
                const actualBase64 = result.substring(commaIndex + 1);
                resolve(actualBase64);
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    }

    public static GetAudioSourceFromBase64Content(base64Content: string, extension: string) {
        const audioType = extension.startsWith(".mid") ? "midi" : extension.substring(1);
        return `data:audio/${audioType};base64,${base64Content}`;
    }

    public static GetPdfSourceFromBase64Content(base64Content: string) {
        return "data:application/pdf;base64," + base64Content;
    }

    public static GetImageSourceFromBase64Content(base64Content: string) {
        if (StringHelper.isEmpty(base64Content) === true)
            return null;

        return { uri: "data:image/png;base64," + base64Content }
    }

    // Boyutsallar

    public static ConvertNumberToSizeString(value: number): string {
        if (value < 1024)
            return `${Math.floor(value).toLocaleString('en-US', { maximumFractionDigits: 0 })} bytes`;
        else
            return `${(value / 1024).toLocaleString('en-US', { maximumFractionDigits: 0 })} Kb`;
    }

    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    public static ResizeDataURL(baseUri: string, wantedWidth: number) {
        const response = new Promise<string>((resolve, reject) => {
            const img = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const ratio = img.height / img.width;
                const wantedHeight = Math.floor(wantedWidth * ratio);
                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx!.drawImage(img, 0, 0, wantedWidth, wantedHeight);

                const dataURI = canvas.toDataURL();
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = baseUri;
        })
        // We create an image to receive the Data URI

        return response;
    }
}