/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { Button, Chip } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { TarzModel } from "../../../models/businessModels/TarzModel";
import { CsmAccordion, CsmAccordionGroup } from "../../../library/components/mui/CsmAccordion";
import JsonHelper from "../../../library/helpers/JsonHelper";

interface ITarzPopupEditorProps {
    tarzModel: TarzModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: TarzModel | null) => void,
}

function TarzPopupEditor(props: ITarzPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [tarzModel, setTarzModel] = useState<TarzModel>(JsonHelper.deepCopy(props.tarzModel));

    const tumTarzlar = genelDurumHelper.SeciliKoroModel.tumTarzlar;

    const updateTarzModel = (action: (model: TarzModel) => void) => {
        const result = JsonHelper.deepCopy(tarzModel);
        action(result);
        setTarzModel(result);
    }

    const hata = tumTarzlar.any(e => e.id !== tarzModel.id && e.isim === tarzModel.isim) ? "Bu isimde başka bir tarz bulunmaktadır" : "";
    const hataVar = !!hata || !tarzModel.isim;

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Tarz.TarzGuncelle(tarzModel, "Tarz güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Tarz.TarzEkle(tarzModel, "Tarz ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(tarzModel);
    }

    const kullananSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.tarzModelIdList.contains(props.tarzModel.id));

    return (
        <Rows height={guncellemeMi ? 550 : 250} pt={20} style={{ minWidth: 850 }}>
            <CsmTextField
                disabled={!guncellenebilir}
                label="Tarz İsmi"
                error={hataVar}
                helperText={hata}
                value={tarzModel.isim}
                onChange={(e) => updateTarzModel(t => t.isim = e)}
                appendMaxlenOnLabel={50}
                eventOnKeyPress />
            <CsmTextField
                disabled={!guncellenebilir}
                label="Tarz Açıklaması"
                value={tarzModel.aciklama}
                onChange={(e) => updateTarzModel(t => t.aciklama = e)}
                appendMaxlenOnLabel={1000}
                eventOnKeyPress />

            <Rows flex mt={10} style={{ overflowY: "auto" }}>
                {guncellemeMi &&
                    <CsmAccordionGroup initialExpandedKey="hesap" children={(onChange, expandedKey) => (
                        <>
                            {kullananSarkilar.length > 0 ?
                                <CsmAccordion expandedKey="hesap" title={`Grubu İçeren Hesaplar (${kullananSarkilar.length} hesap)`} expanded={expandedKey === "hesap"} onExpandedChanged={onChange} >
                                    <>
                                        <CsmText bold>Grubu İçeren Şarkılar</CsmText>
                                        <Columns wrap>
                                        {kullananSarkilar.map(item => <Chip key={item.id} label={item.isim} />)}
                                        </Columns>
                                    </>
                                </CsmAccordion>
                                :
                                <CsmText note italic>Grubu içeren hesap bulunmamaktadır</CsmText>
                            }
                        </>
                    )} />
                }
            </Rows>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default TarzPopupEditor;