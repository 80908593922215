import CssType from "csstype";
import { BaseCSSProperties } from "@mui/material/styles/createMixins";
import { RefObject } from "react";
import StyleHelper, { IExtraStyleBorderProps, IExtraStyleSpacingProps } from "../../helpers/StyleHelper";

export type RowsCSSProperties = Omit<BaseCSSProperties, "flex" | "marginTop" | "marginRight" | "marginBottom" | "marginLeft" | "paddingTop" | "paddingRight" | "paddingBottom" | "paddingLeft" | "borderLeftColor"| "borderTopColor"| "borderRightColor"| "borderBottomColor">;
export type RowsProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "style"> & IExtraStyleSpacingProps & IExtraStyleBorderProps &
{
    style?: RowsCSSProperties,
    forwardRef?: RefObject<HTMLDivElement>,
    width?: CssType.Property.Width<string | number>,
    height?: CssType.Property.Width<string | number>,
    flex?: boolean | CssType.Property.Width<string | number>,
    wrap?: boolean,
    dontGrowForChild?: boolean,
    dbg_red?: boolean,
    dbg_blue?: boolean,
    dbg_yellow?: boolean,
    ignore?: boolean,
};

export default function Rows(props: RowsProps): JSX.Element {
    const { ignore, dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, forwardRef, ...innerRest } = props;

    if (ignore) return <>{children}</>;

    const rest = StyleHelper.OmitProps(innerRest);

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const customStyle = StyleHelper.CreateStyle(props);

    const givenStyle: React.CSSProperties = {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        display: "flex",
        flexDirection: "column",
        minHeight: dontGrowForChild ? 0 : "auto",
        minWidth: dontGrowForChild ? 0 : "auto",
        flexWrap: wrap ? "wrap" : "unset",
    };
    return (
        <div ref={forwardRef} style={{
            ...givenStyle,
            ...customStyle,
            ...style,
        }} {...rest}>
            {children}
        </div>
    );
}

export function Rows2(props: RowsProps): JSX.Element {
    const { ignore, dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, forwardRef, ...innerRest } = props;

    if (ignore) return <>{children}</>;

    const rest = StyleHelper.OmitProps(innerRest);

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const customStyle = StyleHelper.CreateStyle(props);

    const givenStyle: React.CSSProperties = {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        display: "flex",
        flexDirection: "column",
        minHeight: dontGrowForChild ? 0 : "auto",
        minWidth: dontGrowForChild ? 0 : "auto",
        flexWrap: wrap ? "wrap" : "unset",
    };
    return (
        <div ref={forwardRef} style={{
            ...givenStyle,
            ...customStyle,
            ...style,
        }} {...rest}>
            {children}
        </div>
    );
}
