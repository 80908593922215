/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, FormControl, IconButton, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { TatilModel } from "../../../models/businessModels/TatilModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmDatePicker from "../../../library/components/mui/CsmDatePicker";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { TatilTarihModel } from "../../../models/businessModels/TatilTarihModel";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import Cell from "../../../library/components/layout/Cell";

interface ITatilPopupEditorProps {
    tatilModel: TatilModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: TatilModel | null) => void
}

function TatilPopupEditor(props: ITatilPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [tatilModel, setTatilModel] = useState<TatilModel>(JsonHelper.deepCopy(props.tatilModel));

    const updateTatilModel = (action: (model: TatilModel) => void) => {
        const result = JsonHelper.deepCopy(tatilModel);
        action(result);
        setTatilModel(result);
    }

    const updateTatilTarihModel = (tatilTarihId: string, action: (model: TatilTarihModel) => void) => {
        const result = JsonHelper.deepCopy(tatilModel);
        action(result.tarihler.single(e => e.id === tatilTarihId));
        setTatilModel(result);
    }

    const isimHataVar = !tatilModel.isim;

    const tarihSayisalHata = tatilModel.sabitTarihTekrarEdiyorMu ?
        (tatilModel.tarihler.length !== 1 ? "Tekrar eden tatiller bir 1 adet tarih kaydı girilmelidir" : "") :
        (tatilModel.tarihler.length === 0 ? "Tekrar etmeyen tatiller en az 1 adet tarih kaydı girilmelidir" : "");

    const baslangicTarihHata = tatilModel.tarihler.any(e => (e.tarihBaslangic && e.tarihBitis && e.tarihBaslangic > e.tarihBitis))
        ? "Başlangıç tarihi, bitiş tarihinden sonra olamaz" : "";

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Tatil.TatilGuncelle(tatilModel, "Tatil güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Tatil.TatilEkle(tatilModel, "Tatil ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(tatilModel);
    }

    // if (!baslangicTarihHataVar && !bitisTarihHataVar) {
    //     if (tatilModel.tarihBaslangic < DateTimeHelper.today())
    //         baslamayaKalanSureText = "Tatil başladı";
    //     else {
    //         let baslamayaKalanSure = DateTimeHelper.substractForDays(DateTimeHelper.today(), tatilModel.tarihBaslangic);
    //         baslamayaKalanSureText = `${baslamayaKalanSure} gün sonra başlayacak`;
    //     }

    //     let tatilSure = DateTimeHelper.substractForDays(tatilModel.tarihBaslangic, tatilModel.tarihBitis);
    //     if (tatilModel.tarihBitis < DateTimeHelper.today())
    //         tatilSuresiText = `Süre: Tatil ${tatilSure} gün sürdü`;
    //     else
    //         tatilSuresiText = `Süre: Tatil ${tatilSure} gün sürecek`;
    // }

    const tarihekle = () => {
        const result = JsonHelper.deepCopy(tatilModel);
        const tatilTarih = new TatilTarihModel();
        tatilTarih.id = GuidHelper.NewGuid();
        tatilTarih.tarihBaslangic = DateTimeHelper.today();
        tatilTarih.tarihBitis = DateTimeHelper.today();
        tatilTarih.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        tatilTarih.olusturmaTarih = DateTimeHelper.now();
        result.tarihler.push(tatilTarih);
        setTatilModel(result);
    }

    function getSureAciklama(tatilTarih: TatilTarihModel): string {
        if (tatilTarih.tarihBitis < tatilTarih.tarihBaslangic)
            return "Başlangış tarihi, bitiş tarihinden geç olamaz";
        else
            return `Süre: ${DateTimeHelper.substractForDays(tatilTarih.tarihBaslangic, tatilTarih.tarihBitis) + 1} gün`
    }

    function sabitTarihTekrarEdiyorMuGuncelle(newValue: boolean): void {
        const result = JsonHelper.deepCopy(tatilModel);
        result.sabitTarihTekrarEdiyorMu = newValue;

        if (newValue === true) {
            if (result.tarihler.length === 0) {
                const subModel = new TatilTarihModel();
                subModel.id = GuidHelper.NewGuid();
                subModel.tarihBaslangic = DateTimeHelper.today();
                subModel.tarihBitis = DateTimeHelper.today();
                subModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
                subModel.olusturmaTarih = DateTimeHelper.now();
                result.tarihler.push(subModel);
            }

            if (result.tarihler.length > 1)
                result.tarihler = [result.tarihler.first()];
        }
        setTatilModel(result);
    }

    return (
        <Rows width={850} height={350} pt={20}>
            <Columns>
                <CsmTextField
                    disabled={!guncellenebilir}
                    label="Tatilin İsmi"
                    error={isimHataVar}
                    value={tatilModel.isim}
                    onChange={(e) => updateTatilModel(t => t.isim = e)} />
                <Cell width={150}>
                    <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                        <Rows>
                            <Switch checked={tatilModel.sabitTarihTekrarEdiyorMu} onChange={(e) => sabitTarihTekrarEdiyorMuGuncelle(e.target.checked)} />
                            <Typography sx={{ fontSize: 12, marginTop: "-5px" }}>{tatilModel.sabitTarihTekrarEdiyorMu ? "Her Yıl Sabit" : "Her Yıl Farklı"}</Typography>
                        </Rows>
                    </FormControl>
                </Cell>
                <Button disabled={tatilModel.sabitTarihTekrarEdiyorMu} onClick={() => tarihekle()} color="primary" variant="contained" autoFocus>
                    Tarih Ekle
                </Button>
            </Columns>
            {tatilModel.tarihler.map(tatilTarih =>
                <Rows key={tatilTarih.id}>
                    <Columns mt={10}>
                        <CsmDatePicker
                            disabled={!guncellenebilir}
                            label="Başlangıç Tarihi"
                            error={!!baslangicTarihHata || !tatilTarih.tarihBaslangic}
                            value={tatilTarih.tarihBaslangic}
                            onChange={(e) => updateTatilTarihModel(tatilTarih.id, t => t.tarihBaslangic = e ?? null as any)} />
                        <CsmDatePicker
                            disabled={!guncellenebilir}
                            label="Bitiş Tarihi"
                            error={!!baslangicTarihHata || !tatilTarih.tarihBitis}
                            value={tatilTarih.tarihBitis}
                            style={{ marginLeft: 10 }}
                            onChange={(e) => updateTatilTarihModel(tatilTarih.id, t => t.tarihBitis = e ?? null as any)} />
                        <Rows flex ml={10} mt={5}>
                            <CsmText note>{getSureAciklama(tatilTarih)}</CsmText>
                        </Rows>
                        <IconButton disabled={tatilModel.sabitTarihTekrarEdiyorMu} color="error" onClick={() => updateTatilModel(t => t.tarihler = t.tarihler.where(e => e.id !== tatilTarih.id))}>{appIcons.CrudIcons.sil}</IconButton>
                    </Columns>
                </Rows>
            )}

            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={isimHataVar || !!tarihSayisalHata || !!baslangicTarihHata || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default TatilPopupEditor;