/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmDatePicker from "../../../library/components/mui/CsmDatePicker";
import Cell from "../../../library/components/layout/Cell";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import CsmTimePicker from "../../../library/components/mui/CsmTimePicker";

interface ISecmeBasvuruTarihGuncellePopupEditorProps {
    secmeBasvuruModelList: SecmeBasvuruModel[],
    genelDurumHelper: GenelDurumHelper,
    closer: (model: Date | null | undefined) => void,
}

function SecmeBasvuruTarihGuncellePopupEditor(props: ISecmeBasvuruTarihGuncellePopupEditorProps) {
    const { closer, secmeBasvuruModelList, genelDurumHelper } = props;

    const [seciliTarih, setSeciliTarih] = useState<Date | null>(secmeBasvuruModelList.first().secmeTarihi);


    const updateTarih = (newDate: Date | null): void => {
        if (newDate === null)
            setSeciliTarih(null);
        else {
            const totalMinutes = seciliTarih ? DateTimeHelper.getTotalMinutesOfTime(seciliTarih) : 60 * 15;
            const rawDate = DateTimeHelper.getDateWithoutTime(newDate);
            setSeciliTarih(DateTimeHelper.addMinutes(rawDate, totalMinutes));
        }

    };

    const updateSaat = (newTime: Date): void => {
        if (newTime === null)
            setSeciliTarih(null);
        else {
            const rawDate = DateTimeHelper.getDateWithoutTime(seciliTarih!);
            setSeciliTarih(DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(newTime)));
        }
    };

    const kaydet = async () => {
        closer(seciliTarih === null ? undefined : seciliTarih);
    }

    return (
        <Rows>
            <Rows wrap mt={8} dontGrowForChild>
                <Cell width={210} mr={5} mb={10} >
                    <CsmDatePicker
                        canClean
                        label={"Seçme Tarihi"}
                        value={seciliTarih}
                        onChange={value => updateTarih(value)} />
                </Cell>
                <Cell width={210} mr={5} mb={10}>
                    <CsmTimePicker
                        disabled={seciliTarih === null}
                        label={"Seçme Saati"}
                        value={seciliTarih}
                        onChange={value => updateSaat(value)} />
                </Cell>
            </Rows>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default SecmeBasvuruTarihGuncellePopupEditor;