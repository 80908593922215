import { BusinessHelper } from "../../helpers/BusinessHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { TarzModel } from "../../models/businessModels/TarzModel";
import CsmGenericListMultiSelectField, { ICsmGenericListMultiSelectFieldProps } from "../../library/components/mui/CsmGenericListMultiSelectField";

export type IVatanTarzFieldProps = Omit<ICsmGenericListMultiSelectFieldProps<TarzModel, string>, "list" | "labelGetter" | "valueGetter"> &
{
    genelDurumHelper: GenelDurumHelper,
    colorize?: boolean,
};

function VatanTarzField(props: IVatanTarzFieldProps) {
    const { colorize, genelDurumHelper, ...rest } = props;
    const orderedTarzList = genelDurumHelper.SeciliKoroModel.tumTarzlar.orderByTurkish(e => e.isim);

    return (
        <CsmGenericListMultiSelectField
            {...rest}
            list={orderedTarzList}
            chipColorOverride={e => colorize ? BusinessHelper.Tarz.RenkGetir(e.id, genelDurumHelper) : undefined}
            labelGetter={e => e.isim}
            valueGetter={e => e.id}
        />
    )
}

export default VatanTarzField;