const usernameKey = "username";
const tokenKey = "token";
const seciliKoroIdKey = "seciliKoroIdKey";
/*const tokenExpireDateKey = "tokenExpireDate"*/


export interface IFileInfo {
  //ctime: Date;
  //mtime: Date;
  name: string;
  //path: string;
  size: number;
  //isFile: () => boolean;
  //isDirectory: () => boolean;
}

const LocalStorageHelper = {
  DocumentDirectoryPath: 'documents',

  setUsername(username: string) {
    localStorage.setItem(usernameKey, username);
  },

  getUsername(): string | null {
    return localStorage.getItem(usernameKey);
  },

  setToken(token: string) {
    localStorage.setItem(tokenKey, token);
  },

  getToken(): string | null {
    return localStorage.getItem(tokenKey);
  },
  /*
    setTokenExpireDate(tokenExpireDate: Date | null) {
      localStorage.setItem(tokenExpireDateKey, StringHelper.nulToEmpty(tokenExpireDate?.valueOf()?.toString()));
    },
  
    getTokenExpireDate(): Date | null {
      var content = localStorage.getItem(tokenExpireDateKey);
      return content ? new Date(Number(content)) : null;
    },*/

  setSeciliKoroId(token: string) {
    localStorage.setItem(seciliKoroIdKey, token);
  },

  getSeciliKoroId(): string | null {
    return localStorage.getItem(seciliKoroIdKey);
  },

  clear() {
    localStorage.clear();
  },

  writeFile(path: string, content: string) {
    localStorage.setItem(path, content);
  },

  readFile(path: string) {
    return localStorage.getItem(path);
  },

  exists(path: string) {
    return localStorage.getItem(path) !== null;
  },

  unlink(path: string) {
    return localStorage.removeItem(path);
  },

  readDir(dir: string): IFileInfo[] {
    const result: IFileInfo[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith(dir + "/"))
        result.push({ name: key.substring(dir.length + 1), size: localStorage.getItem(key)?.length ?? 0 });
    }

    return result;
  }
};

export default LocalStorageHelper;
