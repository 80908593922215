/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { DuyuruModel } from "../../../models/businessModels/DuyuruModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmDatePicker from "../../../library/components/mui/CsmDatePicker";
import VatanKadroMultiSelectField from "../../../components/vatan/VatanKadroMultiSelectField";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import VatanSesGrupMultiSelectField from "../../../components/vatan/VatanSesGrupMultiSelectField";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";

interface IDuyuruPopupEditorProps {
    duyuruModel: DuyuruModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: DuyuruModel | null) => void,
}

function DuyuruPopupEditor(props: IDuyuruPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [duyuruModel, setDuyuruModel] = useState<DuyuruModel>(JsonHelper.deepCopy(props.duyuruModel));

    const updateDuyuruModel = (action: (model: DuyuruModel) => void) => {
        const result = JsonHelper.deepCopy(duyuruModel);
        action(result);
        setDuyuruModel(result);
    }

    const icerikHataVar = !duyuruModel.icerik;

    const baslangicTarihHata = (duyuruModel.tarihBaslangic && duyuruModel.tarihBitis && duyuruModel.tarihBaslangic > duyuruModel.tarihBitis)
        ? "Başlanhıç tarihi, bitiş tarihinden sonra olamaz" : "";

    const baslangicTarihHataVar = !!baslangicTarihHata || !duyuruModel.tarihBaslangic;
    const bitisTarihHataVar = !duyuruModel.tarihBitis;

    const kadroHataVar = duyuruModel.kadroIdList.length === 0;

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Duyuru.DuyuruGuncelle(duyuruModel, "Duyuru güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Duyuru.DuyuruEkle(duyuruModel, "Duyuru ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(duyuruModel);
    }

    let baslamayaKalanSureText = "";
    let duyuruSuresiText = "";

    if (!baslangicTarihHataVar && !bitisTarihHataVar) {
        if (duyuruModel.tarihBaslangic < DateTimeHelper.today())
            baslamayaKalanSureText = "Duyuru başladı";
        else {
            const baslamayaKalanSure = DateTimeHelper.substractForDays(DateTimeHelper.today(), duyuruModel.tarihBaslangic);
            baslamayaKalanSureText = `${baslamayaKalanSure} gün sonra başlayacak`;
        }

        const duyuruSure = DateTimeHelper.substractForDays(duyuruModel.tarihBaslangic, duyuruModel.tarihBitis);
        if (duyuruModel.tarihBitis < DateTimeHelper.today())
            duyuruSuresiText = `Süre: Duyuru ${duyuruSure} gün sürdü`;
        else
            duyuruSuresiText = `Süre: Duyuru ${duyuruSure} gün sürecek`;
    }

    return (
        <Rows width={850} height={350} pt={20}>
            <CsmTextField
                disabled={!guncellenebilir}
                label="Duyuru İçeriği"
                error={icerikHataVar}
                value={duyuruModel.icerik}
                onChange={(e) => updateDuyuruModel(t => t.icerik = e)} />
            <Columns mt={10}>
                <CsmDatePicker
                    disabled={!guncellenebilir}
                    label="Başlangıç Tarihi"
                    error={baslangicTarihHataVar}
                    helperText={baslangicTarihHata}
                    value={duyuruModel.tarihBaslangic}
                    onChange={(e) => updateDuyuruModel(t => t.tarihBaslangic = e ?? null as any)} />
                <CsmDatePicker
                    disabled={!guncellenebilir}
                    label="Bitiş Tarihi"
                    error={bitisTarihHataVar}
                    value={duyuruModel.tarihBitis}
                    style={{ marginLeft: 10 }}
                    onChange={(e) => updateDuyuruModel(t => t.tarihBitis = e ?? null as any)} />
                <Rows ml={10} mt={5}>
                    <CsmText note>{`Başlangıç: ${baslamayaKalanSureText}`}</CsmText>
                    <CsmText note>{`${duyuruSuresiText}`}</CsmText>
                </Rows>
            </Columns>

            <VatanKadroMultiSelectField
                style={{ marginTop: "10px" }}
                disabled={!guncellenebilir}
                label="Hedef Kadrolar"
                error={kadroHataVar}
                value={duyuruModel.kadroIdList}
                tumKadroList={genelDurumHelper.SeciliKoroModel.tumKadrolar}
                onChange={(e) => updateDuyuruModel(t => t.kadroIdList = e)}
                id={duyuruModel.id} />

            <VatanSesGrupMultiSelectField
                sx={{ height: "90px", alignContent: "flex-start", justifyItems: "flex-start" }}
                style={{ marginTop: "10px" }}
                disabled={!guncellenebilir}
                label="Hedef Ses Grupları"
                value={duyuruModel.sesGrupIdList}
                tumSesGruplari={genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip !== EnmSesGrupTip.Diğer)}
                onChange={(e) => updateDuyuruModel(t => t.sesGrupIdList = e)}
                id={duyuruModel.id} />

            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={icerikHataVar || baslangicTarihHataVar || bitisTarihHataVar || kadroHataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default DuyuruPopupEditor;