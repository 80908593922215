/* eslint-disable @typescript-eslint/no-non-null-assertion */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import Cell from "../../../library/components/layout/Cell";
import { CsmText } from "../../../library/components/mui/CsmText";
import { AltSezonModel } from "../../../models/businessModels/AltSezonModel";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import AlertHelper from "../../../library/helpers/AlertHelper";
import AltSezonPopupEditor from "./AltSezonPopupEditor";
import StringHelper from "../../../library/helpers/StringHelper";

interface ISezonPopupEditorProps {
    sezonModel: SezonModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: SezonModel | null) => void,
}

function SezonPopupEditor(props: ISezonPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [sezonModel, setSezonModel] = useState<SezonModel>(JsonHelper.deepCopy(props.sezonModel));

    const updateSezonModel = (action: (model: SezonModel) => void) => {
        const result = JsonHelper.deepCopy(sezonModel);
        action(result);
        setSezonModel(result);
    }

    let isimHataVar = false;
    let isimHata = "";

    if (StringHelper.isEmpty(sezonModel.isim))
        isimHataVar = true;
    else if (genelDurumHelper.SeciliKoroModel.tumSezonlar.any(e => e.id !== sezonModel.id && e.isim === sezonModel.isim)) {
        isimHataVar = true;
        isimHata = "Bu isme sahip başka bir sezon bulunmaktadır";
    }

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Sezon.SezonGuncelle(sezonModel, "Sezon güncelleniyor");
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Sezon.SezonEkle(sezonModel, "Sezon ekleniyor");
            if (!webResponse.successfull)
                return;
        }

        closer(sezonModel);
    }

    async function altSezonEkle() {
        const model = new AltSezonModel();
        model.id = GuidHelper.NewGuid();
        model.sezonModelId = sezonModel.id;
        model.isim = "";
        model.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
        model.olusturmaTarih = new Date();

        const response = await AlertHelper.ShowClosableContent2<AltSezonModel>("Alt Sezon Ekle", (closer) => (
            <AltSezonPopupEditor
                closer={closer}
                altSezonModel={model}
                tumAltSezonModeller={sezonModel.altSezonlar}
                guncellenebilir={guncellenebilir}
                genelDurumHelper={genelDurumHelper}
            />
        ));

        if (response == null) return;

        updateSezonModel(e => e.altSezonlar.push(response!));
    }

    async function altSezonSil(altSezon: AltSezonModel) {
        if (await AlertHelper.Confirm("Dikkat", `Alt sezonu silmek istediğinizden emin misiniz?`) === false)
            return;

        updateSezonModel(e => e.altSezonlar.remove(e.altSezonlar.single(t => t.id === altSezon.id)));
    }

    async function altSezonGuncelle(altSezon: AltSezonModel) {
        const response = await AlertHelper.ShowClosableContent2<AltSezonModel>("Alt Sezon Güncelle", (closer) => (
            <AltSezonPopupEditor
                guncellenebilir={guncellenebilir}
                altSezonModel={altSezon}
                tumAltSezonModeller={sezonModel.altSezonlar}
                genelDurumHelper={genelDurumHelper}
                closer={closer} />
        ));

        if (response == null)
            return;

        updateSezonModel(e => e.altSezonlar.single(e => e.id === response!.id).isim = response!.isim);
    }

    function altSezonRowGetir(altSezon: AltSezonModel): JSX.Element {
        const calismaCount = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => e.altSezonModelId === altSezon.id).length;
        const altBilgi = `${calismaCount} Çalışma`;

        const rightButtons: ICustomSwipeButton[] = [];

        rightButtons.push({ icon: appIcons.CrudIcons.sil, disabled: !guncellenebilir, color: "error", onClick: () => altSezonSil(altSezon) });
        rightButtons.push({ icon: appIcons.CrudIcons.guncelle, disabled: !guncellenebilir, color: "primary", onClick: () => altSezonGuncelle(altSezon) });

        return (
            <CsmSwipableListItem leftButtons={rightButtons} key={altSezon.id} style={{ width: "250px" }}>
                <Rows>
                    <CsmText style={{ alignSelf: "flex-start" }}>{altSezon.isim}</CsmText>
                    <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi}</CsmText>
                </Rows>
            </CsmSwipableListItem>
        );
    }

    return (
        <Rows width={550} height={400} pt={20}>
            <CsmTextField
                disabled={!guncellenebilir}
                helperText={isimHata}
                label="Sezon İsmi"
                error={isimHataVar}
                value={sezonModel.isim}
                onChange={(e) => updateSezonModel(t => t.isim = e)}
                eventOnKeyPress
            />

            {!genelDurumHelper.SeciliKoroModel.koroAyarlari.altSezonlarKullanilsin ?
                null
                :
                <Columns style={{ alignItems: "flex-start" }}>
                    <Rows>
                        {sezonModel.altSezonlar.length === 0 ? <CsmText italic>Alt Sezon Bulunmamaktadır</CsmText> : null}
                        {sezonModel.altSezonlar.map(e => altSezonRowGetir(e))}
                    </Rows>
                    <Cell flex></Cell>
                    <Button color="success" variant="outlined" onClick={() => altSezonEkle()} style={{ margin: 10, marginRight: 0 }}>
                        {appIcons.CrudIcons.ekle}
                        Alt Sezon Ekle</Button>
                </Columns>
            }
            <Cell flex></Cell>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={isimHataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default SezonPopupEditor;
