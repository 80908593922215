import PdfDisplayer from "../../../components/media/PdfDisplayer";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, SubPageLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import RegularAudioPlayer from "../../../components/media/RegularAudioPlayer";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";

function SarkiLinkAudioWithPdfScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);

    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <SarkiLinkAudioWithPdfScreenInner genelDurumHelper={genelDurumHelper} />
}

function SarkiLinkAudioWithPdfScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const { sarkiLinkId } = useParams();

    function sesGrupIsimGetir(model: SarkiLinkModel) {
        return StringHelper.nullToEmpty(genelDurumHelper.SeciliKoroModel.tumSesGruplari.singleOrDefault(e => e.id === model.sesGrupId)?.isim);
    }

    const sarki = genelDurumHelper.SeciliKoroModel.tumSarkilar.single(e => e.sarkiLinkModelList.any(t => t.id === sarkiLinkId));
    const sarkiLink = sarki.sarkiLinkModelList.single(e => e.id === sarkiLinkId);

    const {pdfVariMi, eskiPdfMi, pdfIcerikId} = BusinessHelper.Icerik.SarkiLinktenPdfLinkGetir(sarki?.sarkiLinkModelList);

    let title = ((eskiPdfMi ? "Güncel PDF " : "Eski PDF ") + StringHelper.nullToEmpty(sarkiLink.aciklama)).trim();

    if (sarkiLink.linkTip === EnmSarkiLinkTip["Full Midi Kaydı"])
        title = `${sarki.isim} Full Midi Kaydı`;
    else if (sarkiLink.linkTip === EnmSarkiLinkTip["Midi Kaydı"])
        title = `${sarki.isim} - ${sesGrupIsimGetir(sarkiLink)} Midi Kaydı ${StringHelper.nullToEmpty(sarkiLink.aciklama)}`;
    else if (sarkiLink.linkTip === EnmSarkiLinkTip["Ses Kaydı"])
        title = `${sarki.isim} - ${sesGrupIsimGetir(sarkiLink)} Ses Kaydı`;

    return <SubPageLayout
        title={title}
        previousLocations={[{ location: mainScreenLocations.sarkilar }]}
        genelDurumHelper={genelDurumHelper}
        hideHeader={false}
        hideMenu={false}>

        <Rows flex dontGrowForChild style={{ overflow: "hidden" }}>
            <Rows>
                <RegularAudioPlayer
                    genelDurumHelper={genelDurumHelper}
                    icerikId={sarkiLink.icerikId!}
                    title={title} />
                {!sarkiLink.aciklama ? null :
                    <Columns hasLightBottomBorder ml={10}>
                        <CsmText bold>Kayıta dair ek açıklamalar:</CsmText>
                        <CsmText>{sarkiLink.aciklama}</CsmText>
                    </Columns>
                }
            </Rows>
            <Columns style={{ justifyContent: "center" }} flex dontGrowForChild>
                {!pdfVariMi ? <CsmText>PDF Bulunmamaktadır</CsmText> : <PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={pdfIcerikId} />}
            </Columns>
        </Rows>
    </SubPageLayout>
}

export default SarkiLinkAudioWithPdfScreen;