/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { PieItemIdentifier, DefaultizedPieValueType } from '@mui/x-charts/models';
import Rows from '../layout/Rows';
import { CsmText } from '../mui/CsmText';
import StringHelper from '../../helpers/StringHelper';

interface ICsmPieChartProps {
    label?: string,
    data: { id?: string, label: string, value: number }[],
    showPercent?: boolean,
    hideLegend?: boolean,
    width?: number,
    height?: number,
    rowLikeDesign?: boolean,
}

export default function CsmPieChart(props: ICsmPieChartProps) {
    const { rowLikeDesign, width, height, hideLegend, label, showPercent } = props;
    const total = props.data.sum(e => e.value);

    const getPercentForLegend = (value: number) => {
        const percent = value / total;
        return `${(percent * 100).toFixed(0)}%`;
    };

    const getPercentForArcLabel = (params: { value: number }) => {
        const percent = params.value / total;
        return percent < 0.1 ? "" : `${(percent * 100).toFixed(0)}%`;
    };

    const data = props.data.orderByDesc(e => e.value).select(e => { return { id: e.id, value: e.value, label: `${e.label} ${getPercentForLegend(e.value)}` } });

    const handleClick = (
        event: React.MouseEvent<SVGPathElement, MouseEvent>,
        itemIdentifier: PieItemIdentifier,
        item: DefaultizedPieValueType,
    ) => {
        console.log(item.id);
        console.log(itemIdentifier);
    };

    const widthToUse = width ?? 500;
    const heightToUse = height ?? 250;

    return (
        <Rows>
            {StringHelper.isNotEmpty(label) && <CsmText header style={{ alignSelf: "center", marginBottom: "15px" }}>{label}</CsmText>}
            <PieChart
                series={[
                    {
                        cy: rowLikeDesign ? (widthToUse / 2) : undefined,
                        cx: rowLikeDesign ? (widthToUse / 2) : ((heightToUse / 2) + 25),
                        data: data,
                        arcLabel: !showPercent ? undefined : (e => getPercentForArcLabel(e as any)),
                    },
                ]}
                slotProps={{
                    legend: { hidden: hideLegend, position: { vertical: rowLikeDesign ? "bottom" : "middle", horizontal: rowLikeDesign ? "left" : "right" } },                    
                }}
                
                onItemClick={handleClick}
                width={widthToUse}
                height={heightToUse}
            />
        </Rows>
    );
}
